import * as React from "react";
import AdminHeader from "../components/AdminHeader";
import apis from "../apis";
import { PaymentDetail } from "../components/PaymentHistory";
import { Link, useNavigate } from "react-router-dom";
import "../css/Page/PaymentRecords.css";
import Loader from "../components/Loader";
import { AuthProvider } from "../auth/AuthContext";
import Table from "../common/tables/Table";

const PaymentRecords = () => {
  const [paymentHistory, setPaymentHistory] = React.useState([]);
  const [paymentHistory1, setPaymentHistory1] = React.useState([]);
  const [pastRecordfile, setPastRecordFile] = React.useState(null);
  const [refundFile, setRefundFile] = React.useState(null);
  const [page, setPage] = React.useState(-1);
  const [dateRange, setDateRange] = React.useState([null, null]);
  const navigate = useNavigate();
  const { adminData } = AuthProvider();

  const [loadingStatus, setLoadingStatus] = React.useState(true);

  const filterPaymentHistory = React.useCallback(() => {
    if (dateRange[0] && dateRange[1]) {
      return paymentHistory.filter((e) => {
        let parts = (e.date.replace(/\//g, '-')).split('-');
        let date = new Date(parts[2], parts[1] - 1, parts[0]);
        let startDate = new Date(dateRange[0]);
        let endDate = new Date(dateRange[1]);
        return date >= startDate && date <= endDate;
      });
    } else {
      return paymentHistory;
    }
  }, [dateRange, paymentHistory]);

  React.useEffect(() => {
    setPaymentHistory1(filterPaymentHistory());
    // 
  }, [dateRange, filterPaymentHistory]);


  React.useEffect(() => {
    setLoadingStatus(true);
    const getPaymentHistory = async () => {
      await apis.paymentHistory({}).then((res) => {
        setPaymentHistory(res);
        setLoadingStatus(false);
      });
    };
    getPaymentHistory();
  }, [navigate]);

  React.useEffect(() => {
    if (paymentHistory1.length > 0) {
      setPage(0);
    }
  }, [paymentHistory1]);

  const uploadTransactions = async () => {
    const formData = new FormData();
    formData.append("file", pastRecordfile);
    await apis
      .uploadTransactionsData(formData)
      .then((data) => {
        window.location.reload();
        alert(data.message);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const uploadRefund = async () => {
    const formData = new FormData();
    formData.append("file", refundFile);
    await apis
      .uploadRefundData(formData)
      .then((data) => {
        window.location.reload();
        alert(data.message);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const downloadReceipt = async (id) => {
    await apis.fetchReceipt(id)
      .then((data) => {
        let byteArray = new Uint8Array(data.data.receipt.data);
        let blob = new Blob([byteArray], { type: "application/pdf" });
        let url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
      }).catch((err) => {
        alert(err.message);
      });
  };

  const paymentRecordColumns = [
    {
      Header: "S.No",
      accessor: "id",
      style: { width: "53px", minWidth: "53px", cursor: 'pointer' },
      showContextMenu: true,
    },
    {
      Header: "Date",
      accessor: "date",
      style: { width: "100px", minWidth: "100px", cursor: 'pointer' },
      showContextMenu: true,
    },
    {
      Header: "Roll No.",
      accessor: "roll",
      style: { width: "100px", minWidth: "100px", cursor: 'pointer' },
      showContextMenu: true,
    },
    {
      Header: "Amount",
      accessor: "amount",
      style: { width: "100px", minWidth: "100px", cursor: 'pointer' },
      showContextMenu: true,
    },
    {
      Header: "Mode",
      accessor: "mode",
      style: { width: "100px", minWidth: "100px", cursor: 'pointer' },
      showContextMenu: true,
    },
    {
      Header: "Transaction ID",
      accessor: "transactionId",
      style: { width: "100px", minWidth: "100px" },
      showContextMenu: false,
    },
    {
      Header: "Status / Verified by",
      accessor: "status",
      style: { width: "100%", minWidth: "100%", cursor: 'pointer' },
      showContextMenu: true,
    },
    {
      Header: "Reciept",
      accessor: "reciept",
      style: { width: "120px", minWidth: "120px" },
      showContextMenu: false,
    },
  ];

  const paymentRecordRowData = paymentHistory1?.filter((e, i) =>
    i >= page * 50 && i < page * 50 + 50 ? true : false)
    .map((payment, index) => {
      return {
        id: index + page * 50 + 1,
        date: payment.date,
        roll: payment.roll,
        amount: payment.amount,
        mode: payment.type,
        transactionId: payment.refno,
        status: payment.status,
        reciept: payment.type === "Offline" ? (
          <div
            className="result-content-item"
            style={{ cursor: "pointer" }}
            onClick={() => downloadReceipt(payment.id)}
          >
            <h4>Download</h4>
          </div>
        ) : (
          <div className="result-content-item">
            <h4>N/A</h4>
          </div>
        ),
      };
    });

  return (
    <>
      <AdminHeader />
      {adminData?.role?.find((e) => e === "Finance Section" || e === "Super Admin") && <div className="top-div">
        <div className="records-upload">
          <div className="data-upload-div">
            <div className="data-upload-contents">
              <div className="data-upload-details">
                <h2>Add Past Payment Records:</h2>
                <p>
                  Use this only for uploading old payment details and use the
                  given format <br /> (
                  <Link
                    to="https://docs.google.com/spreadsheets/d/1ouMoE4AavsJaon6y_OyQed1LKbd9l3pyaSVpP2I5kIk/edit?usp=drive_link"
                    target="_blank"
                  >
                    {" "}
                    Download format{" "}
                  </Link>
                  ) only
                </p>
              </div>
              <div className="data-upload-buttons">
                <form style={{ width: "100%" }}>
                  <input className="required"
                    type="file"
                    id="csv-file"
                    placeholder="Choose .csv file"
                    accept=".csv"
                    required
                    value={pastRecordfile?.filename}
                    onChange={(e) => setPastRecordFile(e.target.files[0])}
                  />
                  <button
                    className="primary"
                    onClick={() => uploadTransactions()}
                  >
                    Upload .csv file
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="data-upload-div">
            <div className="data-upload-contents">
              <div className="data-upload-details">
                <h2>Add Refund Records:</h2>
                <p>
                  Use this to add refund records, amounts will be adjust from
                  over due of students
                  <br /> (
                  <Link
                    to="https://docs.google.com/spreadsheets/d/1GvOTPtf--J372TMvSVItxyKE3b7n_nzjEZjxPhQh7VQ/edit?usp=drive_link"
                    target="_blank"
                  >
                    {" "}
                    Download format{" "}
                  </Link>
                  ) only
                </p>
              </div>
              <div className="data-upload-buttons">
                <form style={{ width: "100%" }}>
                  <input className="required"
                    type="file"
                    id="csv-file"
                    placeholder="Choose .csv file"
                    accept=".csv"
                    required
                    value={refundFile?.filename}
                    onChange={(e) => setRefundFile(e.target.files[0])}
                  />
                  <button className="primary" onClick={() => uploadRefund()}>
                    Upload .csv file
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>}
      <div className="mid-div">
        <div className="payHistory-section">
          <div className="payHistory-div">
            <div className="title-div cv">
              <div className="title-left-div">
                <h2>Payment History:</h2>
              </div>

              <div className="filter-item title-right-div">
                <h4>From: </h4>
                <input class="date-input" type="date" value={dateRange[0]} onChange={
                  (e) => {
                    setDateRange([e.target.value, dateRange[1]])
                  }
                } />
                <h4 style={{ marginLeft: "5%" }}>To: </h4>
                <input class="date-input" type="date"
                  value={dateRange[1]} onChange={
                    (e) => {
                      setDateRange([dateRange[0], e.target.value])
                    }
                  }
                />
              </div>
            </div>
            <div className="content-div">
              <div className="result-items history">
                {loadingStatus ? (
                  <Loader />
                ) : (
                  <Table columns={paymentRecordColumns} data={paymentRecordRowData} className="paymentRecordTable" bodyClassName="paymentRecordBody" />
                )}
                <div className="pagination">
                  <button
                    className="new_btn"
                    onClick={() => setPage(page > 0 ? page - 1 : page)}
                  >
                    <i className="fa-solid fa-arrow-left"></i>
                  </button>
                  <div className="page-number">
                    <p>
                      {page + 1} / {Math.ceil(paymentHistory1.length / 50)}
                    </p>
                  </div>
                  <button
                    className="new_btn"
                    onClick={() =>
                      setPage(
                        page + 1 < paymentHistory1.length / 50 ? page + 1 : page
                      )
                    }
                  >
                    <i className="fa-solid fa-arrow-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DownloadCsv paymentHistory={paymentHistory} />
    </>
  );
};

const DownloadCsv = ({ paymentHistory }) => {
  // const { searchResult } = useSearch();
  async function downloadCsv() {
    const headers = [
      {
        label: "Date",
        value: "date",
      },
      {
        label: "Roll No.",
        value: "roll",
      },
      {
        label: "Amount",
        value: "amount",
      },
      {
        label: "Type",
        value: "type",
      },
      {
        label: "Reference No.",
        value: "refno",
      },
      {
        label: "Payment Status",
        value: "status",
      },
    ];
    await apis
      .downloadCsv(paymentHistory, headers, "paymentRecords.csv")
      .then((data) => {
        let url = window.URL.createObjectURL(data);
        window.open(url, +"_blank");
        alert("Downloaded");
      })
      .catch((err) => {
        alert(err.message);
      });
  }
  return (
    <div className="result-footer">
      <div className="result-footer-title">
        <h2>Export Payment records</h2>
        <p>(Get an offline .csv file of above data)</p>
      </div>
      <div className="result-footer-button">
        <button onClick={() => downloadCsv()}>
          Start exporting now as .csv file{" "}
          <i className="fa-solid fa-circle-arrow-right"></i>
        </button>
      </div>
    </div>
  );
};

export default PaymentRecords;
