import React, { useEffect, useState } from "react";
import { AuthProvider } from "../auth/AuthContext";
import GeneralFilter1 from "../common/filters/GeneralFilter1";
import apis from "../apis";

import "../css/Component/MessManagement.css";
import Table from "../common/tables/Table";
import GenerateTimer from "./GenerateTimer";

const MessManagement = () => {
  const { adminData } = AuthProvider();

  const [filterAttribute, setFilterAttribute] = useState({});
  const [messData, setMessData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getStudentMessSwapData = async () => {
    setLoading(true);
    try {
      await apis
        .getMessSwapResults()
        .then((data) => {
          setMessData(data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err.message);
        });
    } catch (err) {
      console.log(err.message);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getStudentMessSwapData();
  }, []);

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (messData.length > 0) {
      let tempData = messData;

      if (filterAttribute["gender"]) {
        tempData = tempData.filter(
          (data) => data["gender"] === filterAttribute["gender"]
        );
      }
      if (filterAttribute["session"]) {
        tempData = tempData.filter(
          (data) => data["session"] === filterAttribute["session"]
        );
      }
      if (filterAttribute["course"]) {
        tempData = tempData.filter(
          (data) => data["program"] === filterAttribute["course"]
        );
      }
      if (filterAttribute["batch"]) {
        tempData = tempData.filter(
          (data) => data["batch"] === filterAttribute["batch"]
        );
      }
      if (filterAttribute["semester"]) {
        tempData = tempData.filter(
          (data) => data["semester"] === filterAttribute["semester"]
        );
      }
      if (filterAttribute["hostel"]) {
        tempData = tempData.filter(
          (data) => data["hostel"] === filterAttribute["hostel"]
        );
      }
      if (filterAttribute["mess"]) {
        tempData = tempData.filter(
          (data) => data["mess"] === filterAttribute["mess"]
        );
      }

      setFilteredData(tempData);
    }
  }, [filterAttribute, messData]);

  const requiredFilters = [
    "gender",
    "session",
    "course",
    "batch",
    "semester",
    "hostel",
    "mess",
  ];

  const columns = [
    {
      Header: "S.No.",
      accessor: "sno",
    },
    {
      Header: "Roll No.",
      accessor: "roll",
    },
    {
      Header: "Name",
      accessor: "name",
      style: { width: "135px", minWidth: "135px", cursor: "pointer" },
    },
    {
      Header: "Batch",
      accessor: "batch",
      style: { width: "65px", minWidth: "65px", cursor: "pointer" },
    },
    {
      Header: "Semester",
      accessor: "semester",
      style: {
        width: "55px",
        minWidth: "55px",
        cursor: "pointer",
        paddingLeft: "5px",
        paddingRight: "5px",
      },
    },
    {
      Header: "Hostel",
      accessor: "hostel",
      style: {
        width: "50px",
        minWidth: "50px",
        cursor: "pointer",
        paddingLeft: "5px",
        paddingRight: "5px",
      },
    },
    {
      Header: "Mess",
      accessor: "mess",
      style: { width: "165px", minWidth: "165px", cursor: "pointer" },
    },
    {
      Header: "Requested Mess",
      accessor: "requestedMess",
      style: { width: "165px", minWidth: "165px", cursor: "pointer" },
    },
    {
      Header: "Approve",
      accessor: "approve",
    },
    {
      Header: "Reject",
      accessor: "reject",
    },
  ];

  const rowData =
    filteredData &&
    filteredData.map((data, index) => {
      return {
        sno: index + 1,
        roll: data.roll,
        name: data.name,
        batch: data.batch,
        semester: data.semester,
        hostel: data.hostel,
        mess: data.mess === "VSL_MESS" ? "Sugandhim – Food & Catering (VCL)" : "Sai Hospitality And Services (KBH/H1)",
        requestedMess: data.requestedMess === "VSL_MESS" ? "Sugandhim – Food & Catering (VCL)" : "Sai Hospitality And Services (KBH/H1)",
        approve: (
          <button
            className="approveButton primary"
            onClick={() => {handleApprove(data.id); data.status = "APPROVED"}}
          >
            Approve
          </button>
        ),
        reject: (
          <button
            className="rejectButton secondary"
            onClick={() => {handleReject(data.id); data.status = "REJECTED"}}
          >
            Reject
          </button>
        ),
        className:
        data.status === "APPROVED"
          ? "resolved"
          : data.status === "REJECTED"
          ? "unresolved"
          : "inreview",
      };
    });

  const handleApprove = async (id) => {
    try {
      await apis.approveMessSwap(id).then((data) => {
        alert(data);
        getStudentMessSwapData();
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleReject = async (id) => {
    try {
      await apis.rejectMessSwap(id).then((data) => {
        alert(data);
        getStudentMessSwapData();
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <div>
      <GeneralFilter1
        filterHeading="Filter students using"
        filters={requiredFilters}
        setFilterAttribute={setFilterAttribute}
        filterAttribute={filterAttribute}
      />
      <div className="secondMessHalf">
        <div className="messSwapTable">
          <Table
            columns={columns}
            data={rowData}
            loading={loading}
            className=""
          />
        </div>
        <div className="schedularPart">
          <GenerateTimer schedularType={"MESS"} />
        </div>
      </div>
    </div>
  );
};

export default MessManagement;
