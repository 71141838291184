import * as React from "react";
import AdminHeader from "../components/AdminHeader";
import "../css/Page/NewAdmissionProgress.css";
import AdmissionListBody from "../components/AdmissionListBody";
import apis from "../apis";
import CircularLoader from "../common/loaders/CircularLoader";
import api from "../newRegistrationApis";
import { AuthProvider } from "../auth/AuthContext";
import PersonalDetailsPopUp from "../components/PersonalDetailsPopUp";
import PersonalDocumentsPopUp from "../components/PersonalDocumentsPopUp";
import OtherDocumentsPopUp from "../components/OtherDocumentsPopUp";
import Table from "../common/tables/Table";
import GeneralFilter1 from "../common/filters/GeneralFilter1";

const StudentList = ({ page, newStudents }) => {
  return (
    <>
      {/* {newStudents
        ?.filter((e, i) =>
          i >= page * 50 && i < page * 50 + 50 ? true : false
        )
        .map((e) => {
          return <AdmissionListBody key={e._id} newStudent={e} />;
        })} */}
    </>
  );
};

const DownloadCsv = ({ download, session }) => {
  // const { download } = useSearch();
  async function downloadCsv() {
    const headers = [
      {
        label: "Application ID",
        value: "applicationid",
      },
      {
        label: "Name",
        value: "name",
      },
      {
        label: "Email",
        value: "email",
      },
      {
        label: "Institute Email",
        value: "instituteEmailId",
      },
      {
        label: "Institute Roll",
        value: "instituteRoll",
      },
      {
        label: "Exam Rank",
        value: "examRank"
      },
      {
        label: "Exam Type",
        value: "examType"
      },
      {
        label: "Program",
        value: "program",
      },
      {
        label: "Branch",
        value: "allotedBranch",
      },
      {
        label: "Semester",
        value: "semester",
      },
      {
        label: "Batch",
        value: "batch",
      },
      {
        label: "Category",
        value: "category",
      },
      {
        label: "Phone",
        value: "phone",
      },
      {
        label: "Permanent Address",
        value: "paddress",
      },
      {
        label: "Correspondence Address",
        value: "caddress",
      },
      {
        label: "Father's Name",
        value: "fathername",
      },
      {
        label: "Mother's Name",
        value: "mothername",
      },
      {
        label: "State of Eligibility",
        value: "stateofeligibility",
      },
      {
        label: "Nationality",
        value: "nationality",
      },
      {
        label: "Gender",
        value: "gender"
      },
      {
        label: "PwD",
        value: "pwd"
      },
      {
        label: "Type",
        value: "type",
      },
    ];
    await apis
      .downloadCsv(download?.filter((value) => value?.session === session), headers, "newAdmissionData.csv")
      .then((data) => {
        let url = window.URL.createObjectURL(data);
        window.open(url, +"_blank");
        alert("Downloaded");
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  const downloadPhotoAndSign = async () => {
    const headers = [
      {
        label: "Application ID",
        value: "applicationid",
      },
      {
        label: "Name",
        value: "name",
      },
      {
        label: "Institute Email",
        value: "instituteEmailId",
      },
      {
        label: "Institute Roll",
        value: "instituteRoll",
      },
      {
        label: "Program",
        value: "program"
      },
      {
        label: "Branch",
        value: "allotedBranch"
      },
      {
        label: "Batch",
        value: "batch"
      },
      {
        label: "DOB",
        value: "dob",
      },
      {
        label: "Blood Group",
        value: "bloodgroup",
      },
      {
        label: "Permanent Address",
        value: "paddress",
      },
      {
        label: "Student Contact No.",
        value: "phone"
      },
      {
        label: "Father's Name",
        value: "fathername"
      },
      {
        label: "Mother's Name",
        value: "mothername",
      },
      {
        label: "Guardian Contact No.",
        value: "gcontact"
      },
    ];
    await apis
      .downloadCsv(download?.filter((value) => value?.session === session), headers, "ID_CARD_DATA.csv")
      .then((data) => {
        let url = window.URL.createObjectURL(data);
        window.open(url, +"_blank");
        alert("Downloaded ID CARD Data");
      })
      .catch((err) => {
        alert(err.message);
      });
    await api.downloadPhotoAndSign(session).then((data) => {
      let url = window.URL.createObjectURL(data);
      window.open(url, +"_blank");
      alert("Downloaded Images and Signature");
    }).catch((err) => {
      alert(err.message);
    });
  }

  return (
    <div className="result-footer">
      <div className="result-footer-title">
        <h2>Export New Students Data</h2>
        <p>(Get an offline .csv file of above data)</p>
      </div>
      <div className="result-footer-button">
        <button onClick={() => downloadCsv()}>
          Start exporting now as .csv file{" "}
          <i className="fa-solid fa-circle-arrow-right"></i>
        </button>
        <button onClick={() => downloadPhotoAndSign()}>Export ID Card Data, Images & Signature{" "}
          <i className="fa-solid fa-circle-arrow-right"></i>
        </button>
      </div>
    </div>
  );
};

const NewAdmissionProgress = () => {
  const { adminData } = AuthProvider();

  const [page, setPage] = React.useState(0);
  const [newStudents, setNewStudents] = React.useState([]);
  const [sessionList, setSessionList] = React.useState(null);
  const [session, setSession] = React.useState(null);
  const [filterAttribute, setFilterAttribute] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const generateSessionList = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const sessionList = [];
    for (let i = currentYear; i >= 2017; i--) {
      if (currentMonth < 6 && i === currentYear) continue;
      let nextYear = (i + 1).toString().slice(-2);
      sessionList.push(`${i}-${nextYear}`);
    }
    return sessionList;
  };

  React.useEffect(() => {
    setSessionList(generateSessionList());
    setSession(generateSessionList()[0]);
    setFilterAttribute((prevFilterAttribute) => ({
      ...prevFilterAttribute,
      session: generateSessionList()[0],
    }));
  }, []);

  const fetchData = async () => {
    setLoading(true);
    await apis
      .fetchAllNewStudents()
      .then((res) => {
        setNewStudents(res);
      })
      .catch((err) => {
        alert(err.message);
      });
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const [filteredData, setFilteredData] = React.useState([]);

  React.useEffect(() => {
    if (newStudents.length > 0) {
      let tempData = newStudents;
      for (let key in filterAttribute) {
        if (filterAttribute[key] !== '' && key !== 'status1') {
          if (key === 'course') {
            tempData = tempData.filter((data) => data['program'] === filterAttribute[key]);
          }
          else if (key === 'branch') {
            tempData = tempData.filter((data) => data['allotedBranch'] === filterAttribute[key]);
          }
          else if (key === 'name') {
            tempData = tempData.filter((data) => data[key].toLowerCase().includes(filterAttribute[key].toLowerCase()));
          }
          else {
            tempData = tempData.filter((data) => data[key] === filterAttribute[key]);
          }
        }
        if (key === 'session' && filterAttribute[key] === '') {
          tempData = tempData.filter((data) => data[key] === generateSessionList()[0]);
        }
      }
      console.log(filterAttribute);
      setFilteredData(tempData);
    }
  }, [filterAttribute, newStudents]);

  const requiredFilters = ['name', 'session', 'course', 'branch'];

  const newAdmissionColumns = [
    {
      Header: "Application ID",
      accessor: "applicationid",
      style: { cursor: "pointer", minWidth: "150px", width: "150px" },
      showContextMenu: false,
    },
    {
      Header: "Name",
      accessor: "name",
      style: { cursor: "pointer", minWidth: "150px", width: "150px" },
      showContextMenu: true,
    },
    {
      Header: "Course",
      accessor: "program",
      style: { cursor: "pointer", minWidth: "80px", width: "80px" },
      showContextMenu: true,
    },
    {
      Header: "Branch",
      accessor: "allotedBranch",
      style: { cursor: "pointer", minWidth: "80px", width: "80px" },
      showContextMenu: true,
    },
    {
      Header: "Payment Record",
      accessor: "paymentRecord",
      style: { cursor: "pointer", minWidth: "118px", width: "118px" },
      showContextMenu: false,
    },
    {
      Header: "Step - 1",
      accessor: "personalDetailsFilled",
      style: { cursor: "pointer", padding: "0px 0px", minWidth: "33.33%", width: "33.33%" },
      showContextMenu: false,
    },
    {
      Header: "Step - 2",
      accessor: "personalFilesFilled",
      style: { cursor: "pointer", padding: "0px 0px", minWidth: "33.33%", width: "33.33%" },
      showContextMenu: false,
    },
    {
      Header: "Step - 3",
      accessor: "otherFilesFilled",
      style: { cursor: "pointer", padding: "0px 0px", minWidth: "33.33%", width: "33.33%" },
      showContextMenu: false,
    },
    {
      Header: "PI Reporting",
      accessor: "allotSeat",
      style: { cursor: "pointer", padding: "0px 0px", minWidth: "80px", width: "80px" },
      showContextMenu: true,
    },
  ];

  const newAdmissionRowData = filteredData && filteredData
    ?.filter((e, i) =>
      i >= page * 20 && i < page * 20 + 20 ? true : false
    )
    .map((e) => {
      return {
        applicationid: e.applicationid,
        name: e.name,
        program: e.program,
        allotedBranch: e.allotedBranch,
        paymentRecord: (<AdmissionListBody newStudent={e} type={"paymentHistory"} />),
        personalDetailsFilled: (<AdmissionListBody newStudent={e} type={"personalDetails"} />),
        personalFilesFilled: (<AdmissionListBody newStudent={e} type={"personalFiles"} />),
        otherFilesFilled: (<AdmissionListBody newStudent={e} type={"otherFiles"} />),
        allotSeat: (<AdmissionListBody newStudent={e} type={"allotSeat"} />),
      };
    });

  return (
    <>
      <AdminHeader />
      <div className="top-div">
        <div className="NewAdmissionProgress">
          <div className="title cv2">
            <div className="title-left-div">
              <h2>New Admission Progress:</h2>
            </div>
            <GeneralFilter1
              filterHeading="Filter Data By"
              filters={requiredFilters}
              setFilterAttribute={setFilterAttribute}
              filterAttribute={filterAttribute}
              containerClass="newAdmissionFilterContainer"
              titleClass="newAdmissionFilterTitle"
            />
          </div>
          <div className="status-div">
            <div className="status-element">
              <h3>Total Students</h3>
              <h1>{filteredData?.length}</h1>
            </div>
            <div className="status-element">
              <h3>Completed Step-1</h3>
              <h1>
                {
                  filteredData?.filter(
                    (newStudent) => newStudent?.personalDetailsFilled === true
                  ).length
                }
              </h1>
            </div>
            <div className="status-element">
              <h3>Completed Step - 2</h3>
              <h1>
                {
                  filteredData?.filter(
                    (newStudent) => newStudent?.personalFilesFilled === true
                  ).length
                }
              </h1>
            </div>
            <div className="status-element">
              <h3>Finished Registration</h3>
              <h1>
                {
                  filteredData?.filter(
                    (newStudent) => newStudent?.otherFilesFilled === true
                  ).length
                }
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="newAdmissionTableContainer">
        <Table columns={newAdmissionColumns} data={newAdmissionRowData} className="newAdmissionTable" bodyClassName="newAdmissionBody" />
      </div>
      {/* <div className="mid-div"> */}
      <div className="table-div">
        <div className="student-list">

          <div className="pagination">
            <button
              className="new_btn"
              onClick={() => setPage(page > 0 ? page - 1 : page)}
            >
              <i className="fa-solid fa-arrow-left"></i>
            </button>
            <div className="page-number">
              <p>
                <span>{page + 1}</span> /{" "}
                {Math.ceil(filteredData?.length / 20)}
              </p>
            </div>
            <button
              className="new_btn"
              onClick={() =>
                setPage(page + 1 < filteredData?.length / 20 ? page + 1 : page)
              }
            >
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
      {/* </div> */}
      {
        adminData && adminData.role && (adminData?.role.includes("Super Admin") || adminData?.role.includes("DOAA") || adminData?.role.includes("DOSA") || adminData?.role.includes("HAB") || adminData?.role.includes("Academic Section") || adminData?.role.includes("DR") || adminData?.role.includes("AR") || adminData?.role.includes("Registrar")) ? (<DownloadCsv download={newStudents} session={session} />) : null
      }
    </>
  );
};

export default NewAdmissionProgress;
