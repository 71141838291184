import React, { useEffect, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import apis from "../apis";
import "../css/Page/FacultyCourseAssign.css";
import { IoClose } from "react-icons/io5";
import Table from "../common/tables/Table";

const FacultyCourseAssignTable = ({
  faculties,
  loading,
  setPopUp,
  setTeacher,
  getUniqueFaculty,
  uniqueFaculty
}) => {
  useEffect(() => {
    getUniqueFaculty();
  }, []);

  const [popUp2, setPopUp2] = useState(false);
  const [facData, setFacData] = useState([]);
  const [name, setName] = useState("");

  const facultyCourseFunc = async (id, factName) => {
    try {
      await Promise.resolve(apis.getFacultyCouresesById(id).then((data) => {
        setFacData(data);
        console.log(data);
        setName(factName);
        setPopUp2(true);
      }));
    } catch (error) {
      console.log(error);
    }
  }

  const facultyTableColumns = [
    { Header: 'S.No.', accessor: 'sno', style: { width: '5%' } },
    { Header: 'Faculty Name', accessor: 'name', style: { width: '50%', cursor: 'pointer' }, onClick: (row) => facultyCourseFunc(row.id, row.name) },
    { Header: 'Assign', accessor: 'assign', style: { width: '15%', cursor: 'pointer' }, onClick: (row) => { setTeacher(row); setPopUp(true); } },
  ];

  const facultyTableData = faculties.map((e, i) => ({
    sno: i + 1,
    name: e.name,
    assign: <AddCircleOutlineIcon onClick={() => { setTeacher(e); setPopUp(true); }} />,
    id: e.id,
    className: uniqueFaculty.includes(e.id) ? 'present' : '',
  }));

  const popUpTableColumns = [
    { Header: 'Course Name', accessor: 'name' },
    { Header: 'Code', accessor: 'code' },
    { Header: 'Course', accessor: 'course' },
    { Header: 'Branch', accessor: 'branch' },
    { Header: 'Semester', accessor: 'semester' },
  ];

  return (
    <>
      {popUp2 && (
        <div className="popUp-overlay">
          <div className="popUp-container">
            <IoClose className="close" onClick={() => setPopUp2(false)} />
            <div className="popUp-Upper">
              <div className="title cv">
                <div className="">
                  <h2>{name}</h2>
                </div>
              </div>
              <div className="assignedCoursesCont">
                <p>Assigned Courses:</p>
                {facData && facData.length > 0 && facData.map((e, i) => (
                  <div key={i}>
                    <div className="assignedCourses">
                      <p>{e.session}</p>
                    </div>
                    <Table columns={popUpTableColumns} data={e.courses} loading={false} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      <div style={{ width: "80%" }}>
        <Table
          columns={facultyTableColumns}
          data={facultyTableData}
          loading={loading}
          className={"courseAssignTablePart"}
        />
      </div>
    </>
  );
};

export default FacultyCourseAssignTable;
