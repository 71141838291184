import React, { useState, useEffect, useRef } from 'react';
import './styles/ContextMenu.css';

const ContextMenu = ({ position, options, onClose }) => {
  const contextMenuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div
      ref={contextMenuRef}
      className="context-menu"
      style={{ top: position.y, left: position.x }}
    >
      {options.map((option, index) => (
        <div key={index} className="context-menu-item" onClick={option.onClick}>
          {option.label}
        </div>
      ))}
    </div>
  );
};

export default ContextMenu;
