import * as React from "react";
import "../css/Component/StudentLogin.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import jwtVerify from "../auth/service";
import { AuthProvider } from "../auth/AuthContext";
import ButtonLoader from "./ButtonLoader";

const StudentLogin = () => {
  const { setUserData } = AuthProvider();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const navigate = useNavigate();
  const [btnLoading, setBtnLoading] = React.useState(false);

  async function loginStudent(e) {
    e.preventDefault();
    setBtnLoading(true);
    await jwtVerify
      .login(email, password)
      .then((res) => {
        if (res) {
          setUserData(res);
          setBtnLoading(false);
          navigate("/student");
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  return (
    <>
      <div className="login-div">
        <div className="login-header">
          <h2>Student Login</h2>
          <Link to={"/admin"}>
            <i className="fa-solid fa-exchange"></i>
          </Link>
        </div>
        <div className="login-message">
          <p>Get all your stuffs done here :)</p>
        </div>
        <div className="login-form">
          <form onSubmit={(e) => loginStudent(e)}>
            <div className="login-input">
              <h3>Institute Email ID</h3>
              <input
                type="email"
                placeholder="College Email ID"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>
            <div className="login-input">
              <h3>Password</h3>
              <input
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </div>
            <div className="login-button">
              {btnLoading ? (
                <button type="submit"><ButtonLoader /></button>
              ) : (
                <button type="submit">Login</button>
              )}
              <Link to={"/password-change"}>
                Forget/Change <span>Password</span>
              </Link>
            </div>
          </form>
        </div >
      </div >
    </>
  );
};

export default StudentLogin;
