import React from "react";
import "../css/Component/BonafideRequestsPopUp.css";
import BonaRequestElement from "./BonaRequestElement";
import { DataGrid } from "@mui/x-data-grid";
import apis from "../apis";

const BonafideRequestsPopUp = ({
  open,
  onClose,
  applications,
  setBonaRequestsPopup,
}) => {
  if (!open) return null;

  const blobUrl = (value) => {
    if (value?.data === null) return null;
    const url = URL.createObjectURL(
      new Blob([new Uint8Array(value?.data)], {
        type: "application/pdf",
      })
    );
  };

  const mardAsDone = async (id, purpose) => {
    // console.log(purpose);
    await apis
      .generateBonafide(id, purpose)
      .then((res) => {
        alert("This request has been marked as done");
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const columns = [
    {
      field: "date",
      headerName: "Apply Date",
      sortable: true,
      minWidth: 130,
      flex: 0.3,
    },
    {
      field: "roll_no",
      headerName: "Roll No.",
      minWidth: 130,
      flex: 0.3,
    },
    {
      field: "type",
      headerName: "Aplication Type",
      minWidth: 100,
      flex: 0.3,
    },

    {
      field: "purpose",
      headerName: "Purpose",
      sortable: false,
      minWidth: 270,
      flex: 0.5,
    },
    {
      field: "format",
      headerName: "Format",
      sortable: false,
      minWidth: 130,
      flex: 0.2,
    },
    {
      field: "default",
      headerName: "Generate Default",
      sortable: false,
      minWidth: 150,
      headerAlign: "center",
      flex: 0.3,
      renderCell: (params) => {
        return (
          <>
            <button
              className="primary generate-primary"
              type="submit"
              onClick={() => {
                setBonaRequestsPopup(false);
                window.location.pathname = `/student/admin/documents-bonafide/${params?.id}/n`;
              }}
            >
              Generate
            </button>
          </>
        );
      },
    },
    {
      field: "manual",
      headerName: "if done manually",
      sortable: false,
      minWidth: 150,
      flex: 0.3,
      renderCell: (params) => {
        const currPurpose = applications.filter(
          (application) => application.id === params.id
        )[0].purpose;
        return (
          <>
            <button
              className="secondary mark-as-done"
              type="submit"
              onClick={() => mardAsDone(params?.id, currPurpose)}
            >
              Mark as done
            </button>
          </>
        );
      },
    },
  ];

  const rows = [];

  applications.map((application) => {
    rows.push({
      id: application.id,
      date: application.date,
      roll_no: application.roll,
      type:
        application.applicationType === null
          ? "Bonafide"
          : application.applicationType,
      purpose: application.purpose,
      format:
        application.otherFormat !== null ? (
          <a href={blobUrl(application?.otherFormat)}>Requested Format</a>
        ) : (
          "N/A"
        ),
      default: application.done,
      manual: application.done,
    });
  });

  return (
    <>
      <div className="bonafied-gateway-popup">
        <div className="gateway-popup-title">
          <h2>
            Requests for Bonafide: <span>{applications.length}</span>
          </h2>
          <button onClick={onClose}>
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          autoPageSize
          className="productListTable"
        />
        {/* <div className="gateway-popup-content BonafideRequests-content">
          <div className="result-items history bonafide-history-contents">
            <div className="result-heading">
              <div className="result-heading-item">
                <h4>Roll Number</h4>
              </div>
              <div className="result-heading-item">
                <h4>Application Type</h4>
              </div>
              <div className="result-heading-item">
                <h4>Purpose</h4>
              </div>
              <div className="result-heading-item">
                <h4>Format</h4>
              </div>
              <div className="result-heading-item">
                <h4>Generate Default</h4>
              </div>
              <div className="result-heading-item">
                <h4>if done manually</h4>
              </div>
            </div>
            <div className="result-contents">
              {applications.map((application) => (
                <BonaRequestElement
                  key={application.id}
                  application={application}
                  setBonafideRequestsPopup={setBonaRequestsPopup}
                />
              ))}
            </div>
            <div className="result-content">
              <h4>No more requests for now :)</h4>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default BonafideRequestsPopUp;
