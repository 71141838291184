import React, { useEffect, useState } from "react";
import apis from "../apis";
import { FiChevronDown } from "react-icons/fi";
import AdminHeader from "../components/AdminHeader";
import "../css/Page/ReviewRating.css";
import { Link } from "react-router-dom";
import { useSearch } from "../contexts/SearchProvider";

const MTechCourses = ["AI & DS", "VLSI & ES", "EV", "Micro & Comm.", "SPML"];

const BTechCourses = ["CSE", "ECE", "MAE", "MNC"];

const Ratings = () => {
  const [newRatings, setNewRatings] = useState([]);
  const [studentsRating, setStudentsRating] = useState([]);
  const [filterAttribute, setFilterAttribute] = React.useState({
    session: "",
    program: "",
    branch: "",
    season: "",
    semester: "",
  });
  const [totalStudentCount, setTotalStudentCount] = useState(0);
  const [totalRatingCount, setTotalRatingCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isReviewAssigned, setIsReviewAssigned] = useState(false);

  const generateSessionList = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const sessionList = [];
    for (let i = currentYear; i >= 2017; i--) {
      if (currentMonth < 6 && i === currentYear) continue;
      let nextYear = (i + 1).toString().slice(-2);
      sessionList.push(`${i}-${nextYear}`);
    }
    return sessionList;
  };

  useEffect(() => {
    const currentMonth = new Date().getMonth();
    if (currentMonth < 6) {
      setFilterAttribute({
        ...filterAttribute,
        session: generateSessionList()[0],
      });
    } else {
      setFilterAttribute({
        ...filterAttribute,
        session: generateSessionList()[1],
      });
    }
  }, []);

  const getStudentCount = async () => {
    try {
      const queryParameters = {
        course: filterAttribute?.program,
        branch: filterAttribute?.branch.replace(/&/g, "%26"),
        semester: filterAttribute?.semester,
      };

      await Promise.resolve(apis.getCurrentStudentLength(queryParameters)).then(
        (data) => {
          console.log("Student count:", data);
          setTotalStudentCount(data);
        }
      );
    } catch (err) {
      console.error("Error fetching student count:", err);
    }
  };

  const getRatingCount = async () => {
    try {
      const queryParameters = {
        branch: filterAttribute?.branch.replace(/&/g, "%26"),
        session: filterAttribute?.session,
        semester: filterAttribute?.semester,
        course: filterAttribute?.program,
      };

      await Promise.resolve(
        apis.getTotalStudentReviewCount(queryParameters)
      ).then((data) => {
        console.log("Rating count:", data);
        setTotalRatingCount(data);
      });
    } catch (err) {
      console.error("Error fetching rating count:", err);
    }
  };

  useEffect(() => {
    getStudentCount();
    getRatingCount();
  }, [filterAttribute, setIsReviewAssigned]);

  const fetchRatings = async () => {
    try {
      await Promise.resolve(apis.getAllRatingStatus()).then((data) => {
        console.log("Ratings:", data);
        setNewRatings(data);
      });
    } catch (err) {
      console.error("Error fetching ratings:", err);
    }
  };

  const fetchStudentRating = async () => {
    try {
      const queryParameters = {
        status: false,
      };

      await Promise.resolve(apis.getStudentRatingDetails(queryParameters)).then(
        (data) => {
          console.log("Student Rating:", data);
          setStudentsRating(data);
        }
      );
    } catch (err) {
      console.error("Error fetching student rating:", err);
    }
  };

  useEffect(() => {
    fetchRatings();
    fetchStudentRating();
  }, []);

  useEffect(() => {
    // const ws = new WebSocket("ws://localhost:8080");
    const protocol = window.location.protocol === "https:" ? "wss://" : "ws://";
    const hostname = window.location.hostname;
    const port = process.env.WEBSOCKET_PORT || 8080;
    const url = `${protocol}${hostname}:${port}`;
    const ws = new WebSocket(url);
    console.log(ws);

    ws.onmessage = (event) => {
      const newRating = JSON.parse(event.data);
      console.log("New rating:", newRating);
      setNewRatings((prevRatings) => [...prevRatings, newRating]);
    };

    // return () => {
    //   ws.close();
    // };
  }, []);

  const [filteredRatings, setFilteredRatings] = useState([]);
  const [filterStudentRating, setFilterStudentRating] = useState([]);

  useEffect(() => {
    if (newRatings && newRatings.length > 0) {
      let filtered = newRatings;

      if (filterAttribute.session !== "") {
        filtered = filtered.filter(
          (e) => e.Session === filterAttribute.session
        );
      }

      if (filterAttribute.program !== "") {
        filtered = filtered.filter(
          (e) => e.Program === filterAttribute.program
        );
      }

      if (filterAttribute.branch !== "") {
        filtered = filtered.filter((e) => e.Branch === filterAttribute.branch);
      }

      if (filterAttribute.season !== "") {
        if (filterAttribute.season === "Spring") {
          filtered = filtered.filter((e) => Number(e.Semester) % 2 === 0);
        } else if (filterAttribute.season === "Autumn") {
          filtered = filtered.filter((e) => Number(e.Semester) % 2 !== 0);
        }
      }

      if (filterAttribute.semester !== "") {
        filtered = filtered.filter(
          (e) => Number(e.Semester) === Number(filterAttribute.semester)
        );
      }

      const uniqueFiltered = Array.from(new Set(filtered.map((a) => a.id))).map(
        (id) => {
          return filtered.find((a) => a.id === id);
        }
      );

      setFilteredRatings(uniqueFiltered);
    }
  }, [
    filterAttribute,
    filterAttribute.program,
    filterAttribute.branch,
    filterAttribute.season,
    filterAttribute.semester,
    newRatings,
  ]);

  useEffect(() => {
    if (studentsRating && studentsRating.length > 0) {
      let filtered = studentsRating;

      if (filterAttribute.session !== "") {
        filtered = filtered.filter(
          (e) => e.session === filterAttribute.session
        );
      }

      if (filterAttribute.program !== "") {
        filtered = filtered.filter((e) => e.course === filterAttribute.program);
      }

      if (filterAttribute.branch !== "") {
        filtered = filtered.filter((e) => e.branch === filterAttribute.branch);
      }

      if (filterAttribute.season !== "") {
        if (filterAttribute.season === "Spring") {
          filtered = filtered.filter((e) => Number(e.semester) % 2 === 0);
        } else if (filterAttribute.season === "Autumn") {
          filtered = filtered.filter((e) => Number(e.semester) % 2 !== 0);
        }
      }

      if (filterAttribute.semester !== "") {
        filtered = filtered.filter(
          (e) => Number(e.semester) === Number(filterAttribute.semester)
        );
      }

      const uniqueFiltered = Array.from(new Set(filtered.map((a) => a.id))).map(
        (id) => {
          return filtered.find((a) => a.id === id);
        }
      );

      setFilterStudentRating(uniqueFiltered);
    }
  }, [
    filterAttribute,
    filterAttribute.program,
    filterAttribute.branch,
    filterAttribute.season,
    filterAttribute.semester,
    studentsRating,
  ]);

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const toggleShow = () => {
    setShow(!show);
  };
  const toggleShow2 = () => {
    setShow2(!show2);
  };

  const FinalUpload = async () => {
    try {
      // await Promise.all(
      //   filteredRatings.map(async (e) => {
      //     if (!e.pushedToDB) {
      //       const data = {
      //         facultyId: e.facultyId,
      //         CourseId: e.CourseId,
      //         Session: e.Session,
      //         Semester: e.Semester,
      //         R_A: e.R_A,
      //         R_B: e.R_B,
      //         R_C: e.R_C,
      //       };

      //       await Promise.resolve(apis.updateRatingOfFaculty(data)).then(
      //         async () => {
      //           await Promise.resolve(apis.updateUploadToDB(e.id));
      //         }
      //       );
      //     }
      //   })
      // ).then(() => {
      //   window.location.reload();
      //   alert("All data uploaded successfully");
      // });
      let ratingData = filteredRatings.map((e) => {
        return {
          id: e.id,
          facultyId: e.facultyId,
          CourseId: e.CourseId,
          Session: e.Session,
          Semester: e.Semester,
          Branch: e.Branch,
          R_A: e.R_A,
          R_B: e.R_B,
          R_C: e.R_C,
          pushedToDB: e.pushedToDB,
        };
      });
      await Promise.resolve(apis.updateRatingOfAtOnce({ratingData})).then(
        () => {
          alert("All data uploaded successfully");
          window.location.reload();
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const checkReviewAssigned = async () => {
    try {
      setLoading(true);
      const queryParameters = {
        branch: filterAttribute?.branch,
        branch: filterAttribute?.branch?.replace(/&/g, "%26"),
        session: filterAttribute?.session,
        semester: filterAttribute?.semester,
        course: filterAttribute?.program,
      };
      await Promise.resolve(apis.getIsRatingAssigned(queryParameters)).then(
        (data) => {
          if (data > 0) {
            setIsReviewAssigned(true);
          } else if (data == 0) {
            setIsReviewAssigned(false);
          }
          setLoading(false);
        }
      );
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    checkReviewAssigned();
  }, [filterAttribute]);

  const assignFeedbackToStudents = async () => {
    try {
      const bodyData = {
        branch: filterAttribute?.branch,
        session: filterAttribute?.session,
        semester: filterAttribute?.semester,
        course: filterAttribute?.program,
      };

      //if any of the queryParameters contains empty string, then show alert showing the list of items that are empty
      if (Object.values(bodyData).includes("")) {
        let emptyItems = Object.keys(bodyData).filter(
          (key) => bodyData[key] === ""
        );
        alert(`Please select ${emptyItems.join(", ")}`);
        return;
      }

      await Promise.resolve(apis.assignReviewPageToStudents(bodyData)).then(
        () => {
          alert("Feedback assigned to students successfully");
          setIsReviewAssigned(true);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  async function downloadCsv() {
    const headers = [
      {
        label: "Roll",
        value: "student_roll",
      },
      {
        label: "Course",
        value: "course",
      },
      {
        label: "Branch",
        value: "branch",
      },
      {
        label: "Semester",
        value: "semester",
      },
    ];

    const downloadData = filterStudentRating.map((e) => {
      return {
        student_roll: e.student_roll,
        course: e.course,
        branch: e.branch,
        semester: e.semester,
      };
    });

    await apis
      .downloadRemainingReviewList(downloadData, headers)
      .then((data) => {
        let url = window.URL.createObjectURL(data);
        window.open(url, +"_blank");
        alert("Downloaded");
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  return (
    <div>
      <AdminHeader />
      <h2 className="ReviewHeading">Ratings Review Page</h2>
      <div className="filterContainer7">
        <div className="filterSection7">
          <div className="filterItem">
            <p>Choose Session:</p>
            <select
              onChange={(e) => {
                setFilterAttribute((e1) => {
                  return { ...e1, session: e.target.value };
                });
              }}
            >
              <option disabled>Session</option>
              {generateSessionList().map((e, i) => {
                return (
                  <option key={i} value={e}>
                    {e}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="filterItem">
            <p>Select Program:</p>
            <select
              onChange={(e) => {
                setFilterAttribute((e1) => {
                  return { ...e1, program: e.target.value };
                });
              }}
            >
              <option value={""}>Program</option>
              <option value="B.TECH.">B.Tech</option>
              <option value="M.TECH.">M.Tech</option>
            </select>
          </div>
          <div className="filterItem">
            <p>Select Branch</p>
            <select
              onChange={(e) => {
                setFilterAttribute((e1) => {
                  return { ...e1, branch: e.target.value };
                });
              }}
            >
              <option value={""}>Branch</option>
              {filterAttribute.program === "B.TECH."
                ? BTechCourses.map((e, i) => {
                    return (
                      <option key={i} value={e}>
                        {e}
                      </option>
                    );
                  })
                : filterAttribute.program === "M.TECH."
                ? MTechCourses.map((e, i) => {
                    return (
                      <option key={i} value={e}>
                        {e}
                      </option>
                    );
                  })
                : null}
            </select>
          </div>
          <div className="filterItem">
            <p>Choose Season:</p>
            <select
              onChange={(e) => {
                setFilterAttribute((e1) => {
                  return { ...e1, season: e.target.value };
                });
              }}
            >
              <option value={""}>Season</option>
              <option value="Spring">Spring</option>
              <option value="Autumn">Autumn</option>
            </select>
          </div>
          <div className="filterItem">
            <p className="">Select Semester:</p>
            <select
              onChange={(e) => {
                setFilterAttribute((e1) => {
                  return { ...e1, semester: e.target.value };
                });
              }}
            >
              <option value={""}>Semester</option>
              {filterAttribute.program === "B.TECH." ? (
                <>
                  {/* <option value="All">All</option> */}
                  {filterAttribute.season === "Spring" ? (
                    <>
                      <option value="2">2nd</option>
                      <option value="4">4th</option>
                      <option value="6">6th</option>
                      <option value="8">8th</option>
                    </>
                  ) : filterAttribute.season === "Autumn" ? (
                    <>
                      <option value="1">1st</option>
                      <option value="3">3rd</option>
                      <option value="5">5th</option>
                      <option value="7">7th</option>
                    </>
                  ) : null}
                </>
              ) : filterAttribute.program === "M.TECH." ? (
                <>
                  <option value="All">All</option>
                  {filterAttribute.season === "Spring" ? (
                    <>
                      <option value="2">2nd</option>
                      <option value="4">4th</option>
                      <option value="6">6th</option>
                    </>
                  ) : filterAttribute.season === "Autumn" ? (
                    <>
                      <option value="1">1st</option>
                      <option value="3">3rd</option>
                      <option value="5">5th</option>
                    </>
                  ) : null}
                </>
              ) : null}
            </select>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="">Loading...</div>
      ) : isReviewAssigned ? (
        <>
          <div className="reviewContainer">
            <div className="countContainer">
              <div className="CountCard">
                <span className="count">{totalStudentCount}</span>
                <span className="countText">Total Students</span>
              </div>
              <div className="CountCard">
                <span className="count">{totalRatingCount}</span>
                <span className="countText">Review Submitted</span>
              </div>
            </div>
            <div className="viewLogs">
              <div onClick={toggleShow} className="viewLogHeading">
                <p className="">View Review Logs in Realtime</p>
                <FiChevronDown />
              </div>
              {show && (
                <table className="myCourse_table">
                  <thead className="myCourse_head">
                    <tr>
                      <th style={{ width: "15vw" }}>Faculty Name</th>
                      <th style={{ width: "22vw" }}>Course Name</th>
                      <th>Course Code</th>
                      <th>Branch</th>
                      <th>Semester</th>
                      <th style={{ width: "7vw" }}>Rating</th>
                    </tr>
                  </thead>
                  <tbody className="myCourse_body">
                    {filteredRatings.map((e, i) => {
                      return (
                        <tr key={i}>
                          <td>{e.facultyName}</td>
                          <td>{e.courseName}</td>
                          <td>{e.courseCode}</td>
                          <td>{e.Branch}</td>
                          <td>{e.Semester}</td>
                          <td>{e.ratingSubmitted ? "Submitted" : "Pending"}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>

            <div className="bottomSec">
              <button onClick={FinalUpload} className="uploadButton primary">
                Upload to Database
              </button>
            </div>
          </div>
          <div className="remainingListDiv">
            {/* list of students not submitted review */}
            <div onClick={toggleShow2} className="viewLogHeading">
              <p className="">List of Students not submitted the review</p>
              <FiChevronDown />
            </div>
            {show2 && (
              <>
                <table className="myCourse_table listTable">
                  <thead className="myCourse_head">
                    <tr>
                      <th style={{ width: "15vw" }}>Roll No.</th>
                      <th>Course</th>
                      <th>Branch</th>
                      <th>Semester</th>
                    </tr>
                  </thead>
                  <tbody className="myCourse_body">
                    {filterStudentRating.map((e, i) => {
                      return (
                        <tr key={i}>
                          <td>{e.student_roll}</td>
                          <td>{e.course}</td>
                          <td>{e.branch}</td>
                          <td>{e.semester}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <button
                  onClick={() => downloadCsv()}
                  className="uploadButton primary downloadBTN"
                >
                  Start exporting now as .csv file{" "}
                  <i className="fa-solid fa-circle-arrow-right"></i>
                </button>
              </>
            )}
          </div>
        </>
      ) : (
        <div className="assignCont">
          <button
            onClick={() => assignFeedbackToStudents()}
            className="assignBtn primary"
          >
            Assign Teacher's Feedback form to Students
          </button>
        </div>
      )}
      <Link to={"/student/admin/feedback"} className="veiwBtn">
        {" "}
        <button className="uploadButton primary">View Feedback</button>
      </Link>
    </div>
  );
};

export default Ratings;
