import React, { useState, useEffect } from "react";
import "../css/Component/PaymentSummary.css";
import apis from "../apis";
import { AuthProvider } from "../auth/AuthContext";


const semesterPattern = (sem) => {
  if (sem === "1") {
    return `${sem}st (${sem & 1 ? "Autumn" : "Spring"})`;
  } else if (sem === "2") {
    return `${sem}nd (${sem & 1 ? "Autumn" : "Spring"})`;
  } else if (sem === "3") {
    return `${sem}rd (${sem & 1 ? "Autumn" : "Spring"})`;
  } else {
    return `${sem}th (${sem & 1 ? "Autumn" : "Spring"})`;
  }
};


const PaymentSummary = () => {
  const { userData } = AuthProvider();
  const [paymentData, setPaymentData] = useState();
  const [feeStructure, setFeeStructure] = useState();
  const [hostelData, setHostelData] = useState();
  // const [totalDue, setTotalDue] = useState();

  async function fetchHostelStatus() {
    await apis
      .hostelStatus()
      .then((result) => {
        setHostelData({
          status: result?.status,
          allotedHostel: result?.hostel,
          canPay: result?.canPay,
        });
      })
      .catch((err) => {
        alert(err.message);
      });
  }
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const allotHostelFee = () => {
    let amount = 0;
    if (hostelData?.allotedHostel === "H1") {
      amount += Number(feeStructure?.h1fee) + Number(feeStructure?.messfee);
    } else if (hostelData?.allotedHostel === "H9") {
      amount += Number(feeStructure?.h9fee) + Number(feeStructure?.messfee);
    } else if (hostelData?.allotedHostel === "H7") {
      amount += Number(feeStructure?.h7fee) + Number(feeStructure?.messfee);
    } else if (hostelData?.allotedHostel === "NBH") {
      amount += Number(feeStructure?.nbhfee) + Number(feeStructure?.messfee);
    } else if (hostelData?.allotedHostel === "GH") {
      amount += Number(feeStructure?.ghfee) + Number(feeStructure?.messfee);
    }else if (hostelData?.allotedHostel === "KBH") {
      amount += Number(feeStructure?.kbhfee) + Number(feeStructure?.messfee);
    }else if (hostelData?.allotedHostel === "KGH") {
      amount += Number(feeStructure?.kghfee) + Number(feeStructure?.messfee);
    }
    return amount;
  };

  useEffect(() => {
    const fetchData = async () => {
      await apis.specificStudent({ roll: userData?.roll, semester: userData?.semester }).then(async (result) => {
        
        await Promise.all([
          apis.specificStudent({ roll: userData?.roll, semester: userData?.semester}),
          apis.feeStructure(
            userData?.year,
            userData?.course,
            userData?.semester,
            userData?.batch,
            result[0]?.type
          ),
        ])
          .then((data) => {
            setPaymentData(data[0][0]);
            setFeeStructure(data[1]);
          })
          .catch((err) => {
            alert(err.message);
          });
      }).catch((err) => {
        alert(err.message);
      });
    };
    fetchData();
    fetchHostelStatus();
  }, [userData]);

  // const updatePayment = useCallback(async (paymentStatus) => {
  //   const data = {
  //     roll: userData?.roll,
  //     paymentStatus: paymentStatus,
  //   }
  //   userData?.roll && await apis
  //     .updatePaymentStatus(data)
  //     .then((result) => {
  //       // payment status updated
  //     })
  //     .catch((err) => {
  //       alert(err.message);
  //     });
  // }, [userData?.roll]);
  const totalDue = () => {
    let totalDue =
      Number(feeStructure?.tuitionfee) +
      Number(feeStructure?.otherfee) +
      Number(feeStructure?.admissionfee) +
      Number(feeStructure?.cautionmoney) +
      Number(feeStructure?.convocationfee) +
      Number(paymentData?.instFine) -
      Number(paymentData?.instAdjust) +
      Number(paymentData?.hostelFine) -
      Number(paymentData?.hostelAdjust) +
      Number(paymentData?.messFine) -
      Number(paymentData?.messAdjust) +
      Number(allotHostelFee());
    
    let total =
      totalDue -
      (Number(paymentData?.currentSemesterPaid?.institute) +
        Number(paymentData?.currentSemesterPaid?.hostel) +
        Number(paymentData?.currentSemesterPaid?.mess));
    
    // setTotalDue(total+ Number(paymentData?.pastDue) + Number(paymentData?.currentSemesterPaidExtra));
    return total;
  }

  // useEffect(() => {
  //   setTotalDue(totalDue1() + Number(paymentData?.pastDue) + Number(paymentData?.currentSemesterPaidExtra));
  //   // 
  //   let feesToBePaid =
  //     Number(feeStructure?.tuitionfee) +
  //     Number(feeStructure?.otherfee) +
  //     Number(feeStructure?.admissionfee) +
  //     Number(feeStructure?.cautionmoney) +
  //     Number(feeStructure?.convocationfee) +
  //     Number(paymentData?.instFine) -
  //     Number(paymentData?.instAdjust) +
  //     Number(paymentData?.hostelFine) -
  //     Number(paymentData?.hostelAdjust) +
  //     Number(paymentData?.messFine) -
  //     Number(paymentData?.messAdjust) +
  //     Number(allotHostelFee()) + Number(paymentData?.pastDue);
  //   if (totalDue && totalDue <= 0) {
  //     updatePayment("Paid");
  //   } else if (totalDue && totalDue > 0 && totalDue < feesToBePaid) {
  //     updatePayment("Pending");
  //   }
  // }, [allotHostelFee, feeStructure, paymentData, totalDue, totalDue1, updatePayment]);



  return (
    <div className="summary">
      <div className="summary-div">
        <div className="summary-left-div">
          <div className="summary-left-item">
            <h3>
              Name: <span>{userData?.name}</span>
            </h3>
          </div>
          <div className="summary-left-item">
            <h3>
              Roll: <span>{userData?.roll}</span>
            </h3>
          </div>
          <div className="summary-left-item">
            <h3>
              Course: <span>{userData?.course}</span>
            </h3>
          </div>
          <div className="summary-left-item">
            <h3>
              Batch: <span>{userData?.batch}</span>
            </h3>
          </div>
          <div className="summary-left-item">
            <h3>
              Branch: <span>{userData?.branch}</span>
            </h3>
          </div>
          <div className="summary-left-item">
            <h3>
              CGPA: <span>{userData?.cgpa}</span>
            </h3>
          </div>
          <div className="summary-left-item">
            <h3>
              Backlogs: <span>{userData?.backlogs}</span>
            </h3>
          </div>
          <div className="summary-left-item">
            <h3>
              Current Semester:{" "}
              <span>{semesterPattern(userData?.semester)}</span>
            </h3>
          </div>
        </div>
        <div className="summary-right-div">
          <div className="summary-right-item">
            <h3>
              Current Semester Paid: <span>{Number(paymentData?.currentSemesterPaid?.institute) + Number(paymentData?.currentSemesterPaid?.hostel) + Number(paymentData?.currentSemesterPaid?.mess)} </span><br />
              <span>Institute: {paymentData?.currentSemesterPaid?.institute}, Hostel: {paymentData?.currentSemesterPaid?.hostel}, Mess: {paymentData?.currentSemesterPaid?.mess} </span>
            </h3>
          </div>
          <div className="summary-right-item">
            <h3>
              Current Semester Payable:{" "}
              <span>{totalDue() + Number(paymentData?.currentSemesterPaidExtra)}</span>
            </h3>
          </div>
          <div className="summary-right-item">
            <h3>
              Past Due: <span>{paymentData?.pastDue}</span>
            </h3>
          </div>
          <div className="summary-right-item">
            <h3>
              Adjusted amounts (total): <span>{Number(paymentData?.instAdjust) + Number(paymentData?.hostelAdjust) + Number(paymentData?.messAdjust)}</span> <br />
              <span>Institute: {paymentData?.instAdjust}, Hostel: {paymentData?.hostelAdjust}, Mess: {paymentData?.messAdjust}</span>
            </h3>
          </div>
          <div className="summary-right-item">
            <h3>
              Fine (total): <span>{Number(paymentData?.instFine) + Number(paymentData?.hostelFine) + Number(paymentData?.messFine)}</span> <br />
              <span>Institute: {paymentData?.instFine}, Hostel: {paymentData?.hostelFine}, Mess: {paymentData?.messFine}</span>
            </h3>
            <div className="summary-right-item">
              <h3>
                Total Due: <span>{totalDue() + Number(paymentData?.pastDue) + Number(paymentData?.currentSemesterPaidExtra)}</span> <br />
                <span>(*final amount to be paid)</span>
              </h3>
            </div>
          </div>
        </div>
        {/* <strong className="NoteOnPaymentDetails">
        <span style={{ color: "red" }}>**</span> Please note that since this
        semester payment is not done using our platform, so only your remaining
        college due is shown.
        </strong> */}
      </div>
    </div>
  );
};

export default PaymentSummary;
