import React, { createContext, useContext, useEffect } from "react";
import apis from "../apis";
// import { AuthProvider } from "../auth/AuthContext";

const SearchContext = createContext();

function SearchProvider({ children }) {
  const [search, setSearch] = React.useState({});
  const [filters, setFilters] = React.useState({});
  const [specific, setSpecific] = React.useState({});
  const [searchResult, setSearchResult] = React.useState([]);
  const [download, setDownload] = React.useState([{}]);
  const [searchLoading, setSearchLoading] = React.useState(false);
  const [abortController, setAbortController] = React.useState(null);
  const [abortController2, setAbortController2] = React.useState(null);

  async function fetchSearch() {
    if (abortController) {
      abortController.abort(); // Abort the previous request if it's still running
    }

    const newAbortController = new AbortController(); // Create a new AbortController for the new request
    setAbortController(newAbortController);

    setSearchLoading(true);
    
    try {
      const data = await apis.searchStudents(search, newAbortController.signal);
      setSearchResult(data);
    } catch (err) {
      if (err?.name !== 'AbortError') { // Handle errors that are not related to aborting
        if(err?.message){
          alert(err?.message);
        }
      }
    } finally {
      setSearchLoading(false);
    }
  }

  async function fetchFilters() {
    setSearchLoading(true);
    await apis
      .filterStudents(search)
      .then((data) => {
        setSearchResult(data);
        setSearchLoading(false);
      })
      .catch((err) => {
        alert(err.message);
        setSearchLoading(false);
      });
  }

  async function fetchSpecific() {
    
    if (abortController2) {
      abortController2.abort(); // Abort the previous request if it's still running
    }

    const newAbortController2 = new AbortController(); // Create a new AbortController for the new request
    setAbortController2(newAbortController2);
    abortController.abort();

    setSearchLoading(true);
    await apis
      .specificStudent1(specific, newAbortController2.signal)
      .then((data) => {
        setSearchResult(data);
        setSearchLoading(false);
      })
      .catch((err) => {
        alert(err.message);
        setSearchLoading(false);
      });
  }

  useEffect(() => {
    // Cleanup function to abort the request when the component is unmounted
    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [abortController]);

  return (
    <SearchContext.Provider
      value={{
        search,
        setSearch,
        filters,
        setFilters,
        specific,
        setSpecific,
        fetchSearch,
        fetchFilters,
        fetchSpecific,
        searchResult,
        setDownload,
        download,
        searchLoading,
        setSearchLoading,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}

export const useSearch = () => {
  return useContext(SearchContext);
};

export default SearchProvider;
