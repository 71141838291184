import React from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#FF4500"];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const renderActiveShape = (props, activeIndex) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;

  if (activeIndex === -1) {
    return null;
  }

  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      {/* <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text> */}
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${activeIndex == 0 ? "Excellent" : activeIndex == 1 ? "Very Good" : activeIndex == 2 ? "Good" : activeIndex == 1 ? "Fair" : "Poor"} ${value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const PieChartComp = ({eachRatingCount}) => {
  const [datas, setDatas] = React.useState([]);

  const createDataArray = () => {
    let data = [];
    for (let i = 0; i < eachRatingCount.length; i++) {
      data.push({
        name: i == 0 ? "Excellent" : i == 1 ? "Very Good" : i == 2 ? "Good" : i == 3 ? "Fair" : "Poor",
        value: eachRatingCount[i],
      });
    }
    setDatas(data);
  }

  React.useEffect(() => {
    if (eachRatingCount) {
      createDataArray();
    }
  }, [eachRatingCount, setDatas]);

  const [state, setState] = React.useState({
    activeIndex: 0,
  });

  const onPieEnter = (index) => {
    setState({
      activeIndex: index,
    });
  };

  const onPieLeave = () => {
    setState({
      activeIndex: -1,
    });
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <div className="pietitle">Total Rating Distribution</div>
      <PieChart width={400} height={300}>
        <Pie
          data={datas}
          activeIndex={state.activeIndex}
          activeShape={(props) => renderActiveShape(props, state.activeIndex)}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={100}
          fill="#8884d8"
          dataKey="value"
          onMouseLeave={() => onPieLeave()}
        >
          {datas.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={COLORS[index % COLORS.length]}
              onMouseOver={() => onPieEnter(index)}
              onMouseLeave={() => onPieLeave()}
            />
          ))}
        </Pie>
        {/* <Tooltip /> */}
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartComp;
