import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import apis from "../apis";
import "../css/Component/PaymentDetails.css";

const PaymentDetails = ({ studentData, adminData }) => {
  const [update, setUpdate] = useState(false);
  const [updateHostel, setUpdateHostel] = useState(false);
  const [feeStructure, setFeeStructure] = useState();
  const [personalData, setPersonalData] = useState({
    pastDue: studentData?.pastDue,
    instFine: studentData?.instFine,
    hostelFine: studentData?.hostelFine,
    messFine: studentData?.messFine,
    instAdjust: studentData?.instAdjust,
    hostelAdjust: studentData?.hostelAdjust,
    messAdjust: studentData?.messAdjust,
    currentSemesterPaid: studentData?.currentSemesterPaid,
    currentSemesterPaidExtra: studentData?.currentSemesterPaidExtra,
    pcontact: studentData?.pcontact,
    gname: studentData?.gname,
    gcontact: studentData?.gcontact,
    gemail: studentData?.gemail,
    grelation: studentData?.grelation,
    caddress: studentData?.caddress,
    paddress: studentData?.paddress,
    opt: studentData?.opt,
    hostel: studentData?.hostel,
    room: studentData?.room,
    previousHostel: studentData?.previousHostel,
  });

  const [checkUpdate, setCheckUpdate] = useState({
    instFine: false,
    hostelFine: false,
    messFine: false,
    instAdjust: false,
    hostelAdjust: false,
    messAdjust: false,
    hostel: false,
    room: false,
    previousHostel: false,
  });

  useEffect(() => {
    setPersonalData({
      pastDue: studentData?.pastDue,
      instFine: studentData?.instFine,
      hostelFine: studentData?.hostelFine,
      messFine: studentData?.messFine,
      instAdjust: studentData?.instAdjust,
      hostelAdjust: studentData?.hostelAdjust,
      messAdjust: studentData?.messAdjust,
      currentSemesterPaid: studentData?.currentSemesterPaid,
      currentSemesterPaidExtra: studentData?.currentSemesterPaidExtra,
      pcontact: studentData?.pcontact,
      gname: studentData?.gname,
      gcontact: studentData?.gcontact,
      gemail: studentData?.gemail,
      grelation: studentData?.grelation,
      caddress: studentData?.caddress,
      paddress: studentData?.paddress,
      opt: studentData?.opt,
      hostel: studentData?.hostel,
      room: studentData?.room,
      previousHostel: studentData?.previousHostel,
    });

    setCheckUpdate({
      instFine: false,
      hostelFine: false,
      messFine: false,
      instAdjust: false,
      hostelAdjust: false,
      messAdjust: false,
      hostel: false,
      room: false,
      previousHostel: false,
    });
  }, [studentData, adminData]);

  const saveUpdate = async () => {
    let updateData = {};
    if (checkUpdate.instFine) {
      updateData.instFine = personalData.instFine;
    }
    if (checkUpdate.hostelFine) {
      updateData.hostelFine = personalData.hostelFine;
    }
    if (checkUpdate.messFine) {
      updateData.messFine = personalData.messFine;
    }
    if (checkUpdate.instAdjust) {
      updateData.instAdjust = personalData.instAdjust;
    }
    if (checkUpdate.hostelAdjust) {
      updateData.hostelAdjust = personalData.hostelAdjust;
    }
    if (checkUpdate.messAdjust) {
      updateData.messAdjust = personalData.messAdjust;
    }

    const givenData = {
      roll: studentData.roll,
      applicationid: studentData.applicationid,
      semester: studentData.semester,
    };

    const data = {
      updateData,
      givenData,
    };

    try {
      await apis.updateNewProfile(data).then((data) => {
        console.log(data);
        alert("Data Updated Successfully");
        setUpdate(false);

        studentData.instFine = personalData.instFine;
        studentData.hostelFine = personalData.hostelFine;
        studentData.messFine = personalData.messFine;
        studentData.instAdjust = personalData.instAdjust;
        studentData.hostelAdjust = personalData.hostelAdjust;
        studentData.messAdjust = personalData.messAdjust;

        setCheckUpdate({
          ...checkUpdate,
          instFine: false,
          hostelFine: false,
          messFine: false,
          instAdjust: false,
          hostelAdjust: false,
          messAdjust: false,
        });
      });
    } catch (error) {
      alert(error.message);
      setCheckUpdate({
        ...checkUpdate,
        instFine: false,
        hostelFine: false,
        messFine: false,
        instAdjust: false,
        hostelAdjust: false,
        messAdjust: false,
      });
    }
  };

  const saveUpdateHostel = async () => {
    let updateData = {};
    if (checkUpdate.hostel) {
      updateData.hostel = personalData.hostel;
    }
    if (checkUpdate.room) {
      updateData.room = personalData.room;
    }
    if (checkUpdate.previousHostel) {
      updateData.previousHostel = personalData.previousHostel;
    }

    const givenData = {
      roll: studentData.roll,
      applicationid: studentData.applicationid,
      semester: studentData.semester,
    };

    const data = {
      updateData,
      givenData,
    };

    try {
      await apis.updateNewProfile(data).then((data) => {
        console.log(data);
        alert("Data Updated Successfully");
        setUpdateHostel(false);

        studentData.hostel = personalData.hostel;
        studentData.room = personalData.room;
        studentData.previousHostel = personalData.previousHostel;

        setCheckUpdate({
          ...checkUpdate,
          hostel: false,
          room: false,
          previousHostel: false,
        });
      });
    } catch (error) {
      alert(error.message);
      setCheckUpdate({
        ...checkUpdate,
        hostel: false,
        room: false,
        previousHostel: false,
      });
    }
  };

  const cancelUpdate = () => {
    setUpdate(false);
    setPersonalData({
      ...personalData,
      instFine: studentData?.instFine,
      hostelFine: studentData?.hostelFine,
      messFine: studentData?.messFine,
      instAdjust: studentData?.instAdjust,
      hostelAdjust: studentData?.hostelAdjust,
      messAdjust: studentData?.messAdjust,
    });

    setCheckUpdate({
      ...checkUpdate,
      instFine: false,
      hostelFine: false,
      messFine: false,
      instAdjust: false,
      hostelAdjust: false,
      messAdjust: false,
    });
  };

  const cancelUpdateHostel = () => {
    setUpdateHostel(false);
    setPersonalData({
      ...personalData,
      hostel: studentData?.hostel,
      room: studentData?.room,
      previousHostel: studentData?.previousHostel,
    });

    setCheckUpdate({
      ...checkUpdate,
      hostel: false,
      room: false,
      previousHostel: false,
    });
  };

  useEffect(() => {
    const getFeeStructure = async () => {
      await apis
        .feeStructure(
          studentData.year,
          studentData.course,
          studentData.semester,
          studentData.batch,
          studentData.type
        )
        .then((data) => {
          console.log(data);
          setFeeStructure(data);
        })
        .catch((err) => {
          alert(err.message);
        });
    };
    getFeeStructure();
  }, [studentData]);

  const [totalHostelPayable, setTotalHostelPayable] = useState(0);
  const [totalMessPayable, setTotalMessPayable] = useState(0);

  useEffect(() => {
    if (personalData && feeStructure) {
      const totalHostel = personalData.opt
        ? personalData.hostel === "KBH"
          ? Number(feeStructure?.kbhfee) -
            Number(personalData.currentSemesterPaid.hostel) -
            Number(personalData.hostelAdjust) +
            Number(personalData.hostelFine)
          : personalData.hostel === "KGH"
          ? Number(feeStructure?.kghfee) -
            Number(personalData.currentSemesterPaid.hostel) -
            Number(personalData.hostelAdjust) +
            Number(personalData.hostelFine)
          : personalData.hostel === "H1"
          ? Number(feeStructure?.h1fee) -
            Number(personalData.currentSemesterPaid.hostel) -
            Number(personalData.hostelAdjust) +
            Number(personalData.hostelFine)
          : Number(0) -
            Number(personalData.currentSemesterPaid.hostel) -
            Number(personalData.hostelAdjust) +
            Number(personalData.hostelFine)
        : Number(0);

      const totalMess = personalData.opt
        ? Number(feeStructure?.messfee) -
          Number(personalData.currentSemesterPaid.mess) -
          Number(personalData.messAdjust) +
          Number(personalData.messFine)
        : Number(0);

      setTotalHostelPayable(totalHostel);
      setTotalMessPayable(totalMess);
    }
  }, [personalData, feeStructure]);

  const calculateTotalFeeStructure = (feeStructureData, hostelData) => {
    let total = 0;
    if (feeStructureData) {
      total =
        Number(feeStructureData.tuitionfee) +
        Number(feeStructureData.otherfee) +
        Number(feeStructureData.convocationfee) +
        Number(feeStructureData.cautionmoney) +
        Number(feeStructureData.admissionfee);
    }
    if (hostelData) {
      total += hostelData?.opt
        ? personalData.hostel === "KBH"
          ? Number(feeStructure?.kbhfee)
          : personalData.hostel === "KGH"
          ? Number(feeStructure?.kghfee)
          : personalData.hostel === "H1"
          ? Number(feeStructure?.h1fee)
          : 0
        : 0;

      total += hostelData?.opt ? Number(feeStructure?.messfee) : 0;
    }
    return total;
  };

  return (
    <div className="otherPageCont">
      <div className="h_top">
        <div className="h_leftPart">
          <div className="h_upperHalf">
            <p className="h_title">Current Semester Paid</p>
            <div className="h_left">
              <div className="h_col">
                <div className={`h_content ${update && "h_update"}`}>
                  <span>Institute Fee: </span>
                  <p style={{ margin: "0px" }}>
                    ₹ {personalData.currentSemesterPaid.institute}
                  </p>
                </div>
                <div className={`h_content ${update && "h_update"}`}>
                  <span>Hostel Fee: </span>
                  <p style={{ margin: "0px" }}>
                    ₹ {personalData.currentSemesterPaid.hostel}
                  </p>
                </div>
                <div className={`h_content ${update && "h_update"}`}>
                  <span>Mess Fee: </span>
                  <p style={{ margin: "0px" }}>
                    ₹ {personalData.currentSemesterPaid.mess}
                  </p>
                </div>
              </div>
              <div className="h_col">
                <div className={`h_content ${update && "h_update"}`}>
                  <span>JOSA/CSAB/CCMT Payment: </span>
                  <p style={{ margin: "0px" }}>
                    ₹ {Number(studentData?.alreadyPaid)}
                  </p>
                </div>
                <div className={`h_content ${update && "h_update"}`}>
                  <span>Total Bank Piad: </span>
                  <p style={{ margin: "0px" }}>
                    ₹ {Number(studentData?.totalBankPaid)}
                  </p>
                </div>
                <div className={`h_content ${update && "h_update"}`}>
                  <span>Paid Extra: </span>
                  <p style={{ margin: "0px" }}>
                    ₹{" "}
                    {Number(studentData?.totalBankPaid) +
                      Number(studentData?.alreadyPaid) -
                      calculateTotalFeeStructure(feeStructure, personalData)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="h_lowerHalf">
            <p className="h_title">Current Semester Fee Structure</p>
            <div className="h_left">
              <div className={`h_content ${update && "h_update"}`}>
                <span>Institute Fee: </span> ₹{" "}
                {Number(feeStructure?.tuitionfee) +
                  Number(feeStructure?.otherfee) +
                  Number(feeStructure?.convocationfee) +
                  Number(feeStructure?.cautionmoney) +
                  Number(feeStructure?.admissionfee)}
              </div>
              <div className={`h_content ${update && "h_update"}`}>
                <span>Hostel Fee: </span>
                {personalData.opt
                  ? `₹${
                      personalData.hostel === "KBH"
                        ? feeStructure?.kbhfee
                        : personalData.hostel === "KGH"
                        ? feeStructure?.kghfee
                        : personalData.hostel === "H1"
                        ? feeStructure?.h1fee
                        : 0
                    }`
                  : `₹0`}
              </div>
              <div className={`h_content ${update && "h_update"}`}>
                <span>Mess Fee: </span>
                {personalData.opt ? `₹${feeStructure?.messfee}` : `₹0`}
              </div>
            </div>
          </div>
        </div>
        <div className="h_rightPart">
          <p className="h_title">Current Semester Payable</p>
          <div className="h_right">
            <div className="profile_edits">
              {(adminData?.role?.includes("DOAA") ||
                adminData?.role?.includes("Super Admin") ||
                adminData?.role?.includes("DOSA") ||
                adminData?.role?.includes("Warden") ||
                adminData?.role?.includes("HAB") ||
                adminData?.role?.includes("Academic Admin")) &&
                (!update ? (
                  <EditIcon onClick={() => setUpdate(true)} />
                ) : (
                  <div style={{ display: "flex", gap: "10px" }}>
                    <CheckCircleOutlineRoundedIcon
                      onClick={() => saveUpdate()}
                    />
                    <HighlightOffRoundedIcon onClick={() => cancelUpdate()} />
                  </div>
                ))}
            </div>
            <div className={`h_content ${update && "h_update"}`}>
              <span>Past Due: </span> {personalData.pastDue}
            </div>
            <div className={`h_content ${update && "h_update"}`}>
              <div className="h_col">
                <span>
                  Fine{`(total)`}:{" "}
                  <span style={{ fontWeight: "400" }}>
                    {` ₹`}
                    {Number(personalData.instFine) +
                      Number(personalData.hostelFine) +
                      Number(personalData.messFine)}
                  </span>
                </span>
                <div className="h_row">
                  <div className="">
                    <span>Institute: </span>
                    {update &&
                    !adminData?.role?.includes("HAB") &&
                    !adminData?.role?.includes("Warden") ? (
                      <input
                        className="profile_input"
                        style={{ width: "70%" }}
                        type="text"
                        value={personalData.instFine}
                        onChange={(e) => {
                          setCheckUpdate({ ...checkUpdate, instFine: true });
                          setPersonalData({
                            ...personalData,
                            instFine: e.target.value,
                          });
                        }}
                      />
                    ) : (
                      `₹${personalData.instFine}`
                    )}
                  </div>
                  <div className="">
                    <span>Hostel: </span>
                    {update ? (
                      <input
                        className="profile_input"
                        style={{ width: "70%" }}
                        type="text"
                        value={personalData.hostelFine}
                        onChange={(e) => {
                          setCheckUpdate({ ...checkUpdate, hostelFine: true });
                          setPersonalData({
                            ...personalData,
                            hostelFine: e.target.value,
                          });
                        }}
                      />
                    ) : (
                      `₹${personalData.hostelFine}`
                    )}
                  </div>
                  <div className="">
                    <span>Mess: </span>
                    {update ? (
                      <input
                        className="profile_input"
                        style={{ width: "70%" }}
                        type="text"
                        value={personalData.messFine}
                        onChange={(e) => {
                          setCheckUpdate({ ...checkUpdate, messFine: true });
                          setPersonalData({
                            ...personalData,
                            messFine: e.target.value,
                          });
                        }}
                      />
                    ) : (
                      `₹${personalData.messFine}`
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={`h_content ${update && "h_update"}`}>
              <div className="h_col">
                <span>
                  Adjusted Amount{`(total)`}:{" "}
                  <span style={{ fontWeight: "400" }}>
                    {` ₹`}
                    {Number(personalData.instAdjust) +
                      Number(personalData.hostelAdjust) +
                      Number(personalData.messAdjust)}
                  </span>
                </span>
                <div className="h_row">
                  <div className="">
                    <span>Institute: </span>
                    {update &&
                    !adminData?.role?.includes("HAB") &&
                    !adminData?.role?.includes("Warden") ? (
                      <input
                        className="profile_input"
                        type="text"
                        style={{ width: "70%" }}
                        value={personalData.instAdjust}
                        onChange={(e) => {
                          setCheckUpdate({ ...checkUpdate, instAdjust: true });
                          setPersonalData({
                            ...personalData,
                            instAdjust: e.target.value,
                          });
                        }}
                      />
                    ) : (
                      `₹${personalData.instAdjust}`
                    )}
                  </div>
                  <div className="">
                    <span>Hostel: </span>
                    {update ? (
                      <input
                        className="profile_input"
                        type="text"
                        style={{ width: "70%" }}
                        value={personalData.hostelAdjust}
                        onChange={(e) => {
                          setCheckUpdate({
                            ...checkUpdate,
                            hostelAdjust: true,
                          });
                          setPersonalData({
                            ...personalData,
                            hostelAdjust: e.target.value,
                          });
                        }}
                      />
                    ) : (
                      `₹${personalData.hostelAdjust}`
                    )}
                  </div>
                  <div className="">
                    <span>Mess: </span>
                    {update ? (
                      <input
                        className="profile_input"
                        type="text"
                        style={{ width: "70%" }}
                        value={personalData.messAdjust}
                        onChange={(e) => {
                          setCheckUpdate({ ...checkUpdate, messAdjust: true });
                          setPersonalData({
                            ...personalData,
                            messAdjust: e.target.value,
                          });
                        }}
                      />
                    ) : (
                      `₹${personalData.messAdjust}`
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={`h_content ${update && "h_update"}`}>
              <span>Institute Fee Payable: </span> ₹
              {Number(feeStructure?.tuitionfee) +
                Number(feeStructure?.otherfee) +
                Number(feeStructure?.convocationfee) +
                Number(feeStructure?.cautionmoney) +
                Number(feeStructure?.admissionfee) -
                Number(personalData.currentSemesterPaid.institute) -
                Number(personalData.instAdjust) +
                Number(personalData.instFine)}
            </div>
            <div className={`h_content ${update && "h_update"}`}>
              <span>Hostel Fee Payable: </span> ₹{totalHostelPayable}
            </div>
            <div className={`h_content ${update && "h_update"}`}>
              <span>Mess Fee Payable: </span> ₹{totalMessPayable}
            </div>
            <div className={`h_content ${update && "h_update"}`}>
              <span>Total Payable: </span> ₹
              {Number(feeStructure?.tuitionfee) +
                Number(feeStructure?.otherfee) +
                Number(feeStructure?.convocationfee) +
                Number(feeStructure?.cautionmoney) +
                Number(feeStructure?.admissionfee) -
                Number(personalData.currentSemesterPaid.institute) -
                Number(personalData.instAdjust) +
                Number(personalData.instFine) +
                Number(personalData.currentSemesterPaidExtra) +
                Number(personalData.pastDue) +
                totalHostelPayable +
                totalMessPayable}
            </div>
          </div>
        </div>
      </div>
      <div className="h_bottomPart">
        <p className="h_title" style={{ width: "90%", margin: "auto" }}>
          Hostel Info
        </p>
        <div className="h_bottom">
          <div className="profile_edits">
            {(adminData?.role?.includes("DOAA") ||
              adminData?.role?.includes("Super Admin") ||
              adminData?.role?.includes("HAB") ||
              adminData?.role?.includes("Warden")) &&
              (!updateHostel ? (
                <EditIcon onClick={() => setUpdateHostel(true)} />
              ) : (
                <div style={{ display: "flex", gap: "10px" }}>
                  <CheckCircleOutlineRoundedIcon
                    onClick={() => saveUpdateHostel()}
                  />
                  <HighlightOffRoundedIcon
                    onClick={() => cancelUpdateHostel()}
                  />
                </div>
              ))}
          </div>
          <div className={`h_content ${updateHostel && "h_update"}`}>
            <span>Current Semester Hostel: </span>
            {updateHostel ? (
              <input
                className="profile_input"
                type="text"
                style={{ width: "20%" }}
                value={personalData.hostel}
                onChange={(e) => {
                  setCheckUpdate({ ...checkUpdate, hostel: true });
                  setPersonalData({
                    ...personalData,
                    hostel: e.target.value,
                  });
                }}
              />
            ) : (
              personalData.hostel
            )}
          </div>
          <div className={`h_content ${updateHostel && "h_update"}`}>
            <span>Room Number: </span>
            {updateHostel ? (
              <input
                className="profile_input"
                type="text"
                style={{ width: "20%" }}
                value={personalData.room}
                onChange={(e) => {
                  setCheckUpdate({ ...checkUpdate, room: true });
                  setPersonalData({
                    ...personalData,
                    room: e.target.value,
                  });
                }}
              />
            ) : (
              personalData.room
            )}
          </div>
          <div className={`h_content ${updateHostel && "h_update"}`}>
            <span>Last Semester Hostel: </span>
            {updateHostel ? (
              <input
                className="profile_input"
                type="text"
                style={{ width: "20%" }}
                value={personalData.previousHostel}
                onChange={(e) => {
                  setCheckUpdate({ ...checkUpdate, previousHostel: true });
                  setPersonalData({
                    ...personalData,
                    previousHostel: e.target.value,
                  });
                }}
              />
            ) : (
              personalData.previousHostel
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;
