import * as React from "react";
import AdminHeader from "../components/AdminHeader";
import CourseRequest from "../components/CourseRequest";
import "../css/Page/CourseVerification.css";
import apis from "../apis";
import { AuthProvider } from "../auth/AuthContext";

const CourseVerification = () => {
  const [list, setList] = React.useState();
  const { adminData } = AuthProvider();
  const [facultyData, setFacultyData] = React.useState();
  const [search, setSearch] = React.useState({
    semester: null,
    paymentStatus: null,
    backlog: null,
  });
  const fetchFacultyData = React.useCallback(async () => {
    adminData?.id && await apis.getFaculty(adminData?.id).then((data) => {
      setFacultyData(data)
    }).catch((err) => {
      alert(err.message)
    })
  }, [adminData])
  React.useEffect(() => {
    const fetchData = async () => {
      await apis.fetchVerifiableCourses({ semester: search?.semester }).then((res) => {
        
        setList(res);
      }).catch((err) => {
        alert(err.message);
      });
    }
    fetchFacultyData();
    fetchData();
  }, [search, fetchFacultyData]);

  

  return (
    <>
      <AdminHeader />
      <div className="top-div">
        <div className="verification-requests">
          <div className="title cv">
            <div className="title-left-div">
              <h2>
                Verification Requests: <span>{list?.filter((e) => { return (facultyData?.branch?.find((e1) => e1 === e.branch) && facultyData?.batch?.find((e1) => e1 === e.batch) && facultyData?.program?.find((e1) => e1 === e.program)) || facultyData?.name === e.faculty })?.length}</span>
              </h2>
            </div>

            <div className="filter-item title-right-div">
              <h4>Filter by:</h4>

              <select
                id="program"
                value={search.program ? search.program : "0"}
                onChange={(e) =>
                  setSearch((e1) => {
                    return { ...e1, program: e.target.value };
                  })
                }
              >
                <option value="0" disabled>Program</option>
                <option value="B.TECH.">B.Tech</option>
                <option value="M.TECH.">M.Tech</option>
                <option value="Ph.D.">PhD</option>
              </select>

              <select
                id="semesters"
                value={search.semester ? search.semester : "0"}
                onChange={(e) =>
                  setSearch((e1) => {
                    return { ...e1, semester: e.target.value };
                  })
                }
              >
                <option value="0" disabled>Semester</option>
                <option value="1">I</option>
                <option value="2">II</option>
                <option value="3">III</option>
                <option value="4">IV</option>
                <option value="5">V</option>
                <option value="6">VI</option>
                <option value="7">VII</option>
                <option value="8">VIII</option>
              </select>

              <select
                name="batch"
                id="batch"
                value={search.batch ? search.batch : "all"}
                onChange={(e) =>
                  setSearch((e1) => {
                    return { ...e1, batch: e.target.value };
                  })
                }
              >
                <option disabled value="all">
                  Select Batch
                </option>
                <option value="2020-24">2020-24</option>
                <option value="2021-25">2021-25</option>
                <option value="2022-26">2022-26</option>
                <option value="2023-27">2023-27</option>
              </select>
            </div>
          </div>
          {
            list?.filter((e) => { return (facultyData?.branch?.find((e1) => e1 === e.branch) && facultyData?.batch?.find((e1) => e1 === e.batch) && facultyData?.program?.find((e1) => e1 === e.program)) || (facultyData?.name === e.faculty) })?.map((item, index) => {
              return <CourseRequest key={index} data={item} />
            })
          }
        </div>
      </div>
    </>
  );
};

export default CourseVerification;
