import React from "react";
import "../css/Page/NewStudentRegistration.css";
import NewStudentLogin from "../components/NewStudentLogin";

const NewStudentRegistration = () => {
  return (
    <>
      <div className="NewRegistrationPage">
        <div className="overlay-lefttoright">
          <div className="welcome-message-section">
            <div className="welcome-message-content">
              <h1>Congratulations 🎉</h1>
              <h3>Welcome to IIIT Bhagalpur!</h3>
              <p>
                To finish your enrolment, please complete the registration with
                your personal, academic details and fee payment. <br />
                This will help us to create your student profile, please enter
                accurate and complete information, as this will be used for
                official purposes. <br />
              </p>
              <p>Thank you for choosing IIIT Bhagalpur and see you soon!</p>
            </div>
          </div>
          <div className="new-login-section">
            <NewStudentLogin />
          </div>
        </div>
      </div>
    </>
  );
};

export default NewStudentRegistration;
