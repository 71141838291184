import * as React from "react";
import { useHostelContext } from "../contexts/HostelProvider";
import apis from "../apis";
import { AuthProvider } from "../auth/AuthContext";

const HostelRequestElement = (props) => {
  const { selectAll, setSelectAll, setSelectedData, selectedData } =
    useHostelContext();
  // const [selected, setSelected] = React.useState(false);
  const [room, setRoom] = React.useState(props?.room);
  const { adminData } = AuthProvider();
  let fix = false;

  // React.useEffect(() => {
  //   if (!fix) {
  //     setSelected(selectAll);
  //     fix = true;
  //   }
  // }, [selectAll, fix]);

  // const updateData = () => {
  //   if (!selected) {
  //     setSelectedData((e) => {
  //       return [...e, props?.id];
  //     });
  //   } else {
  //     let index = selectedData?.findIndex((e) => e === props?.id);
  //     if (index > -1) {
  //       setSelectedData((e) => e.splice(index, 1));
  //     }
  //   }
  // };

  const allotRoom = async () => {
    await apis.allotRoom({ id: props?.id, room }).then((res) => {
      alert("Room Alloted");
    }).catch((err) => {
      alert(err.message);
    })
  }

  return (
      <div className="roomCont">
        <input type="text" name="room" disabled={!adminData?.role?.find((e) => { return e === "Warden" }) && !adminData?.role?.find((e) => { return "Super Admin" }) && !adminData?.role?.find((e) => { return "HAB" })} id="room" value={room} className="roomInput" onChange={(e) => setRoom(e.target.value)} />
        {(adminData?.role?.find((e) => { return e === "Warden" }) || adminData?.role?.find((e) => { return e === "Super Admin" })) && <input type="submit" value="set" className="roomBtn" onClick={allotRoom} />}
      </div>
  );
};

export default HostelRequestElement;
