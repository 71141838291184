import React, { useEffect } from "react";
import apis from "../apis";
import Table from "../common/tables/Table";
import IosShareIcon from "@mui/icons-material/IosShare";

const MTechCourses = ["AI & DS", "VLSI & ES", "EV", "Micro & Comm.", "SPML"];

const BTechCourses = ["CSE", "ECE", "MAE", "MNC"];

const DebardSection = () => {
  const [filterAttribute, setFilterAttribute] = React.useState({
    session: "",
    program: "",
    branch: "",
    season: "",
    semester: "",
  });

  const [debarredStudents, setDebarredStudents] = React.useState([]);

  const generateSessionList = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const sessionList = [];
    for (let i = currentYear; i >= 2017; i--) {
      if (currentMonth < 6 && i === currentYear) continue;
      let nextYear = (i + 1).toString();
      sessionList.push(`${i}-${nextYear}`);
    }
    return sessionList;
  };

  useEffect(() => {
    const currentMonth = new Date().getMonth();
    if (currentMonth < 6) {
      setFilterAttribute({
        ...filterAttribute,
        session: generateSessionList()[0],
      });
    } else {
      setFilterAttribute({
        ...filterAttribute,
        session: generateSessionList()[1],
      });
    }
  }, []);

  const getDebardStudentsList = async () => {
    try {
      const queryParams = {
        session: filterAttribute.session,
        course: filterAttribute.program,
        branch: filterAttribute.branch,
        season: filterAttribute.season,
        semester: filterAttribute.semester,
      };

      await Promise.resolve(apis.getDebardList(queryParams)).then((data) => {
        setDebarredStudents(data);
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getDebardStudentsList();
  }, [filterAttribute, setFilterAttribute]);

  const columns = [
    {
      Header: "Roll No.",
      accessor: "roll",
    },
    {
      Header: "Email",
      accessor: "email",
      style: { width: "30%", maxWidth: "30%" },
    },
    {
      Header: "Course",
      accessor: "course",
    },
    {
      Header: "Semester",
      accessor: "semester",
    },
    {
      Header: "Branch",
      accessor: "branch",
    },
    {
      Header: "Promote",
      accessor: "promote",
      style: { width: "150px" },
    },
    {
      Header: "Demote",
      accessor: "demote",
      style: { width: "150px" },
    },
  ];

  const [dropDownShow, setDropDownShow] = React.useState("");
  const [dropDownShow2, setDropDownShow2] = React.useState("");
  const toggleDropDown = (value) => {
    if (dropDownShow === value) {
      setDropDownShow("");
    } else {
      setDropDownShow(value);
      setDropDownShow2("");
    }
  };
  const toggleDropDown2 = (value) => {
    if (dropDownShow2 === value) {
      setDropDownShow2("");
    } else {
      setDropDownShow2(value);
      setDropDownShow("");
    }
  };

  const data = debarredStudents.map((student) => {
    return {
      roll: student.roll,
      email: student.email,
      course: student.course,
      semester: student.semester,
      branch: student.branch,
      promote: (
        <button
          onClick={() => toggleDropDown(student.id)}
          style={{
            position: "relative",
            padding: "5px 20px",
            cursor: "pointer",
          }}
        >
          Promote
          {dropDownShow === student.id ? (
            <div className="debard-dropdown">
              {/* from current semester to 8th sem */}
              {Array.from(
                { length: 8 - Number(student.semester) + 1 },
                (_, index) => Number(student.semester) + index
              ).map((i) => (
                <button
                  onClick={() => {
                    console.log("Promote to ", i);
                  }}
                  className="debard-dropdown-button"
                >
                  to {i}
                  {i == 1 ? "st" : i == 2 ? "nd" : i == 3 ? "rd" : "th"}{" "}
                  Semester
                </button>
              ))}
            </div>
          ) : null}
        </button>
      ),
      demote: (
        <button
          onClick={() => toggleDropDown2(student.id)}
          style={{
            position: "relative",
            padding: "5px 20px",
            cursor: "pointer",
          }}
        >
          Demote
          {dropDownShow2 === student.id ? (
            <div className="debard-dropdown">
              {/* from current semester to 1st sem */}
              {Array.from(
                { length: Number(student.semester) },
                (_, index) => Number(student.semester) - index
              ).map((i) => (
                <button
                  onClick={() => {
                    console.log("Demote to ", i);
                  }}
                  className="debard-dropdown-button"
                >
                  to {i}
                  {i == 1 ? "st" : i == 2 ? "nd" : i == 3 ? "rd" : "th"}{" "}
                  Semester
                </button>
              ))}
            </div>
          ) : null}
        </button>
      ),
    };
  });

  async function downloadCsv() {
    const headers = [
      {
        label: "Roll",
        value: "roll",
      },
      {
        label: "Email",
        value: "email",
      },
      {
        label: "Course",
        value: "course",
      },
      {
        label: "Branch",
        value: "branch",
      },
      {
        label: "Semester",
        value: "semester",
      },
    ];

    const downloadData = debarredStudents.map((e) => {
      return {
        roll: e.roll,
        email: e.email,
        course: e.course,
        branch: e.branch,
        semester: e.semester,
      };
    });

    await apis
      .downloadDebardStudentList(downloadData, headers)
      .then((data) => {
        let url = window.URL.createObjectURL(data);
        window.open(url, +"_blank");
        alert("Downloaded");
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  return (
    <div className="data-upload-div">
      <div className="data-upload-contents">
        <div className="data-upload-details2">
          <h2
            style={{
              lineHeight: '48px',
              borderBottom: "2px solid #4e4e4e",
              width: "100%"
            }}
          >Debard List:</h2>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "30px",
              width: "100%",
              justifyContent: "flex-start",
            }}
          >
            <div className="filterContainer7 updateStyle">
              <div className="filterSection7">
                <div className="filterItem">
                  <p>Choose Session:</p>
                  <select
                    onChange={(e) => {
                      setFilterAttribute((e1) => {
                        return { ...e1, session: e.target.value };
                      });
                    }}
                  >
                    <option disabled>Session</option>
                    {generateSessionList().map((e, i) => {
                      return (
                        <option key={i} value={e}>
                          {e}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="filterItem">
                  <p>Choose Season:</p>
                  <select
                    onChange={(e) => {
                      setFilterAttribute((e1) => {
                        return { ...e1, season: e.target.value };
                      });
                    }}
                  >
                    <option value={""}>Season</option>
                    <option value="Spring">Spring</option>
                    <option value="Autumn">Autumn</option>
                  </select>
                </div>
                <div className="filterItem">
                  <p>Select Program:</p>
                  <select
                    onChange={(e) => {
                      setFilterAttribute((e1) => {
                        return { ...e1, program: e.target.value };
                      });
                    }}
                  >
                    <option value={""}>Program</option>
                    <option value="B.TECH.">B.Tech</option>
                    <option value="M.TECH.">M.Tech</option>
                  </select>
                </div>
                <div className="filterItem">
                  <p>Select Branch</p>
                  <select
                    onChange={(e) => {
                      setFilterAttribute((e1) => {
                        return { ...e1, branch: e.target.value };
                      });
                    }}
                  >
                    <option value={""}>Branch</option>
                    {filterAttribute.program === "B.TECH."
                      ? BTechCourses.map((e, i) => {
                          return (
                            <option key={i} value={e}>
                              {e}
                            </option>
                          );
                        })
                      : filterAttribute.program === "M.TECH."
                      ? MTechCourses.map((e, i) => {
                          return (
                            <option key={i} value={e}>
                              {e}
                            </option>
                          );
                        })
                      : null}
                  </select>
                </div>
                <div className="filterItem">
                  <p className="">Select Semester:</p>
                  <select
                    onChange={(e) => {
                      setFilterAttribute((e1) => {
                        return { ...e1, semester: e.target.value };
                      });
                    }}
                  >
                    <option value={""}>Semester</option>
                    {filterAttribute.program === "B.TECH." ? (
                      <>
                        {/* <option value="All">All</option> */}
                        {filterAttribute.season === "Spring" ? (
                          <>
                            <option value="2">2nd</option>
                            <option value="4">4th</option>
                            <option value="6">6th</option>
                            <option value="8">8th</option>
                          </>
                        ) : filterAttribute.season === "Autumn" ? (
                          <>
                            <option value="1">1st</option>
                            <option value="3">3rd</option>
                            <option value="5">5th</option>
                            <option value="7">7th</option>
                          </>
                        ) : null}
                      </>
                    ) : filterAttribute.program === "M.TECH." ? (
                      <>
                        <option value="All">All</option>
                        {filterAttribute.season === "Spring" ? (
                          <>
                            <option value="2">2nd</option>
                            <option value="4">4th</option>
                            <option value="6">6th</option>
                          </>
                        ) : filterAttribute.season === "Autumn" ? (
                          <>
                            <option value="1">1st</option>
                            <option value="3">3rd</option>
                            <option value="5">5th</option>
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </select>
                </div>
              </div>
            </div>
            <button
              className="primary debard-list-export"
              onClick={downloadCsv}
            >
              Export csv. <IosShareIcon style={{ rotate: "90deg" }} />
            </button>
          </div>
          <Table columns={columns} data={data} />
        </div>
      </div>
    </div>
  );
};

export default DebardSection;
