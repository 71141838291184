import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";

const AcademicsDetail = ({ studentData }) => {
  const [update, setUpdate] = useState(false);
  const [personalData, setPersonalData] = useState({
    course: studentData?.course,
    branch: studentData?.branch,
    batch: studentData?.batch,
    examRank: studentData?.examRank,
    examType: studentData?.examType,
    activeBacklog: studentData?.activeBacklog,
    cgpa: studentData?.cgpa,
    semester: studentData?.semester,
    year: studentData?.year,
  });

  useEffect(() => {
    setPersonalData({
      course: studentData?.course,
      branch: studentData?.branch,
      batch: studentData?.batch,
      examRank: studentData?.examRank,
      examType: studentData?.examType,
      activeBacklog: studentData?.activeBacklog,
      cgpa: studentData?.cgpa,
      semester: studentData?.semester,
      year: studentData?.year,
    });
  }, [studentData]);

  return (
    <div className="personalPageCont">
      <div className="p_top">
        <div className="p_left">
          {/* <div className="profile_edits">
            !update ? (
            <EditIcon onClick={() => setUpdate(true)} />) : (
            <div style={{ display: "flex", gap: "10px" }}>
              <CheckCircleOutlineRoundedIcon onClick={() => setUpdate(false)} />
              <HighlightOffRoundedIcon onClick={() => setUpdate(false)} />
            </div>
            )
          </div> */}
          <div className={`p_content ${update && "p_update"}`}>
            <span>Course: </span>
            {update ? (
              <input
                className="profile_input"
                type="text"
                value={personalData.course}
                onChange={(e) =>
                  setPersonalData({ ...personalData, course: e.target.value })
                }
              />
            ) : (
              personalData.course
            )}
          </div>
          <div className={`p_content ${update && "p_update"}`}>
            <span>Branch: </span>
            {update ? (
              <input
                className="profile_input"
                type="text"
                value={personalData.branch}
                onChange={(e) =>
                  setPersonalData({ ...personalData, branch: e.target.value })
                }
              />
            ) : (
              personalData.branch
            )}
          </div>
          <div className={`p_content ${update && "p_update"}`}>
            <span>Batch: </span>
            {update ? (
              <input
                className="profile_input"
                type="text"
                value={personalData.batch}
                onChange={(e) =>
                  setPersonalData({ ...personalData, batch: e.target.value })
                }
              />
            ) : (
              personalData.batch
            )}
          </div>
          <div className={`p_content ${update && "p_update"}`}>
            <span>Semester: </span>
            {update ? (
              <input
                className="profile_input"
                type="text"
                value={personalData.semester}
                onChange={(e) =>
                  setPersonalData({ ...personalData, semester: e.target.value })
                }
              />
            ) : (
              personalData.semester
            )}
          </div>
          <div className={`p_content ${update && "p_update"}`}>
            <span>Year: </span>
            {update ? (
              <input
                className="profile_input"
                type="text"
                value={personalData.year}
                onChange={(e) =>
                  setPersonalData({ ...personalData, year: e.target.value })
                }
              />
            ) : (
              personalData.year
            )}
          </div>
          <div className={`p_content ${update && "p_update"}`}>
            <span>Cgpa: </span>
            {update ? (
              <input
                className="profile_input"
                type="text"
                value={personalData.cgpa}
                onChange={(e) =>
                  setPersonalData({ ...personalData, cgpa: e.target.value })
                }
              />
            ) : (
              personalData.cgpa
            )}
          </div>
          <div className={`p_content ${update && "p_update"}`}>
            <span>Competitve Exam Type: </span>
            {update ? (
              <input
                className="profile_input"
                type="text"
                value={personalData.examType}
                onChange={(e) =>
                  setPersonalData({ ...personalData, examType: e.target.value })
                }
              />
            ) : (
              personalData.examType
            )}
          </div>
          <div className={`p_content ${update && "p_update"}`}>
            <span>Compititve Exam Rank: </span>
            {update ? (
              <input
                className="profile_input"
                type="text"
                value={personalData.examRank}
                onChange={(e) =>
                  setPersonalData({ ...personalData, examRank: e.target.value })
                }
              />
            ) : (
              personalData.examRank
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcademicsDetail;
