import React from "react";
import "../css/Page/PasswordChange.css";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import apis from "../apis";

const PasswordChange = () => {
  const { handleSubmit, register } = useForm();
  const navigate = useNavigate();

  const forgotPassword = async (data) => {
    if (data.newPassword !== data.confirmNewPassword) {
      alert("New Password and Confirm New Password doesn't match");
      return;
    }
    await apis.passwordChange(data).then((res) => {
      navigate("/");
      alert("Password Changed Successfully");
    }).catch((err) => {
      alert(err.message);
    });
  }

  const generateOTP = async (data) => {
    if (!data.email) {
      alert("Email ID is required!!");
      return;
    }
    await apis.generateOTP(data).then((data) => {
      navigate("/forget-password");
      alert("OTP is sent to your email ID");
    }).catch((err) => {
      alert(err.message)
    })
  }
  return (
    <>
      <div className="passwordChange">
        <div className="passwordChange-container">
          <div className="passwordChange-title">
            <h2>Change Password</h2>
          </div>
          <div className="passwordChange-content">
            <form onSubmit={handleSubmit(forgotPassword)}>
              <div className="form-group">
                <div className="form-group-element">
                  <h5>Email (Personal Email in case of New Admission)</h5>
                  <input type="email" required {...register("email")} />
                </div>
                <div className="form-group-element">
                  <div className="form-group-element-title">
                    <h5>Current Password</h5>
                    <Link to="#" onClick={handleSubmit(generateOTP)}>Forgot Password?</Link>
                  </div>
                  <input type="password" required {...register("currentPassword")} />
                </div>
                <div className="form-group-element">
                  <h5>New Password</h5>
                  <input type="password" required {...register("newPassword")} />
                </div>
                <div className="form-group-element">
                  <h5>Confirm New Password</h5>
                  <input type="password" required {...register("confirmNewPassword")} />
                </div>
              </div>
              <button className="passwordChange-button primary" type="submit">
                Change Password
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordChange;
