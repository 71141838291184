import * as React from "react";
import { useNavigate } from "react-router-dom";
import jwtVerify from "./service";
import api from "../newRegistrationApis";
import Apis from "../apis";

const AuthenticateContext = React.createContext();

const AuthContext = ({ children }) => {
  const [fetchAgain, setFetchAgain] = React.useState(false);
  const [userData, setUserData] = React.useState({});
  const [newUserData, setNewUserData] = React.useState({});
  const [adminData, setAdminData] = React.useState({});
  const navigate = useNavigate();

  React.useEffect(() => {
    const fetchUsers = async () => {
      if (jwtVerify.getAccessToken("student")) {
        await jwtVerify
          .getUserData()
          .then((res) => {
            setUserData(res);
          })
          .catch((err) => {
            alert(err.message);
          });
      }
    }
    const fetchNewUsers = async () => {
      if (jwtVerify.getAccessToken("newUser")) {
        await api
          .getProfile()
          .then((res) => {
            setNewUserData(res);
          })
          .catch((err) => {
            alert(err.message);
          });
      }

    }
    const fetchAdmin = async () => {
      if (jwtVerify.getAccessToken("admin")) {
        await jwtVerify
          .getAdminData()
          .then((res) => {
            let adminData = res;
            Promise.resolve(Apis.getFacultyById(res.id)).then((faculty) => {
              adminData = {
                ...adminData,
                program: faculty.program,
                branch: faculty.branch,
              }
              setAdminData(adminData);
            }
            ).catch((err) => {
              // alert(err.message);
              setAdminData(res);
            });
          })
          .catch((err) => {
            alert(err.message);
          });
      }
    }
    jwtVerify.interceptorsSetup();
    fetchUsers();
    fetchNewUsers();
    fetchAdmin();

  }, [fetchAgain, navigate]);

  return (
    <AuthenticateContext.Provider
      value={{
        userData,
        setUserData,
        setFetchAgain,
        setNewUserData,
        newUserData,
        adminData,
        setAdminData,
      }}
    >
      {children}
    </AuthenticateContext.Provider>
  );
};

export const AuthProvider = () => {
  return React.useContext(AuthenticateContext);
};

export default AuthContext;
