import * as React from "react";
import "../css/Component/SearchStudent.css";
import { useSearch } from "../contexts/SearchProvider";
import apis from "../apis";

const SearchStudent = () => {
  const {
    search,
    setSearch,
    specific,
    setSpecific,
    fetchSearch,
    fetchFilters,
    fetchSpecific,
  } = useSearch();

  const resetFilter = () => {
    window.location.reload();
  };

  const [batchList, setBatchList] = React.useState();
  const [sessionList, setSessionList] = React.useState();

  React.useEffect(() => {
    const fetchBatchList = async () => {
      await apis.getBatchFilterList().then((data) => {
        setBatchList(data);
      }).catch((err) => {
        console.log(err.message);
      })
    }

    const fetchSessionList = async () => {
      await apis.getSessionFilterList().then((data) => {
        setSessionList(data);
      }).catch((err) => {
        console.log(err.message);
      })
    }

    fetchSessionList();
    fetchBatchList();
  }, [])

  React.useEffect(() => {
    fetchSearch();
  }, []);

  return (
    <>
      <div className="search-student">
        <div className="title cv">
          <div className="title-left-div">
            <h2>Search Students list: </h2>
          </div>
          <div className="search-item btn-div">
            <button
              id="search1"
              className="reset-btn"
              onClick={() => resetFilter()}
              style={{ width: "180px" }}
            >
              <span>Reset </span><i className="fa-solid fa-refresh"></i>
            </button>
          </div>
        </div>
        <div className="search-div">
          <div className="sub-title">
            <h3>Search Student By:</h3>
          </div>
          <div className="search-items">
            <div className="search-item">
              <h4 htmlFor="course">Select Session:</h4>

              <select
                name="courses"
                id="courses"
                onChange={(e) =>
                  setSearch((e1) => {
                    return { ...e1, session: e.target.value };
                  })
                }
                value={search.session}
              >
                {/* <option value="null">All</option> */}
                <option>All</option>
                {sessionList?.map((session) => (
                  <option key={session} value={session}>
                    {session}
                  </option>
                ))}
              </select>
            </div>
            <div className="search-item">
              <h4 htmlFor="course">Select Course:</h4>

              <select
                name="courses"
                id="courses"
                onChange={(e) =>
                  setSearch((e1) => {
                    return { ...e1, course: e.target.value };
                  })
                }
                value={search.course}
              >
                {/* <option value="null">All</option> */}
                <option>All</option>
                <option value="B.TECH.">B.Tech</option>
                <option value="M.TECH.">M.Tech</option>
                <option value="Ph.D.">Ph.D</option>
              </select>
            </div>
            <div className="search-item">
              <h4 htmlFor="batch">Select Batch:</h4>
              <select
                name="batches"
                id="batches"
                onChange={(e) =>
                  setSearch((e1) => {
                    return { ...e1, batch: e.target.value };
                  })
                }
                value={search.batch}
              >
                <option disabled value="">
                  Select Batch
                </option>
                {search.course ? batchList[search.course]?.map((batch) => (
                  <option key={batch} value={batch}>
                    {batch}
                  </option>
                )) :
                  <option value="null" disabled>Select Course First</option>
                }
              </select>
            </div>
            <div className="search-item">
              <h4 htmlFor="semester">Select Semester:</h4>

              <select
                name="semesters"
                id="semesters"
                onChange={(e) =>
                  setSearch((e1) => {
                    return { ...e1, semester: e.target.value };
                  })
                }
                value={search.semester}
              >
                <option>All</option>
                <option value="1">1st Sem</option>
                <option value="2">2nd Sem</option>
                <option value="3">3rd Sem</option>
                <option value="4">4th Sem</option>
                <option value="5">5th Sem</option>
                <option value="6">6th Sem</option>
                <option value="7">7th Sem</option>
                <option value="8">8th Sem</option>
              </select>
            </div>
            <div className="search-item">
              <h4 htmlFor="branch">Branch/Domain:</h4>

              <select
                name="branches"
                id="branches"
                onChange={(e) =>
                  setSearch((e1) => {
                    return { ...e1, branch: e.target.value };
                  })
                }
                value={search.branch}
              >
                <option value="">All</option>
                <option disabled>B.TECH.</option>
                <option value="CSE">CSE</option>
                <option value="ECE">ECE</option>
                <option value="MAE">MEA</option>
                <option value="MNC">MNC</option>
                <option disabled>M.TECH.</option>
                <option value="AI & DS">AI & DS</option>
                <option value="SPML">SPML</option>
                <option value="VLSI & ES">VLSI & ES</option>
                <option value="EV">EV</option>
                <option value="Micro & Comm.">Micro & Comm.</option>
              </select>
            </div>
            <div className="search-item btn-div">
              <button
                id="search"
                className="new_btn primary"
                onClick={() => fetchSearch()}
              >
                <span>Search</span>
                <img src="/assets/search-icon.svg" alt="search-icon" />
              </button>
            </div>
          </div>
        </div>
        <div className="filter-div">
          <div className="sub-title">
            <h3>Filter Student By:</h3>
          </div>
          <div className="filter-items">
            <div className="filter-item">
              <h4 htmlFor="payment">Payment Status:</h4>

              <select
                name="payments"
                id="payments"
                onChange={(e) =>
                  setSearch((e1) => {
                    return { ...e1, paymentStatus: e.target.value };
                  })
                }
                value={search.paymentStatus}
                defaultValue={""}
              >
                <option value="">Select</option>
                <option value="Pending">Incomplete</option>
                <option value="Not Paid">Not Paid</option>
                <option value="Paid">Paid</option>
              </select>
            </div>
            <div className="filter-item">
              <h4 htmlFor="registration">Reg. Status:</h4>

              <select
                name="registrations"
                id="registrations"
                onChange={(e) =>
                  setSearch((e1) => {
                    return { ...e1, registrationStatus: e.target.value };
                  })
                }
                value={search.registrationStatus}
                defaultValue=""
              >
                <option value="">Select</option>
                <option value="Not Registered">Not Registered</option>
                <option value="Pending">Pending</option>
                <option value="Approved">Eligible for Physical registration</option>
                <option value="Registered">Registered</option>
              </select>
            </div>
            {/* <div className="filter-item">
              <h4 htmlFor="type">Student Type</h4>

              <select
                name="types"
                id="types"
                onChange={(e) =>
                  setSearch((e1) => {
                    return { ...e1, verificationStatus: e.target.value };
                  })
                }
                value={search.verificationStatus}
              >
                <option value="regular">Regular</option>
                <option value="part-time">Part Time</option>
                <option value="mea">Pass Out</option>
                <option value="mac">Drop Out</option>
              </select>
            </div> */}
            <div className="filter-item btn-div">
              <button
                id="filter"
                className="new_btn primary"
                onClick={() => fetchFilters()}
              >
                <span>Apply filter</span>
              </button>
            </div>
          </div>
        </div>
        <div className="search-div">
          <div className="sub-title">
            <h3>
              Specific Student Search: <span>fill any one</span>
            </h3>
          </div>
          <div className="search-items">
            <div className="search-item">
              <h4 htmlFor="roll">Roll Number:</h4>
              <input
                type="text"
                name="student-id"
                id="student-id"
                onChange={(e) =>
                  setSpecific((e1) => {
                    return { ...e1, roll: e.target.value };
                  })
                }
                value={specific.roll}
              />
            </div>
            {/* <div className="search-item">
              <h4 htmlFor="email">Email ID:</h4>
              <input
                type="text"
                name="student-id"
                id="student-id"
                onChange={(e) =>
                  setSpecific((e1) => {
                    return { ...e1, email: e.target.value };
                  })
                }
              />
            </div> */}
            <div className="search-item">
              <h4 htmlFor="name">Full Name:</h4>
              <input
                type="text"
                name="student-id"
                id="student-id"
                onChange={(e) =>
                  setSpecific((e1) => {
                    return { ...e1, name: e.target.value };
                  })
                }
              />
            </div>
            <div className="search-item btn-div">
              <button
                id="search"
                className="new_btn primary"
                onClick={() => fetchSpecific()}
              >
                <span>Search</span>
                <img src="/assets/search-icon.svg" alt="search-icon" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchStudent;
