import * as React from "react";
import AdminHeader from "../components/AdminHeader";
import "../css/Page/VerificationRequests.css";
import VerificationRequest from "../components/VerificationRequest";
import apis from "../apis";
import { AuthProvider } from "../auth/AuthContext";
import { useNavigate } from "react-router-dom";

const countRequests = (requests, adminData) => {
  let count = 0;
  requests.forEach((request) => {
    if (request.verifiedBy === "NA") {
      if (adminData?.role?.find((e) => e === "Academic Admin" || e === "DOAA")) {
        count++;
      }
    } else if (request.verifiedBy === "academic") {
      if (adminData?.role?.find((e) => e === "Finance Section")) {
        count++;
      }
    }
  });
  return count;
};

const VerificationRequests = () => {
  const [requests, setRequests] = React.useState([]);
  // const [fetchAgain, setFetchAgain] = React.useState(0);
  const { adminData } = AuthProvider()
  const navigate = useNavigate();
  React.useEffect(() => {
    if (adminData.id && !adminData?.role?.find((e) => e === "DOAA") && !adminData?.role?.find((e) => e === "Super Admin") && !adminData?.role?.find((e) => e === "Finance Section") && !adminData?.role?.find((e) => e === "Academic Admin")) {
      alert("You are not authorized to access this page.")
      navigate("/student/admin");
    }
    const fetchRequests = async () => {
      await apis
        .allReceipts(`${adminData?.role?.find((e) => e === "Finance Section") ? "academic" : "NA"}`)
        .then((data) => {
          console.log(data);
          setRequests(data);
        })
        .catch((err) => {
          alert(err.message);
        });
    };
    fetchRequests();
  }, [adminData, navigate]);
  return (
    <>
      <AdminHeader />
      <div className="top-div">
        <div className="verification-requests">
          <div className="title">
            <h2>
              Verification Requests: <span>{countRequests(requests, adminData)}</span>
            </h2>
          </div>

          {requests.map((request) => {
            return (
              <VerificationRequest request={request} key={request.id} />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default VerificationRequests;
