import React, { useEffect, useState } from "react";
import { AuthProvider } from "../auth/AuthContext";
import { useNavigate } from "react-router-dom";
import AdminHeader from "../components/AdminHeader";
import Apis from "../apis/apis";
import "../css/Page/FacultyCourseAssign.css";
import FacultyCourseAssignTable from "../components/FacultyCourseAssignTable";
import { FiChevronDown } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import UserPage from "../components/UserPage";

const MTechCourses = ["AI & DS", "VLSI & ES", "EV", "Micro & Comm.", "SPML"];

const BTechCourses = ["CSE", "ECE", "MAE", "MNC"];

const FacultyCourseAssign = () => {
  const { adminData } = AuthProvider();
  const navigate = useNavigate();
  // const [sessionVal, setSessionVal] = React.useState("");
  // const [seasonVal, setSeasonVal] = React.useState("");
  const [filterAttribute, setFilterAttribute] = React.useState({
    session: "",
    program: "",
    branch: "",
    season: "",
    semester: "",
  });

  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [Faculties, setFaculties] = useState([]);
  const [popUp, setPopUp] = useState(false);

  const getAllFacultiesDetails = async () => {
    setLoading(true);
    try {
      Promise.resolve(Apis.getFaculties()).then((data) => {
        setFaculties(data);
      });
    } catch (error) {
      console.error(`Error fetching faculty data: ${error.message}`);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllFacultiesDetails();
  }, []);

  const generateSessionList = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const sessionList = [];
    for (let i = currentYear; i >= 2017; i--) {
      if (currentMonth < 6 && i === currentYear) continue;
      let nextYear = (i + 1).toString().slice(-2);
      sessionList.push(`${i}-${nextYear}`);
    }
    return sessionList;
  };

  useEffect(() => {
    generateSessionList();
    // setSessionVal(generateSessionList()[0]);
    setFilterAttribute({
      ...filterAttribute,
      session: generateSessionList()[0],
    });
  }, []);

  const getCourseData = async () => {
    setLoading(true);
    try {
      Promise.resolve(Apis.getAllCoursesDetails()).then((data) => {
        setCourses(data);
      });
    } catch (error) {
      console.error(`Error fetching course data: ${error.message}`);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCourseData();
  }, []);

  const [filteredCourses, setFilteredCourses] = useState([]);

  useEffect(() => {
    if (courses && courses.length > 0) {
      let filtered = courses;

      if (filterAttribute.program !== "") {
        filtered = filtered.filter((e) => e.course === filterAttribute.program);
      }

      if (filterAttribute.branch !== "") {
        filtered = filtered.filter((e) => e.branch === filterAttribute.branch);
      }

      if (filterAttribute.season !== "") {
        if (filterAttribute.season === "Spring") {
          filtered = filtered.filter((e) => e.semester % 2 === 0);
        } else if (filterAttribute.season === "Autumn") {
          filtered = filtered.filter((e) => e.semester % 2 !== 0);
        }
      }

      if (filterAttribute.semester !== "") {
        filtered = filtered.filter(
          (e) => e.semester === Number(filterAttribute.semester)
        );
      }

      const uniqueFiltered = Array.from(new Set(filtered.map((a) => a.id))).map(
        (id) => {
          return filtered.find((a) => a.id === id);
        }
      );

      setFilteredCourses(uniqueFiltered);
    }
  }, [
    filterAttribute,
    filterAttribute.program,
    filterAttribute.branch,
    filterAttribute.season,
    filterAttribute.semester,
    courses,
  ]);

  const [isOpen, setIsOpen] = useState(false);
  const [rotateDegree, setRotateDegree] = useState(0);
  const [teacher, setTeacher] = useState();
  const [selected, setSelected] = useState([]);

  const [uniqueFaculty, setUniqueFaculty] = useState([]);

  const getUniqueFaculty = async () => {
    try {
      Promise.resolve(Apis.getUniqueFaculties()).then((res) => {
        res.map((e) => {
          setUniqueFaculty((prev) => [...prev, e.facultyId]);
        });
      });
    } catch (err) {
      console.error(err);
    }
  };

  const assignCoursesFunc = async () => {
    const promises = selected.map(async (item) => {
      try {
        const sendData = {
          facultyId: teacher.id,
          courseId: item.courseData.id,
          session: item.session,
          semester: item.courseData.semester,
        };
        return Apis.assignFacultyCourse(sendData);
      } catch (error) {
        console.error(`Error assigning course to faculty: ${error.message}`);
      }
    });

    Promise.all(promises)
      .then(() => {
        alert("Course assigned successfully");
        setTeacher(null);
        setSelected([]);
        setPopUp(false);
        getUniqueFaculty();
      })
      .catch((error) => {
        console.error(`Error assigning course to faculty: ${error.message}`);
      });
  };

  const handleSelect = (event, courseData) => {
    if (event.target.checked) {
      setSelected((prevSelected) => [
        ...prevSelected,
        {
          courseData,
          session: filterAttribute.session,
          semester: filterAttribute.semester,
        },
      ]);
    } else {
      setSelected((prevSelected) =>
        prevSelected.filter((item) => item.courseData !== courseData)
      );
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setRotateDegree(isOpen ? 0 : 180);
  };

  return (
    <>
      {popUp && (
        <div className="popUp-overlay">
          <div className="popUp-container">
            <IoClose className="close" onClick={() => setPopUp(false)} />
            <div className="popUp-Upper">
              <div className="title cv">
                <div className="">
                  <h2>{teacher?.name}</h2>
                </div>
              </div>
              <div className="filterSection">
                <div className="filterItem">
                  <p>Choose Session:</p>
                  <select
                    onChange={(e) => {
                      setFilterAttribute((e1) => {
                        return { ...e1, session: e.target.value };
                      });
                    }}
                  >
                    <option disabled>Session</option>
                    {generateSessionList().map((e, i) => {
                      return (
                        <option key={i} value={e}>
                          {e}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="filterItem">
                  <p>Select Program:</p>
                  <select
                    onChange={(e) => {
                      setFilterAttribute((e1) => {
                        return { ...e1, program: e.target.value };
                      });
                    }}
                  >
                    <option value={""}>Program</option>
                    <option value="B.TECH.">B.Tech</option>
                    <option value="M.TECH.">M.Tech</option>
                  </select>
                </div>
                <div className="filterItem">
                  <p>Select Branch</p>
                  <select
                    onChange={(e) => {
                      setFilterAttribute((e1) => {
                        return { ...e1, branch: e.target.value };
                      });
                    }}
                  >
                    <option value={""}>Branch</option>
                    {filterAttribute.program === "B.TECH."
                      ? BTechCourses.map((e, i) => {
                          return (
                            <option key={i} value={e}>
                              {e}
                            </option>
                          );
                        })
                      : filterAttribute.program === "M.TECH."
                      ? MTechCourses.map((e, i) => {
                          return (
                            <option key={i} value={e}>
                              {e}
                            </option>
                          );
                        })
                      : null}
                  </select>
                </div>
                <div className="filterItem">
                  <p>Choose Season:</p>
                  <select
                    onChange={(e) => {
                      setFilterAttribute((e1) => {
                        return { ...e1, season: e.target.value };
                      });
                    }}
                  >
                    <option value={""}>Season</option>
                    <option value="Spring">Spring</option>
                    <option value="Autumn">Autumn</option>
                  </select>
                </div>
                <div className="filterItem">
                  <p className="">Select Semester:</p>
                  <select
                    onChange={(e) => {
                      setFilterAttribute((e1) => {
                        return { ...e1, semester: e.target.value };
                      });
                    }}
                  >
                    <option value={""}>Semester</option>
                    {filterAttribute.program === "B.TECH." ? (
                      <>
                        {/* <option value="All">All</option> */}
                        {filterAttribute.season === "Spring" ? (
                          <>
                            <option value="2">2nd</option>
                            <option value="4">4th</option>
                            <option value="6">6th</option>
                            <option value="8">8th</option>
                          </>
                        ) : filterAttribute.season === "Autumn" ? (
                          <>
                            <option value="1">1st</option>
                            <option value="3">3rd</option>
                            <option value="5">5th</option>
                            <option value="7">7th</option>
                          </>
                        ) : null}
                      </>
                    ) : filterAttribute.program === "M.TECH." ? (
                      <>
                        <option value="All">All</option>
                        {filterAttribute.season === "Spring" ? (
                          <>
                            <option value="2">2nd</option>
                            <option value="4">4th</option>
                            <option value="6">6th</option>
                          </>
                        ) : filterAttribute.season === "Autumn" ? (
                          <>
                            <option value="1">1st</option>
                            <option value="3">3rd</option>
                            <option value="5">5th</option>
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </select>
                </div>
              </div>
              <div className="courseChoose">
                <p>Choose courses to assign:</p>
                <div onClick={toggleDropdown} className="dropdown">
                  <div className="dropdown-header">
                    {`Course Name (course code)`}
                  </div>
                  <button className="dropdown-button" onClick={toggleDropdown}>
                    <FiChevronDown
                      style={{
                        transform: `rotate(${rotateDegree}deg)`,
                        transition: "transform 0.3s ease-in-out",
                      }}
                    />
                  </button>
                </div>
                {isOpen && (
                  <ul className="dropdown-item">
                    {filteredCourses.map((item) => (
                      <li
                        key={item.name}
                        value={item.name}
                        className="dropdown-item-list"
                      >
                        <div className="checkbox-container">
                          <input
                            type="checkbox"
                            name={item.name}
                            id={item.id}
                            onChange={(event) => handleSelect(event, item)}
                            checked={selected.some(
                              (s) => s.courseData === item
                            )}
                            className="checkbox-input"
                          />
                        </div>
                        <div className="courseName">
                          {item.name} {`(${item.code})`}
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="assignedCoursesCont">
                <p>Assigned Courses:</p>
                <table className="myCourse_table">
                  <thead className="myCourse_head">
                    <tr>
                      <th>Course Name</th>
                      <th>Code</th>
                      <th>Course</th>
                      <th>Branch</th>
                      <th>Semester</th>
                      <th>Session</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="myCourse_body">
                    {selected.map((item) => (
                      <tr key={item?.courseData.id}>
                        <td>{item?.courseData.name}</td>
                        <td>{item?.courseData.code}</td>
                        <td>{item?.courseData.course}</td>
                        <td>{item?.courseData.branch}</td>
                        <td>{item?.courseData.semester}</td>
                        <td>{item?.session}</td>
                        <td style={{ cursor: "pointer" }}>
                          <IoClose
                            onClick={() => {
                              setSelected((prevSelected) =>
                                prevSelected.filter((e) => e !== item)
                              );
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="popUp-lower">
              <button
                className={`Assign primary ${
                  selected.length === 0 ? "disabled" : ""
                }`}
                onClick={assignCoursesFunc}
                disabled={selected.length === 0}
              >
                Assign
              </button>
            </div>
          </div>
        </div>
      )}
      <AdminHeader />
      <div className="top-div">
        <div className="hodContainer">
          <div className="title cv">
            <div className="">
              <h2>Assign Courses:</h2>
            </div>
          </div>
          <div className="status-div2">
            <FacultyCourseAssignTable
              faculties={Faculties}
              loading={loading}
              setPopUp={setPopUp}
              setTeacher={setTeacher}
              getUniqueFaculty={getUniqueFaculty}
              uniqueFaculty={uniqueFaculty}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FacultyCourseAssign;
