import * as React from "react";
import apis from "../apis";
import AdminHeader from "../components/AdminHeader";
import "../css/Page/BulkGeneration.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { StudentReport } from "./StudentProfileAV";
import axios from "axios";

const BulkShow = ({ data }) => {
  
  const [session, setSession] = React.useState("");
  const [student, setStudent] = React.useState({});
  const [studentFees, setStudentFees] = React.useState({});
  const printRef = React.useRef();
  const printRef1 = React.useRef();
  const print = async (roll) => {
    const canvas = await html2canvas(printRef.current);
    const canvas1 = await html2canvas(printRef1.current);
    const imgData = canvas.toDataURL("image/png");
    const imgData1 = canvas1.toDataURL("image/png");
    const pdf = new jsPDF("landscape");
    pdf.addImage(imgData1, "JPEG", 2, 2, 148, 205, "adminCopy", "SLOW");
    pdf.addImage(imgData, "JPEG", 152, 2, 142, 205, "studentCopy", "SLOW");
    pdf.save(`${roll}_receipt.pdf`);
  };

  React.useEffect(() => {
    const fetchStudent = async () => {
      await apis
        .profile(data.roll)
        .then((data) => {
          setSession(
            `${data.batch} (${data.semester & 1 ? "Autumn" : "Spring"}`
          );
          setStudent(data);
        })
        .catch((err) => {
          alert(err.message);
        });
      await apis
        .specificStudent({ roll: data.roll })
        .then((data) => {
          setStudentFees(data[0]);
        })
        .catch((err) => {
          alert(err.message);
        });
      await print(data.roll);
    };
    fetchStudent();
  }, [data]);

  return (
    <>
      <div style={{ display: "flex" }}>
        <StudentReport
          ref={printRef}
          admin={true}
          session={session}
          student={student}
          studentFees={studentFees}
        />
        <StudentReport
          ref={printRef1}
          admin={false}
          session={session}
          student={student}
          studentFees={studentFees}
        />
      </div>
    </>
  );
};

const BulkGeneration = () => {
  // const [fromDate, setFromDate] = useState("");
  // const [toDate, setToDate] = useState("");
  // const fetchReceipts = async () => {
  //   await apis
  //     .bulkGeneration({ fromDate: fromDate, toDate: toDate })
  //     .then((data) => {
  //       
  //     })
  //     .catch((err) => {
  //       alert(err.message);
  //     });
  // };
  // const [session, setSession] = React.useState("");
  // const [student, setStudent] = React.useState({});
  // const [studentFees, setStudentFees] = React.useState({});
  const [userData, setUserData] = React.useState([]);

  const bulkGeneration = async () => {
    try {
      // axios.defaults.baseURL = `http://localhost:2000`;
      let userData = await axios({
        url: `/api/student/search`,
        method: "POST",
        data: {},
        headers: {
          "Content-Type": "application/json",
        },
      });
      setUserData(userData.data);
      // userData.data.map(async (data) => {
      // await apis
      //   .profile(data.roll)
      //   .then((data) => {
      //     setSession(
      //       `${data.batch} (${data.semester & 1 ? "Autumn" : "Spring"}`
      //     );
      //     setStudent(data);
      //   })
      //   .catch((err) => {
      //     alert(err.message);
      //   });
      // await apis
      //   .specificStudent({ roll: data.roll })
      //   .then((data) => {
      //     setStudentFees(data[0]);
      //   })
      //   .catch((err) => {
      //     alert(err.message);
      //   });
      // await print(data.roll);
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <>
      <AdminHeader />
      <div className="top-div">
        <div className="bulk-generation-div">
          <div className="filter-div">
            <div className="filter-div-contents">
              <h2>
                Select date range:{" "}
                <span>to generate registration reciepts</span>
              </h2>
              <div className="filter-div-contents-elements bulk-wali">
                <div className="filter-div-contents-element">
                  <h3>Registration Date (From)</h3>
                  <input
                    type="date"
                    id="search"
                    placeholder="Enter Date"
                    required
                  />
                </div>
                <div className="filter-div-contents-element">
                  <h3>Registration Date (To)</h3>
                  <input
                    type="date"
                    id="search"
                    placeholder="Enter Date"
                    required
                  />
                </div>
                <button
                  className="primary bulk-gen-btn"
                  onClick={() => bulkGeneration()}
                >
                  Generate Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="bottom-div"
        style={{ display: "flex", flexDirection: "column" }}
      >
        {/* <div className="loading-box">
          <div className="loader"></div>
        </div> */}
        {userData.map((data, index) => (
          <BulkShow key={index} data={data} />
        ))}
      </div>
    </>
  );
};

export default BulkGeneration;
