import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";
import "../css/Component/GraphComponents.css";

const DetailedBarChart = ({ R_A_Percent, R_B_Percent, R_C_Percent }) => {
  const [datas, setDatas] = useState([]);

  const createDataArray = () => {
    let data = [];
    let cnt = 0;
    for (let i = 0; i < R_A_Percent.length; i++) {
      data.push({
        name: `Q${cnt + 1}`,
        Excellent: R_A_Percent[i][4],
        ["Very Good"]: R_A_Percent[i][3],
        Good: R_A_Percent[i][2],
        Fair: R_A_Percent[i][1],
        Poor: R_A_Percent[i][0],
      });
      cnt++;
    }

    for (let i = 0; i < R_B_Percent.length; i++) {
      data.push({
        name: `Q${cnt + 1}`,
        Excellent: R_B_Percent[i][4],
        ["Very Good"]: R_B_Percent[i][3],
        Good: R_B_Percent[i][2],
        Fair: R_B_Percent[i][1],
        Poor: R_B_Percent[i][0],
      });
      cnt++;
    }

    for (let i = 0; i < R_C_Percent.length; i++) {
      data.push({
        name: `Q${cnt + 1}`,
        Excellent: R_C_Percent[i][4],
        ["Very Good"]: R_C_Percent[i][3],
        Good: R_C_Percent[i][2],
        Fair: R_C_Percent[i][1],
        Poor: R_C_Percent[i][0],
      });
      cnt++;
    }

    setDatas(data);
  };

  useEffect(() => {
    if (R_A_Percent && R_B_Percent && R_C_Percent) {
      createDataArray();
    }
  }, [R_A_Percent, R_B_Percent, R_C_Percent, setDatas]);

  return (
    <div style={{ width: "100%", height: "250px" }}>
      <div className="bartitle">Question Wise Rating Distribution</div>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={datas}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 25,
          }}
        >
          {/* <Legend
            layout="vertical"
            verticalAlign="top"
            align="right"
            wrapperStyle={{
              position: "absolute",
              paddingLeft: "10px",
              border: "1px solid red",
              borderRadius: "5px",
              backgroundColor: "#f5f5f5",
            }}
          /> */}
          <Legend
            layout="vertical"
            height={1}
            // verticalAlign="top"
            // align="right"
            wrapperStyle={{
              top: 30,
              right: 30,
            }}
          />
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name">
            <Label
              value="Questions (Excellent, Very Good, Good, Fair, Poor)"
              offset={-15}
              position="insideBottom"
            />
          </XAxis>
          <YAxis>
            <Label
              angle={-90}
              value="Rating Count"
              position="insideLeft"
              style={{ textAnchor: "middle" }}
            />
          </YAxis>
          <Tooltip />

          <Bar
            dataKey="Excellent"
            fill="#007FFF"
            activeBar={<Rectangle fill="pink" />}
          />
          <Bar
            dataKey="Very Good"
            fill="#ffa600"
            activeBar={<Rectangle fill="gold" />}
          />
          <Bar
            dataKey="Good"
            fill="#50C878"
            activeBar={<Rectangle fill="yellow" />}
          />
          <Bar
            dataKey="Fair"
            fill="#9370DB"
            activeBar={<Rectangle fill="red" />}
          />
          <Bar
            dataKey="Poor"
            fill="#FF4500"
            activeBar={<Rectangle fill="blue" />}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DetailedBarChart;
