import * as React from "react";
import "../css/Component/PaymentHistory.css";
import apis from "../apis";
import { AuthProvider } from "../auth/AuthContext";

export const PaymentDetail = ({ payment }) => {
  const statusShow = (status) => {
    if (status === "Unverified") {
      return "Pending";
    } else {
      return status;
    }
  };
  const downloadReceipt = async (id) => {
    await apis.fetchReceipt(id)
      .then((data) => {
        let byteArray = new Uint8Array(data.data.receipt.data);
        let blob = new Blob([byteArray], { type: "application/pdf" });
        let url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
      }).catch((err) => {
        alert(err.message);
      });
  };
  return (
    <div className="result-content">
      <div className="result-content-item">
        <h4>{payment.date}</h4>
      </div>
      <div className="result-content-item">
        <h4>₹ {payment.amount}</h4>
      </div>
      <div className="result-content-item">
        <h4>{payment.type}</h4>
      </div>
      <div className="result-content-item TID">
        <h4>{payment.refno}</h4>
      </div>
      <div className="result-content-item">
        <h4>{statusShow(payment.status)}</h4>
      </div>
      {payment.type === "Offline" ? (
        <div
          className="result-content-item"
          style={{ cursor: "pointer" }}
          onClick={() => downloadReceipt(payment.id)}
        >
          <h4>Download</h4>
        </div>
      ) : (
        <div className="result-content-item">
          <h4>N/A</h4>
        </div>
      )}
    </div>
  );
};

const PaymentHistoryNew = () => {
  const [paymentHistory, setPaymentHistory] = React.useState([]);
  const [search, setSearch] = React.useState({});
  const { newUserData } = AuthProvider();

  React.useEffect(() => {
    const fetchPaymentHistory = async () => {
      await apis
        .paymentHistory({ applicationid: newUserData.applicationid })
        .then((data) => {
          setPaymentHistory(data);
        })
        .catch((err) => {
          alert(err);
        });
    };
    fetchPaymentHistory();
  }, [newUserData.applicationid]);

  // const fetchOnSearch = async () => {
  //   await apis
  //     .paymentHistory(search)
  //     .then((data) => {
  //       
  //       setPaymentHistory(data);
  //     })
  //     .catch((err) => {
  //       alert(err);
  //     });
  // };

  return (
    <div className="payHistory-section">
      <div className="payHistory-div">
        <div className="title-div">
          <h3>Payment History:</h3>
        </div>
        <div className="content-div">
          {/* <div className="filter-items"> */}
          {/* <div className="filter-item">
              <h4 htmlFor="year">Select Year:</h4>

              <select name="year" id="year">
                <option value="1">1st Year</option>
                <option value="2">2nd Year</option>
                <option value="3">3rd Year</option>
                <option value="4">4th Year</option>
              </select>
            </div> */}
          {/* <div className="filter-item">
              <h4 htmlFor="semester">Choose semester:</h4>

              <select
                id="semesters"
                value={search.semester ? search.semester : "0"}
                onChange={(e) =>
                  setSearch((e1) => {
                    return { ...e1, semester: e.target.value };
                  })
                }
              >
                <option value="0" disabled>
                  Choose Semester
                </option>
                <option value="1">I</option>
                <option value="2">II</option>
                <option value="3">III</option>
                <option value="4">IV</option>
                <option value="5">V</option>
                <option value="6">VI</option>
                <option value="7">VII</option>
                <option value="8">VIII</option>
              </select>
            </div>
            <div className="filter-item btn-div">
              <button
                id="search"
                className="new_btn"
                onClick={() => fetchOnSearch()}
              >
                <span>Search</span>
                <img src="/assets/search-icon.svg" alt="search-icon" />
              </button>
            </div> */}
          {/* </div> */}
          <div className="result-items history">
            <div className="result-heading">
              <div className="result-heading-item">
                <h4>Date</h4>
              </div>
              <div className="result-heading-item">
                <h4>Amount</h4>
              </div>
              <div className="result-heading-item">
                <h4>Mode</h4>
              </div>
              <div className="result-heading-item TID">
                <h4>Transaction ID</h4>
              </div>
              <div className="result-heading-item">
                <h4>Status / Verified by</h4>
              </div>
              <div className="result-heading-item">
                <h4>Reciept</h4>
              </div>
            </div>
            <div className="result-contents">
              {paymentHistory.map((payment) => {
                return <PaymentDetail payment={payment} key={payment.id} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentHistoryNew;
