import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import apis from "../apis";
import AdminHeader from "../components/AdminHeader";
import "../css/Page/StudentProfileAV.css";
import CourseTable from "../components/CourseTable";
import FeeTable from "../components/FeeTable";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import bannerIcon from "../assets/heading-banner.jpg";
import PaymentHistory from "../components/PaymentHistory";
import ProfileUpdatePopUp from "../components/ProfileUpdatePopUp";

const arrayBufferToBase64 = (buffer) => {
  var binary = "";
  var bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => (binary += String.fromCharCode(b)));
  return window.btoa(binary);
};

export const StudentReport = React.forwardRef(
  ({ session, student, studentFees, admin, setTotalPayable }, ref) => {
    return (
      <div className="report" ref={ref} style={{ border: "2px solid #000" }}>
        <div className="report-header">
          <img src={bannerIcon} alt="banner-icon" />
        </div>
        <div className="report-title">
          <h3>
            Course Registration ({admin ? "Student" : "Admin"} Copy): {session})
          </h3>
        </div>
        <div className="report-profile-details">
          <div className="ProfileSummary">
            <div className="profile-summary-item left-text">
              <h4>
                Name: <span>{student?.name}</span>
              </h4>
              <h4>
                Program: <span>{student?.course}</span>
              </h4>
              <h4>
                Branch: <span>{student?.branch}</span>
              </h4>
              <h4>
                Type: <span>{studentFees?.type}</span>
              </h4>
            </div>
            <div className="profile-summary-item right-text">
              <h4>
                Roll: <span>{student?.roll}</span>
              </h4>
              <h4>
                Batch: <span>{student?.batch}</span>
              </h4>
              <h4>
                Semester: <span>{studentFees?.semester}</span>
              </h4>
              <h4>
                Active Backlog: <span>{student.activeBacklog}</span>
              </h4>
            </div>
          </div>
          <div className="profile-picture">
            <img
              src={`data:image/jpg;base64,${arrayBufferToBase64(
                student?.picture?.data
              ).toString("base64")}`}
              alt="profile-pic"
            />
          </div>
        </div>
        <div className="course-table-section">
          {studentFees && (
            <CourseTable user={student} semester={studentFees?.semester} admin={true} />
          )}
        </div>
        <div className="fee-table-section">
          {student.id && studentFees?.id && <FeeTable student={student} studentFees={studentFees} setTotalPayable={setTotalPayable} />}
        </div>
        <div className="verification-indicator">
          <div className="indicator-div">
            <div className="indicator-item left-text">
              <h4>
                {/* {console.log(studentFees?.paymentStatus)} */}
                Payment Status: <span>{studentFees?.paymentStatus}</span>
              </h4>
            </div>
            <div className="indicator-item right-text">
              <h4>
                Registration Status:{" "}
                <span>{studentFees?.registrationStatus === "Approved" ? "Eligible for Physical registration" : studentFees?.registrationStatus}</span>
              </h4>
            </div>
          </div>
        </div>
        <div className="space-for-sign">
          <div className="sign-div">
            <div className="sign-item left-sign-text">
              <div className="space-unit"></div>
              <h4>Faculty Advisor</h4>
            </div>
            <div className="sign-item">
              <div className="space-unit"></div>
              <h4>Hostel Warden</h4>
            </div>
            <div className="sign-item right-sign-text">
              <div className="space-unit"></div>
              {admin ? <h4>F/I Academic UG/PG</h4> : <h4>Student's Sign</h4>}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

const StudentProfileAV = () => {
  const [session, setSession] = React.useState("");
  const [student, setStudent] = React.useState({});
  const [studentFees, setStudentFees] = React.useState();
  const [feeStructure, setFeeStructure] = React.useState({});
  const [rollInput, setRollInput] = React.useState("");
  const [hostelData, setHostelData] = React.useState();
  const [totalPayable, setTotalPayable] = React.useState(0);
  const { roll } = useParams();
  const id = new URLSearchParams(window.location.search).get("id");
  const printRef = React.useRef();
  const printRef1 = React.useRef();
  const print = async () => {
    const canvas = await html2canvas(printRef.current);
    const canvas1 = await html2canvas(printRef1.current);
    const imgData = canvas.toDataURL("image/png");
    const imgData1 = canvas1.toDataURL("image/png");
    const pdf = new jsPDF("landscape");
    pdf.addImage(imgData1, "JPEG", 2, 2, 148, 205, "adminCopy", "SLOW");
    pdf.addImage(imgData, "JPEG", 152, 2, 142, 205, "studentCopy", "SLOW");
    pdf.save(`${roll}_receipt.pdf`);
  };
  const navigate = useNavigate();
  const fetchFeeStructure = React.useCallback(async () => {
    studentFees?.type && await apis
      .feeStructure(
        student?.year,
        student?.course,
        student?.semester,
        student?.batch,
        studentFees?.type
      )
      .then((data) => {
        setSession(
          `${student?.batch} (${studentFees?.semester & 1 ? "Autumn" : "Spring"}`
        );
        setFeeStructure(data);
      })
      .catch((err) => {
        alert(err.message);
      });
  }, [student, studentFees]);

  const fetchHostelData = React.useCallback(async () => {

    student?.roll && studentFees?.semester &&
      (await apis.hostelStatusAdmin(student?.roll, studentFees?.semester).then((data) => {
        setHostelData(data);
      }));
  }, [student?.roll, studentFees?.semester]);

  React.useEffect(() => {
    fetchFeeStructure();
    fetchHostelData();
  }, [fetchFeeStructure, fetchHostelData]);

  React.useEffect(() => {
    const getStudent = async () => {
      await apis
        .profile(roll)
        .then((data) => {
          setStudent(data);
        })
        .catch((err) => {
          alert(err.message);
        });
      await apis
        .specificStudent({ roll: roll, id })
        .then((data) => {
          setStudentFees(data[0]);
        })
        .catch((err) => {
          alert(err.message);
        });
    };
    getStudent();
  }, [roll, id]);

  const allotHostelFee = () => {
    let amount = 0;
    if (hostelData?.hostel === "H1") {
      amount += Number(feeStructure?.h1fee) + Number(feeStructure?.messfee);
    } else if (hostelData?.hostel === "H9") {
      amount += Number(feeStructure?.h9fee) + Number(feeStructure?.messfee);
    } else if (hostelData?.hostel === "H7") {
      amount += Number(feeStructure?.h7fee) + Number(feeStructure?.messfee);
    } else if (hostelData?.hostel === "NBH") {
      amount += Number(feeStructure?.nbhfee) + Number(feeStructure?.messfee);
    } else if (hostelData?.hostel === "GH") {
      amount += Number(feeStructure?.ghfee) + Number(feeStructure?.messfee);
    } else if (hostelData?.hostel === "KBH") {
      amount += Number(feeStructure?.kbhfee) + Number(feeStructure?.messfee);
    } else if (hostelData?.hostel === "KGH") {
      amount += Number(feeStructure?.kghfee) + Number(feeStructure?.messfee);
    }
    return amount;
  };

  const totalDue = () => {
    let totalDue =
      Number(feeStructure?.tuitionfee) +
      Number(feeStructure?.otherfee) +
      Number(feeStructure?.admissionfee) +
      Number(feeStructure?.cautionmoney) +
      Number(feeStructure?.convocationfee) +
      Number(studentFees?.instFine) + Number(studentFees?.hostelFine) + Number(studentFees?.messFine) -
      Number(studentFees?.instAdjust) - Number(studentFees?.hostelAdjust) - Number(studentFees?.messAdjust) +
      Number(allotHostelFee());
    let total =
      totalDue -
      Number(
        Number(studentFees?.currentSemesterPaid?.institute) +
        Number(studentFees?.currentSemesterPaid?.hostel) +
        Number(studentFees?.currentSemesterPaid?.mess)
      );


    return total;
  };

  const [openUpdatePopup, setOpenUpdatePopup] = React.useState({
    open: false,
  });

  return (
    <>
      <ProfileUpdatePopUp
        open={openUpdatePopup.open}
        onClose={() => setOpenUpdatePopup({ open: false })}
        studentData={student}
        setOpenUpdatePopup={setOpenUpdatePopup}
      />
      <AdminHeader />
      <div className="top-div">
        <div className="profile-section">
          <div className="display-div">
            <div className="display-item">
              <div className="summary-div">
                <div className="summary-left-div">
                  <div className="summary-left-item">
                    <h3>
                      Name: <span>{student.name}</span>
                    </h3>
                  </div>
                  <div className="summary-left-item">
                    <h3>
                      Roll: <span>{student.roll}</span>
                    </h3>
                  </div>
                </div>
                <div className="summary-right-div">
                  <div className="summary-right-item">
                    <h3>
                      Email: <span>{student.email}</span>
                    </h3>
                  </div>
                  <div className="summary-right-item">
                    <h3>
                      Phone: <span>{student.contact}</span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="summary-div">
                <div className="summary-left-div">
                  <div className="summary-left-item">
                    <h3>
                      Course: <span>{student.course}</span>
                    </h3>
                  </div>
                  <div className="summary-left-item">
                    <h3>
                      Batch: <span>{student.batch}</span>
                    </h3>
                  </div>
                  <div className="summary-left-item">
                    <h3>
                      Branch: <span>{student.branch}</span>
                    </h3>
                  </div>
                </div>
                <div className="summary-right-div">
                  <div className="summary-right-item">
                    <h3>
                      Student-Type: <span>{studentFees?.type}</span>
                    </h3>
                  </div>
                  <div className="summary-right-item">
                    <h3>
                      Current Semester: <span>{studentFees?.semester}</span>
                    </h3>
                  </div>
                  <div className="summary-right-item">
                    <h3>
                      CGPA: <span>{student?.cgpa}</span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="summary-div">
                <div className="summary-left-div">
                  <div className="summary-left-item">
                    <h3>
                      Registration Status:{" "}
                      <span>{studentFees?.registrationStatus}</span>
                    </h3>
                  </div>
                  <div className="summary-left-item">
                    <h3>
                      Current Semester Paid (total): <span>{Number(studentFees?.currentSemesterPaid?.institute) + Number(studentFees?.currentSemesterPaid?.hostel) + Number(studentFees?.currentSemesterPaid?.mess)} </span><br />
                      <span>
                        Institute: {studentFees?.currentSemesterPaid?.institute},
                        Hostel: {studentFees?.currentSemesterPaid?.hostel}, Mess: {studentFees?.currentSemesterPaid?.mess}
                      </span>
                    </h3>
                  </div>
                  <div className="summary-left-item">
                    <h3>
                      Adjusted amounts (total): <span>{Number(studentFees?.instAdjust) + Number(studentFees?.hostelAdjust) + Number(studentFees?.messAdjust)}</span> <br />
                      <span>Institute: {Number(studentFees?.instAdjust)},
                        Hostel: {Number(studentFees?.hostelAdjust)},
                        Mess: {Number(studentFees?.messAdjust)}</span>
                    </h3>
                  </div>
                </div>
                <div className="summary-right-div">
                  <div className="summary-right-item">
                    <h3>
                      Current Semester Payable:{" "}
                      {/* <span>{totalDue() + Number(studentFees?.currentSemesterPaidExtra)}</span> */}
                      <span>{
                        totalPayable && 
                      totalPayable 
                      - Number(studentFees?.currentSemesterPaid?.institute) 
                      - Number(studentFees?.currentSemesterPaid?.hostel) 
                      - Number(studentFees?.currentSemesterPaid?.mess) 
                      + Number(studentFees?.currentSemesterPaidExtra) 
                      + Number(studentFees?.pastDue) 
                      + Number(studentFees?.instFine) 
                      + Number(studentFees?.hostelFine) 
                      + Number(studentFees?.messFine)
                      - Number(studentFees?.instAdjust) 
                      - Number(studentFees?.hostelAdjust) 
                      - Number(studentFees?.messAdjust)
                      }</span>
                      {/* <span>{Number(studentFees?.totalDue) + Number(studentFees?.currentSemesterPaidExtra)}</span> */}
                    </h3>
                  </div>
                  <div className="summary-right-item">
                    <h3>
                      Past Due: <span>{studentFees?.pastDue}</span>
                    </h3>
                  </div>
                  <div className="summary-right-item">
                    <h3>
                      Fine (total): <span>{Number(studentFees?.instFine) + Number(studentFees?.hostelFine) + Number(studentFees?.messFine)}</span> <br />
                      <span>Institute: {Number(studentFees?.instFine)},
                        Hostel: {Number(studentFees?.hostelFine)},
                        Mess: {Number(studentFees?.messFine)}
                      </span>
                    </h3>
                  </div>
                  <div className="summary-right-item">
                    <h3>
                      {/* Total Due: <span>{totalDue() + Number(studentFees?.currentSemesterPaidExtra) + Number(studentFees?.pastDue)}</span> */}
                      Total Due: <span>{studentFees?.totalDue}</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="side-div">
            <div className="search-div search-AV">
              <div className="search-title-AV">
                <h4>Specific Student Search:</h4>
              </div>
              <div className="search-items search-items-AV">
                <div className="search-item search-item-AV">
                  <h4 htmlFor="roll">Roll Number:</h4>
                  <input
                    type="text"
                    name="student-id"
                    id="student-id"
                    onChange={(e) => setRollInput(e.target.value)}
                  />
                </div>
                {/* <div className="search-item search-item-AV">
                  <h4 htmlFor="email">Email ID:</h4>
                  <input type="text" name="student-id" id="student-id" />
                </div> */}
                <div className="search-item btn-div search-item-AV">
                  <button
                    id="search"
                    className="new_btn primary"
                    onClick={() =>
                      navigate(`/student/admin/studentprofile/${rollInput}`)
                    }
                  >
                    <span>Search</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="printing-div">
              <div className="printing-div-title">
                <h4>Update User Data:</h4>
              </div>
              <div className="printing-div-item">
                <button id="print" className="new_btn" onClick={() => setOpenUpdatePopup({ open: true })}>
                  <span>Update</span>
                </button>
              </div>
            </div>
            <div className="printing-div">
              <div className="printing-div-title">
                <h4>Print Options:</h4>
              </div>
              <div className="printing-div-item">
                <button id="print" className="new_btn" onClick={print}>
                  <span>Download Printable Copy</span>
                </button>
              </div>
            </div>
            {/* <div className="contact-div">
              <div className="contact-div-title">
                <h4>Contact/Other Details:</h4>
              </div>
              <div className="contact-details">
                <div className="phone-div">
                  <h4>
                    Phone: <span>+91 {student.contact}</span>
                  </h4>
                </div>
                <div className="email-div">
                  <h4>
                    Email: <span>{student.email}</span>
                  </h4>
                </div>
              </div>
            </div> */}

          </div>
        </div>
      </div>
      <div className="mid-div">
        <div className="reportsView-section">
          <StudentReport
            ref={printRef}
            admin={true}
            session={session}
            student={student}
            studentFees={studentFees}
            setTotalPayable={setTotalPayable}
          />
          <StudentReport
            ref={printRef1}
            admin={false}
            session={session}
            student={student}
            studentFees={studentFees}
            setTotalPayable={setTotalPayable}
          />
        </div>
      </div>
      <div className="bottom-div">
        <PaymentHistory student={studentFees} admin={true} />
      </div>
    </>
  );
};

export default StudentProfileAV;
