import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import "../css/Component/OtherDetails.css";
import apis from "../apis";

const OtherDetails = ({ studentData, adminData }) => {
  const [updateSelf, setUpdateSelf] = useState(false);
  const [updateParent, setUpdateParent] = useState(false);
  const [updateGuardian, setUpdateGuardian] = useState(false);
  const [updateAddress, setUpdateAddress] = useState(false);
  const [personalData, setPersonalData] = useState({
    gender: studentData?.gender,
    allotmentCategory: studentData?.allotmentCategory,
    dob: studentData?.dob,
    bloodgroup: studentData?.bloodgroup,
    stateofeligibility: studentData?.stateofeligibility,
    nationality: studentData?.nationality,
    aadhar: studentData?.aadhar,
    fathersname: studentData?.fathersname || studentData?.fathername,
    mothersname: studentData?.mothersname || studentData?.mothername,
    pcontact: studentData?.pcontact,
    gname: studentData?.gname,
    gcontact: studentData?.gcontact,
    gemail: studentData?.gemail,
    grelation: studentData?.grelation,
    caddress: studentData?.caddress,
    paddress: studentData?.paddress,
  });

  const [checkUpdate, setCheckUpdate] = useState({
    gender: false,
    allotmentCategory: false,
    dob: false,
    bloodgroup: false,
    stateofeligibility: false,
    nationality: false,
    aadhar: false,
    fathersname: false,
    mothersname: false,
    pcontact: false,
    gname: false,
    gcontact: false,
    gemail: false,
    grelation: false,
    caddress: false,
    paddress: false,
  });

  useEffect(() => {
    setPersonalData({
      gender: studentData?.gender,
      allotmentCategory: studentData?.allotmentCategory,
      dob: studentData?.dob,
      bloodgroup: studentData?.bloodgroup,
      stateofeligibility: studentData?.stateofeligibility,
      nationality: studentData?.nationality,
      aadhar: studentData?.aadhar,
      fathersname: studentData?.fathersname || studentData?.fathername,
      mothersname: studentData?.mothersname || studentData?.mothername,
      pcontact: studentData?.pcontact,
      gname: studentData?.gname,
      gcontact: studentData?.gcontact,
      gemail: studentData?.gemail,
      grelation: studentData?.grelation,
      caddress: studentData?.caddress,
      paddress: studentData?.paddress,
    });

    setCheckUpdate({
      gender: false,
      allotmentCategory: false,
      dob: false,
      bloodgroup: false,
      stateofeligibility: false,
      nationality: false,
      aadhar: false,
      fathersname: false,
      mothersname: false,
      pcontact: false,
      gname: false,
      gcontact: false,
      gemail: false,
      grelation: false,
      caddress: false,
      paddress: false,
    });
  }, [studentData, adminData]);

  const saveUpdateSelf = async () => {
    const updateData = {};
    if (checkUpdate.gender) updateData.gender = personalData.gender;
    if (checkUpdate.allotmentCategory)
      updateData.allotmentCategory = personalData.allotmentCategory;
    if (checkUpdate.dob) updateData.dob = personalData.dob;
    if (checkUpdate.bloodgroup) updateData.bloodgroup = personalData.bloodgroup;
    if (checkUpdate.stateofeligibility)
      updateData.stateofeligibility = personalData.stateofeligibility;
    if (checkUpdate.nationality)
      updateData.nationality = personalData.nationality;
    if (checkUpdate.aadhar) updateData.aadhar = personalData.aadhar;

    const givenData = {
      roll: studentData.roll,
      applicationid: studentData.applicationid,
    };

    const data = {
      updateData,
      givenData,
    };

    try {
      await apis.updateNewProfile(data).then((res) => {
        console.log(res);
        alert("Updated Successfully");
        setUpdateSelf(false);
        studentData.gender = updateData?.gender || studentData?.gender;
        studentData.allotmentCategory =
          updateData?.allotmentCategory || studentData?.allotmentCategory;
        studentData.dob = updateData?.dob || studentData?.dob;
        studentData.bloodgroup =
          updateData?.bloodgroup || studentData?.bloodgroup;
        studentData.stateofeligibility =
          updateData?.stateofeligibility || studentData?.stateofeligibility;
        studentData.nationality =
          updateData?.nationality || studentData?.nationality;
        studentData.aadhar = updateData?.aadhar || studentData?.aadhar;

        setCheckUpdate({
          ...checkUpdate,
          gender: false,
          allotmentCategory: false,
          dob: false,
          bloodgroup: false,
          stateofeligibility: false,
          nationality: false,
          aadhar: false,
        });
      });
    } catch (error) {
      console.log(error);
      setUpdateSelf(false);
      setCheckUpdate({
        ...checkUpdate,
        gender: false,
        allotmentCategory: false,
        dob: false,
        bloodgroup: false,
        stateofeligibility: false,
        nationality: false,
        aadhar: false,
      });
    }
  };

  const saveUpdateParent = async () => {
    const updateData = {};
    if (checkUpdate.fathersname)
      updateData.fathersname = personalData.fathersname;
    if (checkUpdate.mothersname)
      updateData.mothersname = personalData.mothersname;
    if (checkUpdate.pcontact) updateData.pcontact = personalData.pcontact;

    const givenData = {
      roll: studentData.roll,
      applicationid: studentData.applicationid,
    };

    const data = {
      updateData,
      givenData,
    };

    try {
      await apis.updateNewProfile(data).then((res) => {
        console.log(res);
        alert("Updated Successfully");
        setUpdateParent(false);
        studentData.fathersname =
          updateData?.fathersname || studentData?.fathersname;
        studentData.mothersname =
          updateData?.mothersname || studentData?.mothersname;
        studentData.pcontact = updateData?.pcontact || studentData?.pcontact;

        setCheckUpdate({
          ...checkUpdate,
          fathersname: false,
          mothersname: false,
          pcontact: false,
        });
      });
    } catch (error) {
      console.log(error);
      setUpdateParent(false);
      setCheckUpdate({
        ...checkUpdate,
        fathersname: false,
        mothersname: false,
        pcontact: false,
      });
    }
  };

  const saveUpdateGuardian = async () => {
    const updateData = {};
    if (checkUpdate.gname) updateData.gname = personalData.gname;
    if (checkUpdate.gcontact) updateData.gcontact = personalData.gcontact;
    if (checkUpdate.gemail) updateData.gemail = personalData.gemail;

    const givenData = {
      roll: studentData.roll,
      applicationid: studentData.applicationid,
    };

    const data = {
      updateData,
      givenData,
    };

    try {
      await apis.updateNewProfile(data).then((res) => {
        console.log(res);
        alert("Updated Successfully");
        setUpdateGuardian(false);
        studentData.gname = updateData?.gname || studentData?.gname;
        studentData.gcontact = updateData?.gcontact || studentData?.gcontact;
        studentData.gemail = updateData?.gemail || studentData?.gemail;

        setCheckUpdate({
          ...checkUpdate,
          gname: false,
          gcontact: false,
          gemail: false,
        });
      });
    } catch (error) {
      console.log(error);
      setUpdateGuardian(false);
      setCheckUpdate({
        ...checkUpdate,
        gname: false,
        gcontact: false,
        gemail: false,
      });
    }
  };

  const saveUpdateAddress = async () => {
    const updateData = {};
    if (checkUpdate.caddress) updateData.caddress = personalData.caddress;
    if (checkUpdate.paddress) updateData.paddress = personalData.paddress;

    const givenData = {
      roll: studentData.roll,
      applicationid: studentData.applicationid,
    };

    const data = {
      updateData,
      givenData,
    };

    try {
      await apis.updateNewProfile(data).then((res) => {
        console.log(res);
        alert("Updated Successfully");
        setUpdateAddress(false);
        studentData.caddress = updateData?.caddress || studentData?.caddress;
        studentData.paddress = updateData?.paddress || studentData?.paddress;

        setCheckUpdate({
          ...checkUpdate,
          caddress: false,
          paddress: false,
        });
      });
    } catch (error) {
      console.log(error);
      setUpdateAddress(false);
      setCheckUpdate({
        ...checkUpdate,
        caddress: false,
        paddress: false,
      });
    }
  };

  const cancelUpdateSelf = () => {
    setUpdateSelf(false);
    setPersonalData({
      ...personalData,
      gender: studentData?.gender,
      allotmentCategory: studentData?.allotmentCategory,
      dob: studentData?.dob,
      bloodgroup: studentData?.bloodgroup,
      stateofeligibility: studentData?.stateofeligibility,
      nationality: studentData?.national,
      aadhar: studentData?.aadhar,
    });

    setCheckUpdate({
      ...checkUpdate,
      gender: false,
      allotmentCategory: false,
      dob: false,
      bloodgroup: false,
      stateofeligibility: false,
      nationality: false,
      aadhar: false,
    });
  };

  const cancelUpdateParent = () => {
    setUpdateParent(false);
    setPersonalData({
      ...personalData,
      fathersname: studentData?.fathersname || studentData?.fathername,
      mothersname: studentData?.mothersname || studentData?.mothername,
      pcontact: studentData?.pcontact,
    });

    setCheckUpdate({
      ...checkUpdate,
      fathersname: false,
      mothersname: false,
      pcontact: false,
    });
  };

  const cancelUpdateGuardian = () => {
    setUpdateGuardian(false);
    setPersonalData({
      ...personalData,
      gname: studentData?.gname,
      gcontact: studentData?.gcontact,
      gemail: studentData?.gemail,
    });

    setCheckUpdate({
      ...checkUpdate,
      gname: false,
      gcontact: false,
      gemail: false,
    });
  };

  const cancelUpdateAddress = () => {
    setUpdateAddress(false);
    setPersonalData({
      ...personalData,
      caddress: studentData?.caddress,
      paddress: studentData?.paddress,
    });

    setCheckUpdate({
      ...checkUpdate,
      caddress: false,
      paddress: false,
    });
  };

  return (
    <div className="otherPageCont">
      <div className="o_top">
        <div className="o_leftPart">
          <p className="o_title">Self</p>
          <div className="o_left">
            <div className="profile_edits">
              {(adminData?.role?.includes("DOAA") || (adminData?.role?.includes("Super Admin"))) && (!updateSelf ? (
                <EditIcon onClick={() => setUpdateSelf(true)} />
              ) : (
                <div style={{ display: "flex", gap: "10px" }}>
                  <CheckCircleOutlineRoundedIcon
                    onClick={() => saveUpdateSelf()}
                  />
                  <HighlightOffRoundedIcon onClick={() => cancelUpdateSelf()} />
                </div>
              ))}
            </div>
            <div className={`o_content ${updateSelf && "o_update"}`}>
              <span>Gender: </span>
              {updateSelf ? (
                <input
                  className="profile_input"
                  type="text"
                  value={personalData.gender}
                  onChange={(e) => {
                    setPersonalData({
                      ...personalData,
                      gender: e.target.value,
                    });
                    setCheckUpdate({ ...checkUpdate, gender: true });
                  }}
                />
              ) : (
                personalData.gender
              )}
            </div>
            <div className={`o_content ${updateSelf && "o_update"}`}>
              <span>Category: </span>
              {updateSelf ? (
                <input
                  className="profile_input"
                  type="text"
                  value={personalData.allotmentCategory}
                  onChange={(e) => {
                    setCheckUpdate({ ...checkUpdate, allotmentCategory: true });
                    setPersonalData({
                      ...personalData,
                      allotmentCategory: e.target.value,
                    });
                  }}
                />
              ) : (
                personalData.allotmentCategory
              )}
            </div>
            <div className={`o_content ${updateSelf && "o_update"}`}>
              <span>Date of Birth: </span>
              {updateSelf ? (
                <input
                  className="profile_input"
                  type="date"
                  value={personalData.dob}
                  onChange={(e) => {
                    setCheckUpdate({ ...checkUpdate, dob: true });
                    setPersonalData({ ...personalData, dob: e.target.value });
                  }}
                />
              ) : (
                personalData.dob
              )}
            </div>
            <div className={`o_content ${updateSelf && "o_update"}`}>
              <span>BloodGroup: </span>
              {updateSelf ? (
                <input
                  className="profile_input"
                  type="text"
                  value={personalData.bloodgroup}
                  onChange={(e) => {
                    setCheckUpdate({ ...checkUpdate, bloodgroup: true });
                    setPersonalData({
                      ...personalData,
                      bloodgroup: e.target.value,
                    });
                  }}
                />
              ) : (
                personalData.bloodgroup
              )}
            </div>
            <div className={`o_content ${updateSelf && "o_update"}`}>
              <span>State: </span>
              {updateSelf ? (
                <input
                  className="profile_input"
                  type="text"
                  value={personalData.stateofeligibility}
                  onChange={(e) => {
                    setCheckUpdate({
                      ...checkUpdate,
                      stateofeligibility: true,
                    });
                    setPersonalData({
                      ...personalData,
                      stateofeligibility: e.target.value,
                    });
                  }}
                />
              ) : (
                personalData.stateofeligibility
              )}
            </div>
            <div className={`o_content ${updateSelf && "o_update"}`}>
              <span>Nationality: </span>
              {updateSelf ? (
                <input
                  className="profile_input"
                  type="text"
                  value={personalData.nationality}
                  onChange={(e) => {
                    setCheckUpdate({ ...checkUpdate, nationality: true });
                    setPersonalData({
                      ...personalData,
                      nationality: e.target.value,
                    });
                  }}
                />
              ) : (
                personalData.nationality
              )}
            </div>
            <div className={`o_content ${updateSelf && "o_update"}`}>
              <span>Aadhar: </span>
              {updateSelf ? (
                <input
                  className="profile_input"
                  type="text"
                  value={personalData.aadhar}
                  onChange={(e) => {
                    setCheckUpdate({ ...checkUpdate, aadhar: true });
                    setPersonalData({
                      ...personalData,
                      aadhar: e.target.value,
                    });
                  }}
                />
              ) : (
                personalData.aadhar
              )}
            </div>
          </div>
        </div>
        <div className="o_rightPart">
          <div className="o_upperHalf">
            <p className="o_title">Parent Details</p>
            <div className="o_right">
              <div className="profile_edits">
                {(adminData?.role?.includes("DOAA") || (adminData?.role?.includes("Super Admin"))) && (!updateParent ? (
                  <EditIcon onClick={() => setUpdateParent(true)} />
                ) : (
                  <div style={{ display: "flex", gap: "10px" }}>
                    <CheckCircleOutlineRoundedIcon
                      onClick={() => saveUpdateParent()}
                    />
                    <HighlightOffRoundedIcon
                      onClick={() => cancelUpdateParent()}
                    />
                  </div>
                ))}
              </div>
              <div className={`o_content ${updateParent && "o_update"}`}>
                <span>Father's Name: </span>
                {updateParent ? (
                  <input
                    className="profile_input"
                    type="text"
                    value={personalData.fathersname}
                    onChange={(e) => {
                      setCheckUpdate({ ...checkUpdate, fathersname: true });
                      setPersonalData({
                        ...personalData,
                        fathersname: e.target.value,
                      });
                    }}
                  />
                ) : (
                  personalData.fathersname
                )}
              </div>
              <div className={`o_content ${updateParent && "o_update"}`}>
                <span>Mother's Name: </span>
                {updateParent ? (
                  <input
                    className="profile_input"
                    type="text"
                    value={personalData.mothersname}
                    onChange={(e) => {
                      setCheckUpdate({ ...checkUpdate, mothersname: true });
                      setPersonalData({
                        ...personalData,
                        mothersname: e.target.value,
                      });
                    }}
                  />
                ) : (
                  personalData.mothersname
                )}
              </div>
              <div className={`o_content ${updateParent && "o_update"}`}>
                <span>Parent Contact: </span>
                {updateParent ? (
                  <input
                    className="profile_input"
                    type="text"
                    value={personalData.pcontact}
                    onChange={(e) => {
                      setCheckUpdate({ ...checkUpdate, pcontact: true });
                      setPersonalData({
                        ...personalData,
                        pcontact: e.target.value,
                      });
                    }}
                  />
                ) : (
                  personalData.pcontact
                )}
              </div>
            </div>
          </div>
          <div className="o_lowerHalf">
            <p className="o_title">Guardians Details</p>
            <div className="o_right">
              <div className="profile_edits">
                {(adminData?.role?.includes("DOAA") || (adminData?.role?.includes("Super Admin"))) && (!updateGuardian ? (
                  <EditIcon onClick={() => setUpdateGuardian(true)} />
                ) : (
                  <div style={{ display: "flex", gap: "10px" }}>
                    <CheckCircleOutlineRoundedIcon
                      onClick={() => saveUpdateGuardian()}
                    />
                    <HighlightOffRoundedIcon
                      onClick={() => cancelUpdateGuardian()}
                    />
                  </div>
                ))}
              </div>
              <div className={`o_content ${updateGuardian && "o_update"}`}>
                <span>Guardian Name: </span>
                {updateGuardian ? (
                  <input
                    className="profile_input"
                    type="text"
                    value={personalData.gname}
                    onChange={(e) => {
                      setCheckUpdate({ ...checkUpdate, gname: true });
                      setPersonalData({
                        ...personalData,
                        gname: e.target.value,
                      });
                    }}
                  />
                ) : (
                  <>
                    <span>{personalData.gname}</span>
                    <span>{`(${personalData.grelation})`}</span>
                  </>
                )}
              </div>
              <div className={`o_content ${updateGuardian && "o_update"}`}>
                <span>Guardian's Contact: </span>
                {updateGuardian ? (
                  <input
                    className="profile_input"
                    type="text"
                    value={personalData.gcontact}
                    onChange={(e) => {
                      setCheckUpdate({ ...checkUpdate, gcontact: true });
                      setPersonalData({
                        ...personalData,
                        gcontact: e.target.value,
                      });
                    }}
                  />
                ) : (
                  personalData.gcontact
                )}
              </div>
              <div className={`o_content ${updateGuardian && "o_update"}`}>
                <span>Guardian's Email: </span>
                {updateGuardian ? (
                  <input
                    className="profile_input"
                    type="text"
                    value={personalData.gemail}
                    onChange={(e) => {
                      setCheckUpdate({ ...checkUpdate, gemail: true });
                      setPersonalData({
                        ...personalData,
                        gemail: e.target.value,
                      });
                    }}
                  />
                ) : (
                  personalData.gemail
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="o_bottom">
        <div className="profile_edits">
          {(adminData?.role?.includes("DOAA") || (adminData?.role?.includes("Super Admin"))) && (!updateAddress ? (
            <EditIcon onClick={() => setUpdateAddress(true)} />
          ) : (
            <div style={{ display: "flex", gap: "10px" }}>
              <CheckCircleOutlineRoundedIcon
                onClick={() => saveUpdateAddress()}
              />
              <HighlightOffRoundedIcon onClick={() => cancelUpdateAddress()} />
            </div>
          ))}
        </div>
        <div className={`o_content ${updateAddress && "o_update"}`}>
          <span>Current Address: </span>
          {updateAddress ? (
            <input
              className="profile_input"
              type="text"
              style={{ width: "50%" }}
              value={personalData.caddress}
              onChange={(e) => {
                setCheckUpdate({ ...checkUpdate, caddress: true });
                setPersonalData({
                  ...personalData,
                  caddress: e.target.value,
                });
              }}
            />
          ) : (
            personalData.caddress
          )}
        </div>
        <div className={`o_content ${updateAddress && "o_update"}`}>
          <span>Permanent Address: </span>
          {updateAddress ? (
            <input
              className="profile_input"
              type="text"
              style={{ width: "50%" }}
              value={personalData.paddress}
              onChange={(e) => {
                setCheckUpdate({ ...checkUpdate, paddress: true });
                setPersonalData({
                  ...personalData,
                  paddress: e.target.value,
                });
              }}
            />
          ) : (
            personalData.paddress
          )}
        </div>
      </div>
    </div>
  );
};

export default OtherDetails;
