import React, { useState } from "react";
import { AuthProvider } from "../auth/AuthContext";
import apis from "../apis";
import "../css/Page/BonafideApply.css";

const BonafideApply = () => {
  const { userData } = AuthProvider();
  const [name, setName] = useState(userData?.name);
  const [roll, setRoll] = useState(userData?.roll);
  const [fatherName, setFatherName] = useState(userData?.fathersname);
  const [course, setCourse] = useState(userData?.course);
  const [branch, setBranch] = useState(userData?.branch);
  const [applySemester, setApplySemester] = useState(userData?.semester);
  const [type, setType] = useState(null);
  const [purpose, setPurpose] = useState("");
  const [state, setState] = useState("");
  const [selectOther, setSelectOther] = useState(false);
  const [file, setFile] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("roll", roll);
    formData.append("purpose", purpose);
    formData.append("otherFormat", file);
    formData.append("applicationType", type);
    formData.append("applySemester", applySemester);
    formData.append("state", state.toUpperCase())

    await apis
      .applyBonafide(formData)
      .then((res) => {
        alert(res.message);
        window.location.pathname = "/student/documents";
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <div className="bonafide-application">
      <h1>Application Form</h1>

      <form onSubmit={handleSubmit}>
        <div style={{ width: "98%" }}>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            disabled
            onChange={(e) => setName(e.target.value)}
            required
          />

          <label htmlFor="roll">Roll Number:</label>
          <input
            type="text"
            id="roll"
            value={roll}
            disabled
            onChange={(e) => setRoll(e.target.value)}
            required
          />

          <label htmlFor="fatherName">Father's Name:</label>
          <input
            type="text"
            id="fatherName"
            value={fatherName}
            disabled
            onChange={(e) => setFatherName(e.target.value)}
            required
          />

          <label htmlFor="course">Course:</label>
          <select
            id="course"
            value={course}
            disabled
            onChange={(e) => setCourse(e.target.value)}
            required
          >
            <option value="">Select Course</option>
            <option value="B.TECH.">B.Tech</option>
            <option value="M.TECH.">M.Tech</option>
            <option value="Ph.D.">PhD</option>
          </select>

          <label htmlFor="branch">Branch:</label>
          <select
            id="branch"
            value={branch}
            disabled
            onChange={(e) => setBranch(e.target.value)}
            required
          >
            <option value="">Select Branch</option>
            <option value="CSE">CSE</option>
            <option value="ECE">ECE</option>
            <option value="MAE">MAE</option>
            <option value="MNC">MNC</option>
          </select>

          <label htmlFor="branch">Apply Semester:</label>
          <select
            id="semester"
            value={applySemester}
            onChange={(e) => setApplySemester(e.target.value)}
            required
          >
            <option value="" disabled>
              Select the semester for which <br /> you want your application
            </option>
            <option value={userData?.semester}>{userData?.semester}</option>
            <option value={(Number(userData?.semester) + 1).toString()}>
              {(Number(userData?.semester) + 1).toString()}
            </option>
          </select>

          <label htmlFor="type">Application Type:</label>
          <select
            id="type"
            value={type}
            onChange={(e) => setType(e.target.value)}
            required
          >
            <option value="">Select Application Type</option>
            <option value="Bonafide">Bonafide</option>
            <option value="Receipt">Fee Receipt</option>
            <option value="Demand">Demand Letter</option>
          </select>

          <label htmlFor="purpose">Purpose:</label>
          <select
            id="type"
            value={selectOther ? "Other" : purpose}
            onChange={(e) => {
              if (e.target.value === "Other") {
                setSelectOther(true);
                setPurpose(e.target.value);
              } else {
                setSelectOther(false);
                setPurpose(e.target.value);
              }
            }}
            required
          >
            <option value="">Select Purpose for Application</option>
            <option value="National Scholorship via NSP">
              National Scholorship via NSP
            </option>
            <option value="Other">Other</option>
          </select>
          {selectOther && (
            <textarea
              id="purpose"
              value={purpose === "Other" ? "" : purpose}
              placeholder="Please specify the purpose for application"
              onChange={(e) => setPurpose(e.target.value)}
              required
            />
          )}
          {purpose === "National Scholorship via NSP" && (
            <>
            <label htmlFor="state">Enter your Domicile State:</label>
            <input
              type="text"
              id="state"
              value={state.toUpperCase()}
              onChange={(e) => setState(e.target.value)}
              required
            />
            </>
          )}

          {type === "Bonafide" && (
            <label htmlFor="file">
              Upload Bonafide Format: only if you have been asked for specific
              format
            </label>
          )}
          {type === "Bonafide" && (
            <input type="file" id="file" onChange={handleFileChange} />
          )}
        </div>

        <button className="primary" type="submit">
          Submit
        </button>
      </form>
    </div>
  );
};

export default BonafideApply;
