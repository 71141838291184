import React, { useEffect, useState } from "react";
import "../css/Component/ProfileUpdatePopUp.css";
import apis from "../apis";

const arrayBufferToBase64 = (buffer) => {
  var binary = "";
  var bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => (binary += String.fromCharCode(b)));
  return window.btoa(binary);
};

const ProfileUpdatePopUp = ({
  open,
  onClose,
  studentData,
  setOpenUpdatePopup,
}) => {
  const [picture, setPicture] = useState(null);
  const [name__, setName__] = useState(null);
  const [phoneNo__, setPhoneNo__] = useState(null);
  const [parentPhoneNo__, setParentPhoneNo__] = useState(null);
  const [address__, setAddress__] = useState(null);
  const [bufferImage, setBufferImage] = useState(null);

  useEffect(() => {
    if (studentData) {
      setPicture(
        `data:image/*;base64,${arrayBufferToBase64(
          studentData?.picture?.data
        ).toString("base64")}`
      );
      // console.log(arrayBufferToBase64(
      //   studentData?.picture?.data
      // ).toString("base64"));
      // console.log(studentData.picture);
    }
  }, [studentData, studentData.picture]);

  const updateProfileDataChange = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setPicture(reader.result);
        setBufferImage(reader.result.split(",")[1]);
      } else {
        setPicture(null);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const updateUserData = async() => {
    const name = name__ || studentData.name;
    const phoneNo = phoneNo__ || studentData.contact ;
    const parentPhoneNo = parentPhoneNo__ || studentData.pcontact;
    const address = address__ || studentData.address;
    
    const data = {
      name,
      contact: phoneNo,
      pcontact: parentPhoneNo,
      address,
      picture: bufferImage ? {
        data: bufferImage,
        type: "Buffer",
      } : studentData?.picture,
    };

    await apis.updatePersonalProfile(data, studentData.roll).then((data) => {
      alert("Profile Updated Successfully");
      window.location.reload();
      setOpenUpdatePopup(false);
    })
  };

  if (!open) return null;

  return (
    <div className="popUpOverlay">
      <div className="profileUpdateContainer">
        <button className="profile-closebtn" onClick={onClose}>
          <i className="fa-solid fa-xmark"></i>
        </button>
        <div className="formPart">
          <div className="picPart">
            <div className="picWithName">
              <img src={picture} alt="profilePic_" className="profilePic_" />
              <div className="">{studentData.name}</div>
            </div>
            <div className="">
              <input
                type="file"
                name="avatar"
                accept="image/*"
                id="avatar"
                className="avatar"
                placeholder="Update Profile Picture"
                style={{ display: "none" }}
                onChange={updateProfileDataChange}
              />
              <label
                htmlFor="avatar"
                className="avatarButton"
                style={{ cursor: "pointer" }}
              >
                Update Profile Picture
              </label>
            </div>
          </div>
          <div className="dataPart">
            <div className="_data">
              <label htmlFor="_name">Name</label>
              <input
                type="text"
                name="_name"
                id="name"
                onChange={(e) => setName__(e.target.value)}
                placeholder={studentData.name}
              />
            </div>
            <div className="_data">
              <label htmlFor="phoneNo.">Contact Number</label>
              <input
                type="text"
                name="phoneNo."
                id="phoneNo."
                onChange={(e) => setPhoneNo__(e.target.value)}
                placeholder={studentData.contact}
              />
            </div>
            <div className="_data">
              <label htmlFor="parentPhoneNo.">Parent Contact Number</label>
              <input
                type="text"
                name="parentPhoneNo."
                id="parentPhoneNo."
                onChange={(e) => setParentPhoneNo__(e.target.value)}
                placeholder={studentData.pcontact}
              />
            </div>
            <div className="_data">
              <label htmlFor="address">Address</label>
              <textarea
                cols={30}
                rows={3}
                type="text"
                name="address"
                id="address"
                onChange={(e) => setAddress__(e.target.value)}
                placeholder={studentData.address}
              />
            </div>
          </div>
        </div>
        <div className="updateBtnPart">
          <button className="updateBtn" onClick={updateUserData}>Update</button>
        </div>
      </div>
    </div>
  );
};

export default ProfileUpdatePopUp;
