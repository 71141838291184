import * as React from "react";
import Header from "../components/Header";
import "../css/Page/AdmissionPage.css";
import { AuthProvider } from "../auth/AuthContext";
import api from "../newRegistrationApis";
import { useForm } from "react-hook-form";
import PaySectionNew from "../components/PaySectionNew";
import PaymentHistoryNew from "../components/PaymentHistoryNew";
import { CircularProgress } from '@mui/material';
import apis from "../apis";

const AdmissionPage = () => {
  const [step, setStep] = React.useState(0);
  const [fileLoading, setFileLoading] = React.useState(false);
  const [fileLoading2, setFileLoading2] = React.useState(false);
  const [personalDetails, setPersonalDetails] = React.useState(false);
  const [personalFiles, setPersonalFiles] = React.useState(false);
  const [otherDetails, setOtherDetails] = React.useState(false);
  const [feeDetails, setFeeDetails] = React.useState();
  const [fileStatus, setFileStatus] = React.useState();
  const [isPersonalFilesRejected, setIsPersonalFilesRejected] = React.useState(false);
  const [isOtherFilesRejected, setIsOtherFilesRejected] = React.useState(false);
  const { newUserData } = AuthProvider();
  // console.log(newUserData);
  const { register, handleSubmit } = useForm();

  const fetchStudentFeeDetail = React.useCallback(() => {
    newUserData?.applicationid && apis.specificStudent({ applicationid: newUserData?.applicationid, semester: newUserData?.semester }).then((res) => {
      // console.log(res[0]);
      api.checkReupload(newUserData?.applicationid).then((res) => {
        setFileStatus(res);

        const isPersonalFilesRejected = res?.photo?.status === "Rejected" || res?.signature?.status === "Rejected" || res?.allotmentletter?.status === "Rejected" || res?.aadhar?.status === "Rejected" || res?.tenth?.status === "Rejected" || res?.twelfth?.status === "Rejected";
        const isOtherFilesRejected = res?.medicalCertificate?.status === "Rejected" || res?.provisionalDocVerificationLetter?.status === "Rejected" || res?.application?.status === "Rejected" || res?.pwdCertificate?.status === "Rejected";

        setIsPersonalFilesRejected(isPersonalFilesRejected);
        setIsOtherFilesRejected(isOtherFilesRejected);
      }).catch((err) => {
        console.log(err.message);
      });
      setFeeDetails(res[0]);
    }).catch((err) => {
      alert(err.message);
    })
  }, [newUserData]);


  React.useEffect(() => {
    if (newUserData?.personalDetailsFilled) {
      setPersonalDetails(true);
    }
    if (newUserData?.personalFilesFilled) {
      setPersonalFiles(true);
    }
    if (newUserData?.otherFilesFilled) {
      setOtherDetails(true);
    }
    fetchStudentFeeDetail();
  }, [newUserData, fetchStudentFeeDetail]);

  const detailsSubmit = async (data) => {
    if (feeDetails?.totalDue > 0) {
      alert("Please pay the due amount to proceed");
      return;
    }
    api
      .updateProfile(data)
      .then((res) => {
        setPersonalDetails(true);
        alert("Data Submitted");
        setStep(1);
      })
      .catch((err) => {
        alert(err.message);
      });
  };


  const uploadFiles1 = async (data) => {
    if (!newUserData?.personalDetailsFilled) {
      alert("Please fill the personal details to proceed");
      return;
    }
    if (data.aadhaar[0].size > 1000000) {
      alert("Aadhaar file size should be less than 1MB");
      return;
    } else if (data.allotmentletter[0].size > 1000000) {
      alert("Allotment letter file size should be less than 1MB");
      return;
    } else if (data.photo[0].size > 1000000) {
      alert("Photo file size should be less than 1MB");
      return;
    } else if (data.signature[0].size > 1000000) {
      alert("Signature file size should be less than 1MB");
      return;
    } else if (data.tenth[0].size > 1000000) {
      alert("10th marksheet file size should be less than 1MB");
      return;
    } else if (data.twelfth[0].size > 1000000) {
      alert("12th marksheet file size should be less than 1MB");
      return;
    }
    let formData = new FormData();

    formData.append("aadhaar", data.aadhaar[0]);
    formData.append("allotmentletter", data.allotmentletter[0]);
    formData.append("photo", data.photo[0]);
    formData.append("signature", data.signature[0]);
    formData.append("tenth", data.tenth[0]);
    formData.append("twelfth", data.twelfth[0]);
    if (feeDetails?.totalDue > 0) {
      alert("Please pay the due amount to proceed");
      return;
    }
    setFileLoading(true);
    api
      .uploadFiles1(formData, newUserData?.applicationid)
      .then((res) => {
        setFileLoading(false);
        setPersonalFiles(true);
        alert("Files uploaded successfully");
        setStep(2);
      })
      .catch((err) => {
        setFileLoading(false);
        alert(err.message);
      });
  };

  const uploadFiles2 = async (data) => {
    if (!newUserData?.personalFilesFilled || !newUserData?.personalDetailsFilled) {
      alert("Please upload the personal files to proceed");
      return;
    }
    if (data?.pwd[0]?.size > 1000000) {
      alert("PWD file size should be less than 1MB");
      return;
    } else if (data.medical[0].size > 1000000) {
      alert("Medical file size should be less than 1MB");
      return;
    } else if (data.provisionalDoc[0].size > 1000000) {
      alert("Provisional document verification letter file size should be less than 1MB");
      return;
    } else if (data?.appInLew[0]?.size > 1000000) {
      alert("Category certificate file size should be less than 1MB");
      return;
    }
    let formData = new FormData();
    formData.append("pwdCertificate", data.pwd[0]);
    formData.append("medicalCertificate", data.medical[0]);
    // formData.append("antiRaggingStudent", data.arStudent[0]);
    // formData.append("antiRaggingParent", data.arParent[0]);
    formData.append("provisionalDocVerificationLetter", data.provisionalDoc[0]);
    formData.append("application", data.appInLew[0]);
    if (feeDetails?.totalDue > 0) {
      alert("Please pay the due amount to proceed");
      return;
    }
    setFileLoading2(true);
    await api
      .uploadFiles2(formData, newUserData?.applicationid)
      .then(async (res) => {
        const isReupload = fileStatus?.photo?.status === "Rejected" || fileStatus?.signature?.status === "Rejected" || fileStatus?.allotmentletter?.status === "Rejected" || fileStatus?.aadhar?.status === "Rejected" || fileStatus?.tenth?.status === "Rejected" || fileStatus?.twelfth?.status === "Rejected" || fileStatus?.medicalCertificate?.status === "Rejected" || fileStatus?.provisionalDocVerificationLetter?.status === "Rejected" || fileStatus?.application?.status === "Rejected";
        setFileLoading2(false);
        setOtherDetails(true);
        if (!isReupload) {
          await apis.generateEmailRoll(newUserData);
          alert("Files uploaded successfully & Provisonal Admission is Successful!! Kindly check your mail for further process...");
        }
        else if(isReupload && (!newUserData?.personalDetailsFilled || !newUserData?.personalFilesFilled || !newUserData?.otherFilesFilled)){
          await apis.generateEmailRoll(newUserData);
          alert("Files uploaded successfully & Provisonal Admission is Successful!! Kindly check your mail for further process...");
        }
        window.location.reload();
      })
      .catch((err) => {
        setFileLoading2(false);
        alert(err.message);
      });
  };

  return (
    <>
      <Header />
      <div className="top-div">
        <div className="banner-image">
          <div className="blur-overlay">
            <div className="banner-message">
              <h1>Welcome to IIIT Bhagalpur!</h1>
              <h4>
                To complete your registration, please fill out the form below
                with your personal and academic information. Make sure to review
                your details carefully before submitting the form.
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="mid-div">
        <div className="Applicant-Details">
          <div className="Applicant-Details-Title">
            <h2>Applicant Details</h2>
          </div>
          <div className="Applicant-Details-Content">
            <div className="content-div-row">
              <div className="content-div-element card-main">
                <div className="content-div-left">
                  <h4>
                    Name: <span>{newUserData?.name}</span>
                  </h4>
                  <h4>
                    Email ID: <span>{newUserData?.email}</span>
                  </h4>
                </div>
                <div className="content-div-right">
                  <h4>
                    Application ID: <span>{newUserData?.applicationid}</span>
                  </h4>
                  <h4>
                    Contact No: <span>+91 {newUserData?.phone}</span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="content-div-row">
              <div className="content-div-element card-1">
                <div className="content-div-left">
                  <h4>
                    Qualifying Exam Rank: <span>{newUserData?.examRank} ({newUserData?.examType})</span>
                  </h4>
                  <h4>
                    Allotment Category:{" "}
                    <span>{newUserData?.allotmentCategory}</span>
                  </h4>
                </div>
                <div className="content-div-right">
                  <h4>
                    Alloted Branch: <span>{newUserData?.allotedBranch}</span>
                  </h4>
                  <h4>
                    Category: <span>{newUserData?.category}</span>
                  </h4>
                </div>
              </div>
              <div className="content-div-element card-2">
                <div className="content-div-left">
                  <h4>
                    Gender: <span>{newUserData?.gender}</span>
                  </h4>
                  <h4>
                    Nationality: <span>{newUserData?.nationality}</span>
                  </h4>
                </div>
                <div className="content-div-right">
                  <h4>
                    DOB: <span>{newUserData?.dob}</span>
                  </h4>
                  <h4>
                    State of Eligibility:{" "}
                    <span>{newUserData?.stateofeligibility}</span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="content-div-row">
              <div className="content-div-element card-3">
                <div className="content-div-left">
                  <h4>
                    Father Name: <span>{newUserData?.fathername}</span>
                  </h4>
                  <h4>
                    Religion: <span>{newUserData?.religion}</span>
                  </h4>
                </div>
                <div className="content-div-right">
                  <h4>
                    Mother Name: <span>{newUserData?.mothername}</span>
                  </h4>
                  <h4>
                    Annual Income: <span>{newUserData.annualIncome}</span>
                  </h4>
                </div>
              </div>
              <div className="content-div-element card-4">
                <div className="content-div-left">
                  <h4>
                    Batch: <span>{newUserData?.batch}</span>
                  </h4>
                  <h4>
                    Type: <span>{newUserData?.type}</span>
                  </h4>
                </div>
                <div className="content-div-right">
                  <h4>
                    Program: <span>{newUserData?.program}</span>
                  </h4>
                  <h4>
                    Pwd: <span>{newUserData?.pwd ? "YES" : "NO"}</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mid-div">
        <PaySectionNew />
      </div>
      <div className="mid-div">
        {!newUserData?.completed ? <div className="admission-form">
          <div className="admission-form-title">
            <h2>Admission Form</h2>
          </div>
          {isPersonalFilesRejected || isOtherFilesRejected ? (<p style={{ color: 'red' }}>*Oops! Your documents has been Rejected. Please re-upload all the files in {isPersonalFilesRejected && isOtherFilesRejected ? "step 2 and step 3." : isPersonalFilesRejected ? "step 2." : isOtherFilesRejected ? "step 3." : ""}</p>) : null}
          <div className="admission-form-content">
            <div className="admission-form-menu">
              <div className="form-menu-element">
                <h3>Personal Details</h3>
                <button
                  className={`step ${personalDetails ? "finished" : `${step === 0 && "active"}`}`}
                  type="button"
                  onClick={() => setStep(0)}
                >
                  1
                </button>
              </div>
              <div className="form-menu-element">
                <h3>Personal Documents</h3>
                <button
                  className={`step ${personalFiles ? "finished" : `${step === 0 && "active"}`}`}
                  type="button"
                  onClick={() => setStep(1)}
                >
                  2
                </button>
              </div>
              <div className="form-menu-element">
                <h3>Other Documents</h3>
                <button
                  className={`step ${otherDetails ? "finished" : `${step === 0 && "active"}`}`}
                  type="button"
                  onClick={() => setStep(2)}
                >
                  3
                </button>
              </div>
            </div>
            <div className="admission-form-fields">
              {step === 0 && (
                <div className="form-fields-element">
                  <form onSubmit={handleSubmit(detailsSubmit)}>
                    <div className="form-container">
                      <div className="form-groups-row">
                        <div className="form-group left-group">
                          <label htmlFor="aadhaarNum" className="required">
                            Aadhaar Number
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="aadhaarNum"
                            value={newUserData?.aadhaar}
                            placeholder="Enter Aadhaar number"
                            required
                            {...register("aadhar", { required: true })}
                          />
                        </div>
                        <div className="form-group right-group">
                          <label htmlFor="bloodGroup">Blood Group</label>
                          <select
                            className="form-control"
                            id="bloodGroup"
                            placeholder="Enter blood group"
                            {...register("bloodgroup")}
                          >
                            <option value={"NA"}>Select Blood Group</option>
                            <option value={"A+"}>A+</option>
                            <option value={"A-"}>A-</option>
                            <option value={"B+"}>B+</option>
                            <option value={"B-"}>B-</option>
                            <option value={"AB+"}>AB+</option>
                            <option value={"AB-"}>AB-</option>
                            <option value={"O+"}>O+</option>
                            <option value={"O-"}>O-</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-groups-row">
                        <div className="form-group left-group">
                          <label htmlFor="permanentAdd" className="required">
                            Permanent Address
                          </label>
                          <input
                            type="address"
                            className="form-control"
                            id="permanentAdd"
                            placeholder="Street/Village, City/District, State, Pincode"
                            name="paddress"
                            required
                            {...register("paddress", { required: true })}
                          />
                        </div>
                        <div className="form-group right-group">
                          <label htmlFor="contactAdd" className="required">
                            Contact Address
                          </label>
                          <input
                            type="address"
                            className="form-control"
                            id="contactAdd"
                            placeholder="Street/Village, City/District, State, Pincode"
                            required
                            {...register("caddress", { required: true })}
                          />
                        </div>
                      </div>
                      <div className="form-groups-row">
                        <div className="form-group left-group">
                          <label htmlFor="guardianName" className="required">
                            Guardian Name
                          </label>
                          <input
                            type="name"
                            className="form-control"
                            id="guardianName"
                            placeholder="Enter Guardian Name"
                            required
                            {...register("gname", { required: true })}
                          />
                        </div>
                        <div className="form-group right-group">
                          <label htmlFor="guardianRel">Guardian Relation</label>
                          <input
                            type="name"
                            className="form-control"
                            id="guardianRel"
                            placeholder="Write Relation with Guardian"
                            name="grelation"
                            {...register("grelation")}
                          />
                        </div>
                      </div>
                      <div className="form-groups-row">
                        <div className="form-group left-group">
                          <label htmlFor="guardianPhone" className="required">
                            Guardian's Contact
                          </label>
                          <input
                            type="phone"
                            className="form-control"
                            id="guardianPhone"
                            placeholder="Enter Phone Number"
                            required
                            {...register("gcontact", { required: true })}
                          />
                        </div>
                        <div className="form-group right-group">
                          <label htmlFor="guardianEmail">
                            Guardian's Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="guardianEmail"
                            placeholder="Enter email"
                            name="gemail"
                            {...register("gemail")}
                          />
                        </div>
                      </div>
                    </div>
                    <button type="submit" className="primary">
                      Submit & Next
                    </button>
                  </form>
                </div>
              )}

              {step === 1 && (
                <div className="form-fields-element">
                  <form onSubmit={handleSubmit(uploadFiles1)}>
                    <div className="form-container">
                      {fileLoading && (
                        <div className="file-loading">
                          <CircularProgress />
                          <h3>Uploading Files...</h3>
                        </div>
                      )}
                      <div className="form-groups-row">
                        <div className="form-group left-group docs-uploading">
                          <label htmlFor="passportPic" className="required">
                            Passport Picture (for ID Card) (png/jpeg)
                          </label>
                          <span style={{ color: "orange" }}>info: should be in plane background</span>
                          <input
                            type="file"
                            className="custom-file-input"
                            id="passportPic"
                            accept="image/png, image/jpeg"
                            {...register("photo", {
                              required: true,
                              accept: "image/png, image/jpeg",
                            })}
                          />
                          {fileStatus?.photo?.status === "Rejected" && <p style={{ color: "red", marginTop: "0px", fontSize: '14px' }}>Rejected {fileStatus.photo.comment && `(${fileStatus.photo.comment})`}</p>}
                        </div>
                        <div className="form-group right-group docs-uploading">
                          <label htmlFor="signature" className="required">
                            Signature (for ID Card) (png/jpeg)
                          </label>
                          <span style={{ color: "orange" }}>Should be in white background</span>
                          <input
                            type="file"
                            className="custom-file-input"
                            id="signature"
                            accept="image/png, image/jpeg"
                            {...register("signature", {
                              required: true,
                              accept: "image/png, image/jpeg",
                            })}
                          />
                          {fileStatus?.signature?.status === "Rejected" && <p style={{ color: "red", marginTop: "0px", fontSize: '14px' }}>Rejected {fileStatus.signature.comment && `(${fileStatus.signature.comment})`}</p>}
                        </div>
                      </div>
                      <div className="form-groups-row">
                        <div className="form-group left-group docs-uploading">
                          <label htmlFor="allotmentLetter" className="required">
                            Seat Allotment Letter (pdf)
                          </label>
                          <input
                            type="file"
                            className="custom-file-input"
                            id="allotmentLetter"
                            accept="application/pdf"
                            {...register("allotmentletter", {
                              required: true,
                              accept: "application/pdf",
                            })}
                          />
                          {fileStatus?.allotmentletter?.status === "Rejected" && <p style={{ color: "red", marginTop: "0px", fontSize: '14px' }}>Rejected {fileStatus.allotmentletter.comment && `(${fileStatus.allotmentletter.comment})`}</p>}
                        </div>
                        <div className="form-group right-group docs-uploading">
                          <label htmlFor="aadhaarFile" className="required">
                            Aadhaar Card (pdf)
                          </label>
                          <input
                            type="file"
                            className="custom-file-input"
                            id="aadhaarFile"
                            accept="application/pdf"
                            {...register("aadhaar", {
                              required: true,
                              accept: "application/pdf",
                            })}
                          />
                          {fileStatus?.aadhar?.status === "Rejected" && <p style={{ color: "red", marginTop: "0px", fontSize: '14px' }}>Rejected {fileStatus.aadhar.comment && `(${fileStatus.aadhar.comment})`}</p>}
                        </div>
                      </div>
                      <div className="form-groups-row">
                        <div className="form-group left-group docs-uploading">
                          <label htmlFor="xthMarksheet" className="required">
                            Xth Marsheet
                          </label>
                          <input
                            type="file"
                            className="custom-file-input"
                            id="xthMarksheet"
                            accept="application/pdf"
                            {...register("tenth", {
                              required: true,
                              accept: "application/pdf",
                            })}
                          />
                          {fileStatus?.tenth?.status === "Rejected" && <p style={{ color: "red", marginTop: "0px", fontSize: '14px' }}>Rejected {fileStatus.tenth.comment && `(${fileStatus.tenth.comment})`}</p>}
                        </div>
                        <div className="form-group right-group docs-uploading">
                          <label htmlFor="xiithMarsheet" className="required">
                            XIIth Marksheet (pdf)
                          </label>
                          <input
                            type="file"
                            className="custom-file-input"
                            id="xiithMarsheet"
                            accept="application/pdf"
                            {...register("twelfth", {
                              required: true,
                              accept: "application/pdf",
                            })}
                          />
                          {fileStatus?.twelfth?.status === "Rejected" && <p style={{ color: "red", marginTop: "0px", fontSize: '14px' }}>Rejected {fileStatus.twelfth.comment && `(${fileStatus.twelfth.comment})`}</p>}
                        </div>
                      </div>
                    </div>
                    <button disabled={fileLoading} type="submit" style={{ cursor: `${fileLoading ? "not-allowed" : "pointer"}`, opacity: `${fileLoading ? "0.6" : "1"}` }} className="primary">
                      Submit & Next
                    </button>
                  </form>
                </div>
              )}

              {step === 2 && (
                <div className="form-fields-element">
                  <form onSubmit={handleSubmit(uploadFiles2)}>
                    <div className="form-container">
                      {fileLoading2 && (
                        <div className="file-loading">
                          <CircularProgress />
                          <h3>Uploading Files...</h3>
                        </div>
                      )}
                      <div className="form-groups-row">
                        <div className="form-group left-group docs-uploading">
                          <label htmlFor="pwdDoc">PWD Certificate (pdf)</label>
                          <input
                            type="file"
                            className="custom-file-input"
                            id="pwdDoc"
                            accept="application/pdf"
                            {...register("pwd", { accept: "application/pdf" })}
                          />
                          {fileStatus?.pwdCertificate?.status === "Rejected" && <p style={{ color: "red", marginTop: "0px", fontSize: '14px' }}>Rejected {fileStatus.pwdCertificate.comment && `(${fileStatus.pwdCertificate.comment})`}</p>}
                        </div>
                        <div className="form-group right-group docs-uploading">
                          <label htmlFor="mediDoc" className="required">
                            Medical Certificate (pdf)
                          </label>
                          <input
                            type="file"
                            className="custom-file-input"
                            id="mediDoc"
                            accept="application/pdf"
                            required
                            {...register("medical", {
                              required: true,
                              accept: "application/pdf",
                            })}
                            y
                          />
                          {fileStatus?.medicalCertificate?.status === "Rejected" && <p style={{ color: "red", marginTop: "0px", fontSize: '14px' }}>Rejected {fileStatus.medicalCertificate.comment && `(${fileStatus.medicalCertificate.comment})`}</p>}
                        </div>
                      </div>
                      {/* <div className="form-groups-row">
                        <div className="form-group left-group docs-uploading">
                          <label htmlFor="arStudent" className="required">
                            Anti-Ragging by Student (pdf)
                          </label>
                          <input
                            type="file"
                            className="custom-file-input"
                            id="arStudent"
                            accept="application/pdf"
                            required
                            {...register("arStudent", {
                              required: true,
                              accept: "application/pdf",
                            })}
                          />
                        </div>
                        <div className="form-group right-group docs-uploading">
                          <label htmlFor="arParent" className="required">
                            Anti-Ragging by Parent (pdf)
                          </label>
                          <input
                            type="file"
                            className="custom-file-input"
                            id="arParent"
                            accept="application/pdf"
                            required
                            {...register("arParent", {
                              required: true,
                              accept: "application/pdf",
                            })}
                          />
                        </div>
                      </div> */}
                      <div className="form-groups-row">
                        <div className="form-group left-group docs-uploading">
                          <label htmlFor="provisionalDoc" className="required">
                            Provisional document verification letter (pdf)
                          </label>
                          <input
                            type="file"
                            className="custom-file-input"
                            id="provisionalDoc"
                            accept="application/pdf"
                            {...register("provisionalDoc", {
                              accept: "application/pdf",
                            })}
                          />
                          {fileStatus?.provisionalDocVerificationLetter?.status === "Rejected" && <p style={{ color: "red", marginTop: "0px", fontSize: '14px' }}>Rejected {fileStatus.provisionalDocVerificationLetter.comment && `(${fileStatus.provisionalDocVerificationLetter.comment})`}</p>}
                        </div>
                        <div className="form-group right-group docs-uploading">
                          <label htmlFor="appInLew">
                            Category Certificate (EWS/OBC/SC/ST) (pdf)
                          </label>
                          <input
                            type="file"
                            className="custom-file-input"
                            id="appInLew"
                            accept="application/pdf"
                            placeholder="choose file"
                            {...register("appInLew", {
                              accept: "application/pdf",
                            })}
                          />
                          {fileStatus?.application?.status === "Rejected" && <p style={{ color: "red", marginTop: "0px", fontSize: '14px' }}>Rejected {fileStatus.application.comment && `(${fileStatus.application.comment})`}</p>}
                        </div>
                      </div>
                    </div>
                    <button disabled={fileLoading2} type="submit" style={{ cursor: `${fileLoading2 ? "not-allowed" : "pointer"}`, opacity: `${fileLoading2 ? "0.6" : "1"}` }} className="primary">
                      Final Submit
                    </button>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
          :
          <div className="admission-form">
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
              <h4>You have successfully completed your onboarding process!! Check your mail for the further process...</h4>
            </div>
          </div>
        }
      </div>

      <div className="bottom-div">
        {newUserData?.applicationid && <PaymentHistoryNew />}
      </div>
    </>
  );
};

export default AdmissionPage;