import React from "react";

const BonafiedGeneration = ({
  update,
  applySemester,
  state,
  student,
  date,
  arrayBufferToBase64,
}) => {
  return (
    <div className="" style={{backgroundColor: 'white'}}>
      <div className="bonafide-generated-head">
        <img src="/assets/heading-banner.jpg" alt="bonafide-header" />
      </div>
      <div className="horz-line"></div>
      <div className="bonafide-generated-contacts">
        <h4>
          <i className="fa-solid fa-location-dot"></i> IIIT Bhagalpur Campus,
          Sabour, Bhagalpur, Bihar - 813210
        </h4>
        <h4>
          <i class="fa-solid fa-envelope"></i> ar.academic@iiitbh.ac.in
        </h4>
      </div>
      <div className="horz-line"></div>
      <div className="reference-id">
        <strong>Ref No.: IIITBH/ACAD/BON/{update?.refNo}</strong>
        <strong>
          Date: {date?.getDate()}/{date?.getMonth() + 1}/{date?.getFullYear()}
        </strong>
      </div>
      <div className="bonafide-generated-heading">
        <h3>BONAFIDE CERTIFICATE</h3>
      </div>
      <div className="pic">
        <img
          src={`data:image/png;base64,${arrayBufferToBase64(
            student?.picture.data
          ).toString("base64")}`}
          alt="bonafide-pic"
        />
      </div>
      {
        update?.purpose === "National Scholorship via NSP" ? (
          <>
          <div className="body-1">
            <p>
              This is to certify that Mr./Ms. <strong>{student?.name}</strong>, son/daughter of
              Mr. <strong> {student?.fathersname}</strong>, Date of Birth <strong>{student?.dob}</strong> is a bonafide student of 
              <strong> Indian Institute of Information Technology Bhagalpur (U-0953), Ranitalab, Sabour, Bhagalpur, BIHAR-813210. </strong>
            </p>
          </div>
          <div className="body-2">
            <p>
              {student?.gender === "Male" ? "He" : "She"} is bearing Roll No. <strong>{student?.roll}</strong> and is studying in <strong>{student?.course}</strong> (<strong>{student?.branch}</strong>) in <strong>{student?.year}{student?.year === '1' ? 'st' : student?.year === '2' ? 'nd' : student?.year === '3' ? 'rd' : 'th'}</strong> year in the current Academic Year <strong>{student?.session}</strong>.
            </p>
          </div>
          <div className="body-2">
            <p>
              As per the record {student?.gender === "Male" ? "his" : "her"} domicile state is <strong>{state}</strong>.
            </p>
          </div>
          </>
        ) : (
          <>
          <div className="body-1">
            <p>
              This is to certify that Mr./Ms. <strong>{student?.name}</strong>,
              Institute Roll No: <strong>{student?.roll}</strong>, son/daughter of
              Mr. <strong> {student?.fathersname}</strong>, Date of Birth <strong>{student?.dob}</strong> is a bonafide
              <strong> {student?.course}</strong> student of this Institute (Batch:
              <strong> {student?.batch}</strong>), and enrolled in the
              <strong> {student?.branch}</strong> branch.
            </p>
          </div>
          <div className="body-2">
            <p>
              Presently, he is studying in the Semester{" "}
              <strong>{student?.semester}</strong> (Academic Year: {student?.session}) {applySemester && applySemester !== student?.semester && (<span>is and eligible for registering in Semester <strong>{applySemester}</strong></span>)}.
            </p>
          </div>
          </>
        )
      }
      <div className="body-3">
        <p>
          The Certificate is issued to enable the student to apply for{" "}
          <span className="underline">{update?.purpose}</span> at his/her own
          request.
        </p>
      </div>
      <div className="body-4">
        <p>
          <strong>
            Assistant Registrar
            <br /> (Academics)
          </strong>
        </p>
      </div>
    </div>
  );
};

export default BonafiedGeneration;
