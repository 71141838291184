import * as React from "react";
import { useParams } from "react-router-dom";
import "../css/Page/LogInPage.css";
import StudentLogin from "../components/StudentLogin";
import AdminLogin from "../components/AdminLogin";
import TermsAndConditionSBI from "../components/TermsAndConditionSBI";

import { useNavigate } from "react-router-dom";
import HappyNewYear from "../components/HappyNewYear";

const LogInPage = () => {
  const { user } = useParams();

  const navigate = useNavigate();

  function OpenTerms() {
    setOpen(true);
  }
  function PremLinkedin() {
    window.open("https://www.linkedin.com/in/prem-kr");
  }
  function PrateekLinkedin() {
    window.open("https://www.linkedin.com/in/its-prateek");
  }
  function IIITBHSite() {
    window.open("https://iiitbh.ac.in/");
  }

  const [open, setOpen] = React.useState(false);

  // React.useEffect(() => {
  //   const seenTerm = localStorage.getItem("seenTerm");
  //   if (!seenTerm) {
  //     setOpen(true);
  //     localStorage.setItem("seenTerm", "true");
  //   }
  // }, []);

  return (
    <>
      <TermsAndConditionSBI open={open} onClose={() => setOpen(false)} />
      <div className="LoginPage">
        <div className="login-section">
          <div className="exist-account">
            <div className="login-box">
              {user === "admin" ? <AdminLogin /> : <StudentLogin />}
              {/* <AdminLogin /> */}
            </div>
          </div>
          <div className="new-admission">
            <div className="new-admission-title">
              <h2>New Admission ?</h2>
              <p>(Welcome onboard with us!)</p>
            </div>
            <div className="new-admission-button">
              <button onClick={() => navigate("/newstudentregistration")}>
                Initiate Registration Now
                <i className="fa-solid fa-circle-arrow-right"></i>
              </button>
            </div>
            {/* <HappyNewYear /> */}
          </div>
        </div>

        <div className="left-footer-dock">
          <div className="dock-contents">
            <div className="dock-content">
              <h4>Mrs. Abhilasa Bharti</h4>
              <p>
                <i className="fa-solid fa-phone"></i> +91 9508537002
              </p>
              <p>
                <i className="fa-solid fa-envelope"></i>{" "}
                abhilasha.ar@iiitbh.ac.in
              </p>
            </div>
            <div className="dock-content">
              <h4>Dr. Chandan Kumar Jha</h4>
              <p>
                <i className="fa-solid fa-phone"></i> +91 7903311768
              </p>
              <p>
                <i className="fa-solid fa-envelope"></i>{" "}
                ug.academic@iiitbh.ac.in
              </p>
            </div>
            <div className="dock-content">
              <h4>Dr. Sandeep Raj</h4>
              <p>
                <i className="fa-solid fa-phone"></i> +91 9386199102
              </p>
              <p>
                <i className="fa-solid fa-envelope"></i>{" "}
                pg.academic@iiitbh.ac.in
              </p>
            </div>
          </div>
          <div className="dock-title">
            <h3>
              <i className="fa-solid fa-phone-volume"></i>
            </h3>
          </div>
        </div>

        <div className="right-footer-dock">
          <div className="dock-title">
            <h3>
              <i className="fa-solid fa-question-circle"></i>
            </h3>
          </div>
          <div className="dock-contents">
            <div className="dock-content right-dock-content">
              <p>
                Designed & developed by <br />
                <span className="para-click" onClick={PremLinkedin}>
                  Prem Kumar
                </span>{" "}
                and{" "}
                <span className="para-click" onClick={PrateekLinkedin}>
                  Prateek Chandra
                </span>
              </p>
            </div>
            <div className="dock-content right-dock-content">
              <p>
                Maintained & Managed by <br />
                <span className="para-click" onClick={IIITBHSite}>
                  Indian Institute of Information Technology Bhagalpur
                </span>
              </p>
            </div>
            <div className="dock-content right-dock-content">
              <p className="para-click" onClick={OpenTerms}>
                <span>
                  Terms & Conditions
                  <br />
                </span>
                (Click to open)
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LogInPage;
