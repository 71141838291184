import React from "react";
import "../css/Page/Documents.css";

import Header from "../components/Header";
import LatestDOC from "../components/LatestDOC";
import FormsDocs from "../components/FormsDocs";

function Documents() {
  return (
    <>
      <Header />
      <div className="top-div">
        <LatestDOC />
      </div>
      <div className="bottom-div">
        <FormsDocs />
      </div>
    </>
  );
}

export default Documents;
