import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Apis from "../apis/apis";
import AdminHeader from "../components/AdminHeader";
import "../css/Page/UploadMarks.css";
import CircularProgress from "@mui/material/CircularProgress";
import Papa from "papaparse";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const UploadMarks = () => {
  let { id } = useParams();
  const courseId = id.split("__")[0];
  const session = id.split("__")[1];

  const [courseDetails, setCourseDetails] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [courseStudentList, setCourseStudentList] = React.useState([]);
  const [isUnsaved, setIsUnsaved] = React.useState(false);

  React.useEffect(() => {
    const getCourseDetails = async () => {
      await Promise.resolve(Apis.courseById(courseId))
        .then((data) => {
          setCourseDetails(data.course);
          setLoading(false);
        })
        .catch((e) => {
          setError(true);
          setLoading(false);
        });
    };
    getCourseDetails();
  }, [courseId]);

  const [cnt, setCnt] = React.useState(0);
  React.useEffect(() => {
    const getCourseStudentList = async () => {
      await Promise.resolve(Apis.courseStudents(id))
        .then((data) => {
          let i = 0;
          setCourseStudentList(data);
          data.forEach((student) => {
            i += Number(student.reviewLevel);
          });
          setCnt(i);
          setLoading(false);
        })
        .catch((e) => {
          setError(true);
          setLoading(false);
        });
    };
    getCourseStudentList();
  }, [id]);

  const [fileName, setFileName] = React.useState("");

  // const randomIntFromInterval = (min, max) => {
  //   return Math.floor(Math.random() * (max - min + 1) + min);
  // };

  const updateStudentMarksFunc = async () => {
    setLoading(true);
    setFileName("");
    // const dummyData = []
    courseStudentList.forEach(async (student) => {
      const data = {
        css: student.css,
        studentRoll: student.studentRoll,
        midSem: student.midSem,
        endSem: student.endSem,
        attendance: student.attendance,
        others: student.others,
      };
      // const dummy = {
      //   "Roll No.": student.studentRoll,
      //   "Mid Sem": randomIntFromInterval(0, 30),
      //   "End Sem": randomIntFromInterval(0, 50),
      //   "Attendance": randomIntFromInterval(0, 5),
      //   "Others": randomIntFromInterval(0, 15),
      // }
      // dummyData.push(dummy)
      await Promise.resolve(Apis.updateStudentMarks(data))
        .then((data) => {
          setLoading(false);
          setIsUnsaved(true);
        })
        .catch((e) => {
          setError(true);
          setLoading(false);
          setIsUnsaved(true);
        });
    });
    // console.log(dummyData);
  };

  const handleForwardToHOD = async () => {
    setLoading(true);
    courseStudentList.forEach(async (student) => {
      const data = {
        css: student.css,
        studentRoll: student.studentRoll,
        reviewLevel: "2",
      };
      await Promise.resolve(Apis.updateLevel(data)).then((data) => {
        setLoading(false);
        setIsUnsaved(true);
      });
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      console.log("No file selected");
      return;
    }

    setFileName(file.name);

    Papa.parse(file, {
      header: true,
      complete: function (results) {
        results.data.forEach((student) => {
          // console.log(student["Roll No."]);
          const index = courseStudentList.findIndex(
            (item) => item.studentRoll === student["Roll No."]
          );
          // console.log(index);
          if (index !== -1) {
            courseStudentList[index].attendance = Number(student["Attendance"]);
            courseStudentList[index].endSem = Number(student["End Sem"]);
            courseStudentList[index].midSem = Number(student["Mid Sem"]);
            courseStudentList[index].others = Number(student["Others"]);
          }
        });
        setCourseStudentList([...courseStudentList]);
        // console.log(results.data);
      },
      error: function (err) {
        console.log("Error parsing CSV: ", err);
      },
    });
  };

  const handleDeselctFile = () => {
    setFileName("");
  };

  return (
    <>
      <AdminHeader />

      <div style={{position: 'relative', padding: "0px 50px"}}>
        <div
          onClick={() => {
            window.history.back();
          }}
          className="backOption"
        >
          <ArrowBackIosIcon className="backArrow" fontSize="smaller" />
          <div className="">Back</div>
        </div>
        <div className="courseContainer">
          {courseDetails && (
            <table className="courseTable">
              <thead className="courseTable-head">
                <tr>
                  <th>Course Name</th>
                  <th>Course Code</th>
                  <th>Semester</th>
                  <th>L</th>
                  <th>T</th>
                  <th>P</th>
                  <th>C</th>
                </tr>
              </thead>
              <tbody className="courseTable-body">
                <tr>
                  <td>{courseDetails.name}</td>
                  <td>{courseDetails.code}</td>
                  <td>{courseDetails.semester}</td>
                  <td>{courseDetails.l}</td>
                  <td>{courseDetails.t}</td>
                  <td>{courseDetails.p}</td>
                  <td>{courseDetails.c}</td>
                </tr>
              </tbody>
            </table>
          )}
          <button
            className={`${
              cnt === courseStudentList.length ||
              cnt === 2 * courseStudentList.length ||
              cnt === 3 * courseStudentList.length
                ? "primary-btn forward"
                : "backward"
            }`}
            disabled={
              cnt === courseStudentList.length ||
              cnt === 2 * courseStudentList.length ||
              cnt === 3 * courseStudentList.length
                ? false
                : true
            }
            onClick={handleForwardToHOD}
          >
            Handover to HOD
          </button>
        </div>

        <div className="tableContainer">
          <table className="uploadMarks-table">
            <thead className="uploadMarks-head">
              <tr className="uploadMarks-head-row">
                <th className="first" style={{ width: "5%" }}>
                  S.No.
                </th>
                <th style={{ width: "12%" }}>Roll No.</th>
                <th style={{ width: "20%" }}>Name</th>
                <th style={{ width: "15%" }}>Status</th>
                <th style={{ width: "9%" }}>MidSem</th>
                <th style={{ width: "9%" }}>EndSem</th>
                <th style={{ width: "9%" }}>Attendance</th>
                <th style={{ width: "9%" }}>Others</th>
                <th style={{ width: "12%" }} className="last">
                  Total
                </th>
              </tr>
            </thead>
            <tbody className="uploadMarks-tbody">
              {loading ? (
                <div className="loading">
                  <CircularProgress />
                </div>
              ) : (
                courseStudentList &&
                courseStudentList.map((student, index) => (
                  <tr key={student.studentId}>
                    <td>{index + 1}</td>
                    <td>{student.studentRoll}</td>
                    <td>{student.name}</td>
                    <td>{student.status}</td>
                    <td>
                      <input
                        type="number"
                        placeholder={
                          student.midSem === 0 ? "0" : student.midSem
                        }
                        value={
                          student.midSem === 0
                            ? ""
                            : student.midSem > 30
                            ? 30
                            : student.midSem
                        }
                        max={30}
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => {
                          let updatedList = [...courseStudentList];
                          let updatedStudent = updatedList.find(
                            (s) => s.studentId === student.studentId
                          );
                          if (updatedStudent) {
                            updatedStudent.midSem = Number(
                              e.target.value > 30 ? 30 : e.target.value
                            );
                          }
                          setCourseStudentList(updatedList);
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        placeholder={
                          student.endSem === 0 ? "0" : student.endSem
                        }
                        value={
                          student.endSem === 0
                            ? ""
                            : student.endSem > 50
                            ? 50
                            : student.endSem
                        }
                        max={50}
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => {
                          let updatedList = [...courseStudentList];
                          let updatedStudent = updatedList.find(
                            (s) => s.studentId === student.studentId
                          );
                          if (updatedStudent) {
                            updatedStudent.endSem = Number(
                              e.target.value > 50 ? 50 : e.target.value
                            );
                          }
                          setCourseStudentList(updatedList);
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        placeholder={
                          student.attendance === 0 ? "0" : student.attendance
                        }
                        value={
                          student.attendance === 0
                            ? ""
                            : student.attendance > 5
                            ? 5
                            : student.attendance
                        }
                        max={5}
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => {
                          let updatedList = [...courseStudentList];
                          let updatedStudent = updatedList.find(
                            (s) => s.studentId === student.studentId
                          );
                          if (updatedStudent) {
                            updatedStudent.attendance = Number(
                              e.target.value > 5 ? 5 : e.target.value
                            );
                          }
                          setCourseStudentList(updatedList);
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        placeholder={
                          student.others === 0 ? "0" : student.others
                        }
                        value={
                          student.others === 0
                            ? ""
                            : student.others > 15
                            ? 15
                            : student.others
                        }
                        max={15}
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => {
                          let updatedList = [...courseStudentList];
                          let updatedStudent = updatedList.find(
                            (s) => s.studentId === student.studentId
                          );
                          if (updatedStudent) {
                            updatedStudent.others = Number(
                              e.target.value > 15 ? 15 : e.target.value
                            );
                          }
                          setCourseStudentList(updatedList);
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={
                          student.midSem +
                          student.endSem +
                          student.attendance +
                          student.others
                        }
                        readOnly
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="bottom_section_part">
          <div className="">
            <input
              type="file"
              name="upload_csv_file"
              id="upload_csv_file"
              hidden
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onChange={handleFileChange}
            />
            <label htmlFor="upload_csv_file" className="upload_button">
              {fileName ? fileName : "Upload .csv file"}
            </label>
          </div>
          <button className="primary-btn" onClick={updateStudentMarksFunc}>
            Save Changes
          </button>
        </div>
      </div>
    </>
  );
};

export default UploadMarks;
