import FlipCountdown from "@rumess/react-flip-countdown";
import React, { useEffect, useState } from "react";
import { AuthProvider } from "../auth/AuthContext";
import apis from "../apis";

const HostelApplication = ({
  hostelStatus,
  hostelApplication,
  setHostelApplication,
  applyHostel,
}) => {
  const { userData } = AuthProvider();

  const [ws, setWs] = useState(null);
  const [timer, setTimer] = useState(null);
  const [type, setType] = useState("HOSTEL");

  useEffect(() => {
    const protocol = window.location.protocol === "https:" ? "wss://" : "ws://";
    const hostname = window.location.hostname;
    const port = process.env.WEBSOCKET_PORT || 8080;
    const url = `${protocol}${hostname}:${port}`;
    const socket = new WebSocket(url);
    setWs(socket);

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      let schedularType = "HOSTEL";

      if (data === null) {
      }
      if (
        data.type === `${schedularType}_TIMER_UPDATE` ||
        data.type === `STOP_${schedularType}_TIMER` ||
        data.type === `SET_${schedularType}_TIMER` ||
        data.type === `UPDATE_${schedularType}_TIMER`
      ) {
        if (data.type === `STOP_${schedularType}_TIMER`) {
        }

        if (data.data) {
          if (data.data !== null) {
            let newDate = data.data.endTime;
          }
        }
        setTimer(data.data);
      }
    };

    // return () => {
    //   socket.close();
    // };
  }, []);


  useEffect(() => {
    if (userData && Object.keys(userData).length > 0) {
      if (timer) {
        if (
          !timer.openFor.includes("All") &&
          !timer.openFor.includes(userData.roll)
        ) {
          setTimer(null);
        }
      }
    }
  }, [userData, timer]);

  return (
    <div className="upload-items">
      <div
        className="upload-title"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h4>
          Hostel Application:{" "}
          <span>
            {" "}
            To opt for hostel accommodation at IIIT Bhagalpur for the Spring
            semester 2023–24
          </span>
        </h4>
        {timer && (
          <div
            className=""
            style={{ width: "100%", margin: "10px auto", flex: "1 1" }}
          >
            <FlipCountdown
              hideYear
              hideMonth
              endAt={timer.endTime}
              onTimeUp={() => {
                alert("Time Up. Please contact the hostel office.");
              }}
              size={"small"}
              theme={"dark"}
            />
          </div>
        )}
      </div>

      <div className="hostelApplication">
        {timer ? (
          <div className="upload-buttons">
            <label htmlFor="OptHostel">Want Hostel Accommodation?:</label>
            <select
              id="OptHostel"
              required
              value={hostelApplication?.optHostel}
              disabled={timer === null}
              onChange={(e) => {
                setHostelApplication((r) => {
                  return {
                    ...r,
                    optHostel: e.target.value === "true" ? true : false,
                  };
                });
              }}
            >
              <option value="">Select the option</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
            <label htmlFor="prefHostel">Preferred Hostel:</label>
            <select
              id="prefHostel"
              required
              value={hostelApplication?.prefHostel}
              disabled={!hostelApplication?.optHostel || timer === null}
              onChange={(e) => {
                setHostelApplication((r) => {
                  return { ...r, prefHostel: e.target.value };
                });
              }}
            >
              <option value="">Select Hostel</option>
              {userData.gender === "Male" ? (
                <>
                  <option value="H1">H1</option>
                  <option value="KBH">Kautilya boys Hostel</option>
                </>
              ) : (
                <>
                  <option value="KGH">Kadambini Girls Hostel</option>
                </>
              )}
            </select>
            {timer ? (
              <button
                id="submit"
                type="submit"
                disabled={hostelStatus?.status === "Pending" && true}
                onClick={() => applyHostel()}
              >
                Apply now
              </button>
            ) : (
              <button style={{ backgroundColor: "red" }}>Time Out</button>
            )}
          </div>
        ) : hostelStatus?.status !== "Not Applied" ? (
          <div className="upload-title">
            <h4 style={{ marginTop: "0px", color: "#5a6dff" }}>
              <span>
                You have successfully completed hostel application process,
                below is your status
              </span>
            </h4>
          </div>
        ) : (
          <div className="upload-buttons">
            <label htmlFor="OptHostel">Want Hostel Accommodation?:</label>
            <select
              id="OptHostel"
              required
              value={hostelApplication?.optHostel}
              disabled={timer === null}
              onChange={(e) => {
                setHostelApplication((r) => {
                  return {
                    ...r,
                    optHostel: e.target.value === "true" ? true : false,
                  };
                });
              }}
            >
              <option value="">Select the option</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
            <label htmlFor="prefHostel">Preferred Hostel:</label>
            <select
              id="prefHostel"
              required
              value={hostelApplication?.prefHostel}
              disabled={!hostelApplication?.optHostel || timer === null}
              onChange={(e) => {
                setHostelApplication((r) => {
                  return { ...r, prefHostel: e.target.value };
                });
              }}
            >
              <option value="">Select Hostel</option>
              {userData.gender === "Male" ? (
                <>
                  <option value="H1">H1</option>
                  <option value="KBH">Kautilya boys Hostel</option>
                </>
              ) : (
                <>
                  <option value="KGH">Kadambini Girls Hostel</option>
                </>
              )}
            </select>
            {timer ? (
              <button
                id="submit"
                type="submit"
                disabled={hostelStatus?.status === "Pending" && true}
                onClick={() => applyHostel()}
              >
                Apply now
              </button>
            ) : (
              <button style={{ backgroundColor: "red" }}>Time Out</button>
            )}
          </div>
        )}

        <div id="hostel-status" className="request-details">
          <h4>
            Status: <span>{hostelStatus?.status}</span>
          </h4>
          <h4>
            Alloted Hostel: <span>{hostelStatus?.allotedHostel}</span>
          </h4>
          <h4>
            Alloted Room: <span>{hostelStatus?.room}</span>
          </h4>
          {/* <h4>
                    Can pay Hostel & Mess fee:{" "}
                    <span>{hostelStatus?.canPay ? "Yes" : "No"}</span>
                  </h4> */}
        </div>
      </div>
    </div>
  );
};

export default HostelApplication;
