import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import AdminHeader from "../components/AdminHeader";
import LaunchIcon from "@mui/icons-material/Launch";
import Apis from "../apis/apis";
import { AuthProvider } from "../auth/AuthContext";
import HodData from "../components/HodData";
import "../css/Page/MyCourses.css";

const MyCourses = () => {
  const { adminData } = AuthProvider();

  const [sessionVal, setSessionVal] = React.useState("");
  const [filterAttribute, setFilterAttribute] = React.useState({
    session: "",
    program: "All",
    semester: "None",
  });

  const generateSessionList = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const sessionList = [];
    for (let i = currentYear; i >= 2017; i--) {
      if (currentMonth < 6 && i === currentYear) continue;
      let nextYear = (i + 1).toString().slice(-2);
      sessionList.push(`${i}-${nextYear}`);
    }
    return sessionList;
  };

  useEffect(() => {
    generateSessionList();
    setSessionVal(generateSessionList()[0]);
  }, []);

  // const [courses, setCourses] = React.useState([]);
  const [courseData, setCourseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const datas = [];

  const getCourseData = async (courses) => {
    if (courses.length > 0) {
      setLoading(true);
      // Use Promise.all to wait for all promises to resolve
      await Promise.all(
        courses.map(async (e) => {
          try {
            const data = await Apis.courseById(e.CourseId);
            // Add data.course to datas array
            datas.push({
              ...data.course,
              session: e.Session,
            });
            // console.log(data);
          } catch (error) {
            console.error(
              `Error fetching course data for CourseId ${e.CourseId}: ${error.message}`
            );
          }
        })
      );
      await setCourseData(datas);
      setLoading(false);
    }
  };

  useEffect(() => {
    const data = Promise.resolve(Apis.myCourses()).then((data) => {
      getCourseData(data);
    });
  }, []);

  const [filteredCourses, setFilteredCourses] = useState([]);

  useEffect(() => {
    let filtered = courseData;

    if (sessionVal !== "") {
      filtered = filtered.filter((course) => course.session === sessionVal);
    }

    if (filterAttribute.program !== "" && filterAttribute.program !== "All") {
      filtered = filtered.filter(
        (course) => course.course === filterAttribute.program
      );
    }

    if (
      filterAttribute.semester !== "" &&
      filterAttribute.semester !== "None"
    ) {
      filtered = filtered.filter(
        (course) => course.semester.toString() === filterAttribute.semester
      );
    }

    // Filter out duplicates based on 'code' property
    const uniqueFiltered = Array.from(
      new Set(filtered.map((course) => course.code))
    ).map((code) => {
      return filtered.find((course) => course.code === code);
    });

    setFilteredCourses(uniqueFiltered);
  }, [courseData, filterAttribute]);

  const navigate = useNavigate();

  return (
    <>
      <AdminHeader />
      {adminData &&
        adminData.role &&
        adminData.role.map((e, i) => {
          return e === "HOD" && <HodData key={i} />;
        })}
      <div className="top-div">
        <div className="hodContainer">
          <div className="title cv">
            <div className="">
              <h2>My Courses:</h2>
            </div>
            <div className="filter-item2">
              <h4>Filter by:</h4>
              <div className="filterAttribute">
                <p>Session</p>
                <select
                  defaultValue={sessionVal}
                  onChange={(e) => {
                    setSessionVal(e.target.value);
                    setFilterAttribute((e1) => {
                      return { ...e1, session: e.target.value };
                    });
                  }}
                >
                  {generateSessionList().map((e, i) => {
                    return (
                      <option key={i} value={e}>
                        {e}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="filterAttribute">
                <p>Program</p>
                <select
                  onChange={(e) => {
                    setFilterAttribute((e1) => {
                      return { ...e1, program: e.target.value };
                    });
                  }}
                >
                  <option value="All">All</option>
                  <option value="B.TECH.">B.Tech</option>
                  <option value="M.TECH.">M.Tech</option>
                  <option value="Ph.D.">PhD</option>
                </select>
              </div>
              <div className="filterAttribute">
                <p className="">Semester</p>
                <select
                  onChange={(e) => {
                    setFilterAttribute((e1) => {
                      return { ...e1, semester: e.target.value };
                    });
                  }}
                >
                  {filterAttribute.program === "B.TECH." ? (
                    <>
                      <option value="None">None</option>
                      <option value="1">1st</option>
                      <option value="2">2nd</option>
                      <option value="3">3rd</option>
                      <option value="4">4th</option>
                      <option value="5">5th</option>
                      <option value="6">6th</option>
                      <option value="7">7th</option>
                      <option value="8">8th</option>
                    </>
                  ) : filterAttribute.program === "M.TECH." ? (
                    <>
                      <option value="None">None</option>
                      <option value="1">1st</option>
                      <option value="2">2nd</option>
                      <option value="3">3rd</option>
                      <option value="4">4th</option>
                    </>
                  ) : filterAttribute.program === "Ph.D." ? (
                    <>
                      <option value="None">None</option>
                      <option value="1">1st</option>
                      <option value="2">2nd</option>
                      <option value="3">3rd</option>
                      <option value="4">4th</option>
                      <option value="5">5th</option>
                      <option value="6">6th</option>
                      <option value="7">7th</option>
                      <option value="8">8th</option>
                      <option value="9">9th</option>
                      <option value="10">10th</option>
                    </>
                  ) : null}
                </select>
              </div>
            </div>
          </div>
          <div className="status-div">
            <table className="myCourse_table">
              <thead className="myCourse_head">
                <tr>
                  <th>S.No.</th>
                  <th>Course Name</th>
                  <th>Course Code</th>
                  <th>Student Data</th>
                </tr>
              </thead>
              <tbody className="myCourse_body">
                {loading ? (
                  <tr>
                    <td colSpan="4">Loading...</td>
                  </tr>
                ) : (
                  filteredCourses && filteredCourses.length > 0 && filteredCourses.map((e, i) => {
                    return (
                      <tr
                        key={`${e.id}__${e.session}__${e.semester.toString()}`}
                      >
                        <td>{i + 1}</td>
                        <td>{e.name}</td>
                        <td>{e.code}</td>
                        <td>
                          <LaunchIcon
                            onClick={() => {
                              navigate(
                                `/student/admin/my-courses/${e.id}__${
                                  e.session
                                }__${e.semester.toString()}`
                              );
                            }}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyCourses;
