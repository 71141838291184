import React from "react";
import "../css/Component/Actions.css";
import { Link, useNavigate } from "react-router-dom";
import apis from "../apis";
import { AuthProvider } from "../auth/AuthContext";

function Actions() {
  const { userData } = AuthProvider();
  const navigate = useNavigate();

  function handleClick() {
    navigate("/student/documents");
  }

  function payNow() {
    navigate("/student/feepayment");
  }
  function RegisterNow() {
    navigate("/student/register");
  }

  function checkResultPage() {
    navigate("/student/result");
  }

  function fillFeedback() {
    navigate("/student/feedback");
  }

  function openbankdetailpage() {
    navigate("/student/bankdetails");
  }

  const [show, setShow] = React.useState(false);

  const getFeedbackPageStatus = async () => {
    try {
      const queryParameters = {
        student_roll: userData?.roll,
        semester: Number(userData?.semester),
        branch: userData?.branch?.replace(/&/g, "%26"),
        course: userData?.course,
        session: "",
      };

      Promise.resolve(apis.showFeedbackPage(queryParameters)).then((data) => {
        Promise.resolve(apis.isReviewSubmitted(queryParameters)).then((status) => {
          if (data && !status) {
            setShow(true);
          } else {
            setShow(false);
          }
        });
      });
    } catch (error) {
      console.log("Error in getting feedback page status");
      setShow(false);
    }
  };

  React.useEffect(() => {
    getFeedbackPageStatus();
  }, [userData?.roll, userData?.semester, userData?.branch, userData?.course]);

  // const checkReviewStatus = async () => {
  //   const queryData = {
  //     student_roll: userData.roll,
  //     semester: Number(userData?.semester) - 1,
  //     session: prevSession,
  //   };

  //   setLoading(true);
  //   if(userData?.semester === "1") {
  //     setLoading(false);
  //     navigate("/student/register");
  //   }
  //   else{
  //     const status = await apis.isReviewSubmitted(queryData);
  //     console.log(status);
  //     if (!status) {
  //       setLoading(false);
  //     } else {
  //       navigate("/student/register");
  //       setLoading(false);
  //     }
  //   }
  // };

  return (
    <div className="actions-div" style={{ marginBottom: "20px", marginTop: "20px" }}>
      <div className="row">
        <div className="action-div" onClick={payNow}>
          <div className="fee-div">
            <div className="action-title">
              <h1>
                Fee Structure <br />
                and Payment
              </h1>
            </div>
          </div>
          <div className="action-btn">
            <Link to={"/student/feepayment"}>Pay Now</Link>
          </div>
        </div>
        <div className="action-div" onClick={RegisterNow}>
          <div className="register-div">
            <div className="action-title">
              <h1>
                Course and
                <br />
                Registration
              </h1>
            </div>
          </div>
          <div className="action-btn">
            <Link to={"/student/register"}>Complete Registration</Link>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="action-div" onClick={openbankdetailpage}>
          <div className="result-div">
            <div className="action-title">
              <h1>
                Bank <br />
                Details
              </h1>
            </div>
          </div>
          <div className="action-btn">
            <Link to={"/student/bankdetails"}>Bank Details</Link>
          </div>
        </div>
        <div className="action-div" onClick={checkResultPage}>
          <div className="result-div">
            <div className="action-title">
              <h1>
                Check <br />
                Result
              </h1>
            </div>
          </div>
          <div className="action-btn">
            <Link to={"/student/result"}>Check Result</Link>
          </div>
        </div>

      </div>
      <div className="row">
        <div className="action-div" onClick={handleClick}>
          <div className="docs-div">
            <div className="action-title">
              <h1>
                Documents <br />
                and Forms
              </h1>
            </div>
          </div>
          <div className="action-btn">
            <Link to={"/student/documents"}>Open Documents</Link>
          </div>
        </div>
      {show && (
          <div className="action-div" onClick={fillFeedback}>
            <div className="docs-div">
              <div className="action-title">
                <h1>
                  Teacher's <br />
                  Feedback Form
                </h1>
              </div>
            </div>
            <div className="action-btn">
              <Link to={"/student/feedback"}>Open Feedback Form</Link>
            </div>
          </div>
      )}
      </div>
    </div>
  );
}

export default Actions;
