import React from "react";
import "../css/Component/Profile.css";
import { AuthProvider } from "../auth/AuthContext";

const arrayBufferToBase64 = (buffer) => {
  var binary = "";
  var bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => (binary += String.fromCharCode(b)));
  return window.btoa(binary);
};

function Profile() {
  const { userData } = AuthProvider();

  return (
    <div className="left-div">
      <div className="image">
        <div className="img-section">
          <img
            src={`data:image/png;base64,${arrayBufferToBase64(
              userData.picture.data
            ).toString("base64")}`}
            alt="img"
          />
        </div>
        <div className="name-section">
          <h3 style={{fontFamily: "CustomFont"}}>{userData.name}</h3>
        </div>
      </div>
      <div className="details">
        <div className="line-div">
          <img src="/assets/Line-side-bar.svg" alt="line" />
        </div>
        <div className="academic">
          <h4>
            Course: <span>{userData.course}</span>
          </h4>
          <h4>
            Batch: <span>{userData.batch}</span>
          </h4>
          <h4>
            Branch: <span>{userData.branch}</span>
          </h4>
          <h4>
            Roll: <span>{userData.roll}</span>
          </h4>
        </div>
        <div className="line-div">
          <img src="/assets/Line-side-bar.svg" alt="line" />
        </div>
        <div className="contact">
          <h4>
            Phone: <span>+91 {userData.contact}</span>
          </h4>
          <h4>
            Email: <span>{userData.email}</span>
          </h4>
        </div>
        <div className="line-div">
          <img src="/assets/Line-side-bar.svg" alt="line" />
        </div>
        <div className="address">
          <h4>Address:</h4>
          <h4>
            <span>{userData.address}</span>
          </h4>
        </div>
      </div>
    </div>
  );
}

export default Profile;
