import * as React from "react";
import "../css/Component/CourseRegistrationSection.css";
import CourseStructureTable from "./CourseStructureTable";
import { AuthProvider } from "../auth/AuthContext";
import { useNavigate } from "react-router-dom";
import apis from "../apis";

const CourseRegistrationSection = () => {
  const { userData } = AuthProvider();
  const [semester, setSemester] = React.useState("");
  const [courseData, setCourseData] = React.useState({
    code: "",
    name: "",
    l: "",
    t: "",
    p: "",
    c: "",
    type: "",
  });
  const [paymentData, setPaymentData] = React.useState({});
  const navigate = useNavigate();
  React.useEffect(() => {
    const fetchPaymentData = async () => {
      await apis
        .specificStudent({ roll: userData?.roll, semester: userData?.semester })
        .then((data) => {
          setPaymentData(data[0]);
        })
        .catch((err) => {
          alert(err.message);
        });
    };
    fetchPaymentData();
  }, [userData?.roll, userData?.semester]);
  React.useEffect(() => {
    setSemester(userData?.semester);
  }, [userData]);

  const registrationDone = async () => {
    if (paymentData?.paymentStatus !== "Paid") {
      navigate("/student/feepayment");
      alert("Complete the payment Page");
      return;
    }
    if (paymentData?.registrationStatus === "Approved" || paymentData?.registrationStatus === "Registered") {
      alert("Already Applied!!")
      return;
    }
    await Promise.all([apis.submitCourses({ roll: userData?.roll, semester: userData?.semester, batch: userData?.batch, program: userData?.course, branch: userData?.branch }), apis.updateStudent(userData.roll, { registrationStatus: "Pending", semester: userData?.semester })]).then((data) => {
      if (data) {
        window.location.reload();
        alert("Applied for Registration!!")
      }
    }).catch((err) => {
      alert(err.message)
    })
  };

  const addCourse = async () => {
    await apis.addExtraCourse({
      studentId: userData?.roll,
      courseCode: courseData?.code,
      courseName: courseData?.name,
      l: courseData?.l,
      t: courseData?.t,
      p: courseData?.p,
      c: courseData?.c,
      courseType: courseData?.type,
      semester: userData?.semester,
      branch: userData?.branch,
      program: userData?.course,
      batch: userData?.batch,
    }).then((data) => {
      if (data) {
        alert("Course Added!!")
      }
    }).catch((err) => {
      alert(err.message)
    })
  }
  return (
    <>
      <div className="CourseRegistrationSection">
        <div className="course-registration-section">
          <div className="course-registration-section-title">
            <h3>Course Registration:</h3>
          </div>
          <div className="Select-semester">
            <div className="Select-semester-label">
              <label htmlFor="Select-semester-label-semester">
                Select Semester to continue registration:
              </label>
            </div>
            <div className="Select-semester-input">
              <select
                name="Select-semester-input-semester"
                id="Select-semester-input-semester"
                value={semester}
                onChange={(e) => setSemester(e.target.value)}
              >
                <option value="1">1st</option>
                <option value="2">2nd</option>
                <option value="3">3rd</option>
                <option value="4">4th</option>
                <option value="5">5th</option>
                <option value="6">6th</option>
                <option value="7">7th</option>
                <option value="8">8th</option>
              </select>
            </div>
          </div>
          <div className="course-structure">
            <div className="course-structure-table">
              {userData.id && semester && (
                <CourseStructureTable user={userData} semester={semester} />
              )}
            </div>
          </div>
          {/* <div className="choose-electives">
            <div className="choose-electives-title">
              <h3>
                Choose Electives:{" "}
                <span>
                  Refer this (<Link to="/Path"> document </Link>) for more
                  details
                </span>
              </h3>
            </div>
            <div className="choose-preferences">
              <div className="preference">
                <div className="preference-label">
                  <label htmlFor="preference-label-preference">
                    Select Elective I:
                  </label>
                </div>
                <div className="preference-input">
                  <select
                    name="preference-input-preference"
                    id="preference-input-preference"
                  >
                    <option value="1">Subject I</option>
                    <option value="2">Subject II</option>
                    <option value="3">Subject III</option>
                    <option value="3">Subject IV</option>
                    <option value="3">Subject V</option>
                  </select>
                </div>
              </div>
              <div className="preference">
                <div className="preference-label">
                  <label htmlFor="preference-label-preference">
                    Select Elective II:
                  </label>
                </div>
                <div className="preference-input">
                  <select
                    name="preference-input-preference"
                    id="preference-input-preference"
                  >
                    <option value="1">Subject I</option>
                    <option value="2">Subject II</option>
                    <option value="3">Subject III</option>
                    <option value="3">Subject IV</option>
                    <option value="3">Subject V</option>
                  </select>
                </div>
              </div>
              <div className="preference">
                <div className="preference-label">
                  <label htmlFor="preference-label-preference">
                    Select Elective III:
                  </label>
                </div>
                <div className="preference-input">
                  <select
                    name="preference-input-preference"
                    id="preference-input-preference"
                  >
                    <option value="1">Subject I</option>
                    <option value="2">Subject II</option>
                    <option value="3">Subject III</option>
                    <option value="3">Subject IV</option>
                    <option value="3">Subject V</option>
                  </select>
                </div>
              </div>
            </div>
          </div> */}
          <div className="apply-minor">
            <div className="apply-minor-title">
              <h3>
                Others: <span>(Minor / extra / back paper)</span>
              </h3>
            </div>
            <div className="apply-minor-inputs">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="enter-course-details">
                  <input
                    type="text"
                    name="apply-minor-input-input-minor"
                    id="apply-minor-input-input-minor"
                    placeholder="Course Code"
                    value={courseData.code}
                    onChange={(e) =>
                      setCourseData((e1) => {
                        return { ...e1, code: e.target.value };
                      })
                    }
                  />
                </div>
                <div className="enter-course-details">
                  <input
                    type="text"
                    name="apply-minor-input-input-minor"
                    id="apply-minor-input-input-minor"
                    placeholder="Course Name"
                    value={courseData.name}
                    onChange={(e) =>
                      setCourseData((e1) => {
                        return { ...e1, name: e.target.value };
                      })
                    }
                  />
                </div>
                <div className="enter-course-details">
                  <input
                    type="text"
                    name="apply-minor-input-input-minor"
                    id="apply-minor-input-input-minor"
                    placeholder="L"
                    value={courseData.l}
                    onChange={(e) =>
                      setCourseData((e1) => {
                        return { ...e1, l: e.target.value };
                      })
                    }
                  />
                  <input
                    type="text"
                    name="apply-minor-input-input-minor"
                    id="apply-minor-input-input-minor"
                    placeholder="T"
                    value={courseData.t}
                    onChange={(e) =>
                      setCourseData((e1) => {
                        return { ...e1, t: e.target.value };
                      })
                    }
                  />
                  <input
                    type="text"
                    name="apply-minor-input-input-minor"
                    id="apply-minor-input-input-minor"
                    placeholder="P"
                    value={courseData.p}
                    onChange={(e) =>
                      setCourseData((e1) => {
                        return { ...e1, p: e.target.value };
                      })
                    }
                  />
                  <input
                    type="text"
                    name="apply-minor-input-input-minor"
                    id="apply-minor-input-input-minor"
                    placeholder="C"
                    value={courseData.c}
                    onChange={(e) =>
                      setCourseData((e1) => {
                        return { ...e1, c: e.target.value };
                      })
                    }
                  />
                </div>
                <div className="enter-course-details preference-input">
                  <select
                    name="preference-input-preference"
                    id="preference-input-preference"
                    value={courseData.type}
                    onChange={(e) =>
                      setCourseData((e1) => {
                        return { ...e1, type: e.target.value };
                      })
                    }
                  >
                    <option value="">Select Type</option>
                    <option value="Minor">Minor</option>
                    <option value="Extras">Extra</option>
                    <option value="BackPaper">Back Paper</option>
                    <option value="Elective">Elective</option>
                  </select>
                </div>
                <div className="enter-course-details">
                  <button className="primary" type="submit" onClick={addCourse}>+ Add</button>
                </div>
              </form>
            </div>
          </div>
          <div className="submit-registration">
            <p>
              By clicking on submit button you agree that all the information
              provided by you is True. <br /> If its found to be false your
              registration will not be considered.
            </p>
            <button type="submit" onClick={() => registrationDone()}>
              Submit Course Registration
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default CourseRegistrationSection;
