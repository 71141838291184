import * as React from "react";
import AdminHeader from "../components/AdminHeader";
import "../css/Page/DataUpload.css";
import { Link, useNavigate } from "react-router-dom";
import apis from "../apis";
import { AuthProvider } from "../auth/AuthContext";
import Loader from "../components/Loader";
import DebardSection from "../components/DebardSection";

const DataUpload = () => {
  const [studentData, setStudentData] = React.useState({});
  const [roll, setRoll] = React.useState("");
  const [confirmationPopUp, setConfirmationPopUp] = React.useState(false);
  const [course, setCourse] = React.useState("");
  const [file, setFile] = React.useState(null);
  const [file2, setFile2] = React.useState(null);
  const { adminData } = AuthProvider();
  const [fine, setFine] = React.useState({
    amount: 0,
    type: "",
    remarks: ""
  });
  const [feeWaiver, setFeeWaiver] = React.useState({
    amount: 0,
    type: "",
    remarks: ""
  })
  const [adjustment, setAdjustment] = React.useState();

  // const { adminData } = AuthProvider();
  // const navigate = useNavigate();
  // React.useEffect(() => {
  //   if (adminData?.role !== "Super Admin" && adminData?.role !== "Academic Admin" && adminData?.role !== "DOAA" && adminData?.role !== "Academic Office") {
  //     alert("You are not authorized to access this page.");
  //     navigate("/student/admin");
  //   }
  // }, [adminData, navigate]);
  const fetchStudent = async () => {
    await apis
      .specificStudent({ roll: roll })
      .then((data) => {
        setStudentData(data[0]);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const uploadMainDatabase = async () => {
    const formData = new FormData();
    formData.append("file", file);
    await apis
      .uploadMainDatabase(formData)
      .then((data) => {
        alert(data.message);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const uploadFeeStructure = async () => {
    const formData = new FormData();
    formData.append("file", file);
    await apis
      .uploadFeeStructure(formData)
      .then((data) => {
        alert(data.message);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const uploadCourseStructure = async () => {
    const formData = new FormData();
    formData.append("file", file);
    await apis
      .uploadCourseStructure(formData)
      .then((data) => {
        alert(data.message);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const uploadNewAllotmentDatabase = async () => {
    const formData = new FormData();
    formData.append("file", file);
    await apis
      .uploadNewAllotmentDatabase(formData)
      .then((data) => {
        alert(data.message);
      }).catch((err) => {
        alert(err.message);
      })
  }

  const uploadRollandEmailData = async () => {
    const formData = new FormData();
    formData.append("file", file2);
    await apis
      .uploadRollandEmailData(formData)
      .then((data) => {
        alert(data.message);
      }).catch((err) => {
        alert(err.message);
      })
  }

  const [loadingStatus, setLoadingStatus] = React.useState(true);
  const [page, setPage] = React.useState(0);

  React.useEffect(() => {
    setLoadingStatus(true);
    const fetchAllAdjustments = async () => {
      await apis
        .allAdjustments()
        .then((data) => {
          setAdjustment(data);
          setLoadingStatus(false);
        })
        .catch((err) => {
          alert(err.message);
        });
    };
    fetchAllAdjustments();
  }, []);

  const [tempLoading, setTempLoading] = React.useState(false);

  const addFine = async () => {
    if (!studentData.roll) {
      alert("Please select the student!!");
      return;
    }
    setTempLoading(true);
    await apis
      .updateFine({ roll: studentData?.roll, type: fine.type, fine: fine.amount, remarks: fine.remarks })
      .then((data) => {
        alert("Fine Added");
        setTempLoading(false);
        setFine({ amount: 0, type: "", remarks: "" });
      })
      .catch((err) => {
        alert(err.message);
        setTempLoading(false);
      });
  }

  const addFeeWaiver = async () => {
    if (!studentData.roll) {
      alert("Please select the student!!");
      return;
    }
    setTempLoading(true);
    await apis
      .updateDiscount({ roll: studentData?.roll, type: feeWaiver.type, discount: feeWaiver.amount, remarks: feeWaiver.remarks })
      .then((data) => {
        alert("Fee Waived!!")
        setTempLoading(false);
        setFeeWaiver({ amount: 0, type: "", remarks: "" });
      })
      .catch((err) => {
        alert(err.message);
        setTempLoading(false);
      });
  }

  const uploadAdjustmentFile = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", file);
    await apis
      .uploadAdjustments(formData)
      .then((data) => {
        window.location.reload();
        alert(data.message);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const openPopUp = (val) => {
    setCourse(val);
    setConfirmationPopUp(true);
  }

  const closePopUp = () => {
    setCourse("");
    setConfirmationPopUp(false);
  }

  const promoteStudents = async () => {
    if (course !== "") {
      await apis
        .promoteStudents({ course: course })
        .then((data) => {
          alert(data.message);
          closePopUp();
        })
        .catch((err) => {
          alert(err.message);
        });
    }
  }

  return (
    <>
      {
        confirmationPopUp && (
          <div className="popUp-overlay">
            <div className="popUp-container">
              <div className="popUp-Upper">
                <div className="popUp-Heading">Are you sure you want to promote <br /> {course} students</div>
                <div className="">
                  <span>*</span><span>Once promoted you will not be able to reset back.</span>
                </div>
              </div>
              <div className="popUp-lower">
                <button className="cancelBtn" onClick={closePopUp}>Cancel</button>
                <button className="promoteBtn" onClick={promoteStudents}>Promote</button>
              </div>
            </div>
          </div>
        )
      }
      <AdminHeader />
      {adminData?.role?.find((e) => e === "Academic Admin" || e === "DOAA" || e === "Super Admin") &&
        <>
          <div className="top-div">
            <div className="Individual-data-update-div">
              <div className="Individual-data-update-contents">
                <div className="Individual-data-update-details">
                  <h2>Individual Student Payment Adjustments:</h2>
                  <p>Search for a student and update his/her data (if required).</p>
                </div>
                <div className="Individual-search">
                  <input
                    type="text"
                    id="search"
                    placeholder="Enter Student Roll Number"
                    required
                    value={roll}
                    onChange={(e) => setRoll(e.target.value)}
                  />
                  <button className="primary" onClick={() => fetchStudent()}>
                    Search
                  </button>
                </div>
              </div>
              <div className="Individual-search-results">
                <div className="Individual-search-results-contents">
                  <h4>
                    Name: <span>{studentData?.name}</span>
                  </h4>
                  <h4>
                    Roll: <span>{studentData?.roll}</span>
                  </h4>
                  <h4>
                    Batch: <span>{studentData?.batch}</span>
                  </h4>
                  <h4>
                    Semester: <span>{studentData?.semester}</span>
                  </h4>
                </div>
              </div>
              <div className="Individual-data-update-buttons">
                <div className="add-other-data">
                  <div className="add-other-data-element">
                    <h3>Add Fine:</h3>
                    <input
                      type="text"
                      id="search"
                      placeholder="Enter Amount"
                      required
                      value={fine?.amount}
                      onChange={(e) => setFine((prev) => {
                        return { ...prev, amount: e.target.value }
                      })
                      }
                    />
                  </div>
                  <div className="add-other-data-element">
                    <h3>Type:</h3>
                    <select id="type" value={fine?.type} onChange={(e) => setFine((prev) => {
                      return { ...prev, type: e.target.value }
                    })}>
                      <option selected disabled value={""}>Select Type of Fine</option>
                      <option value={"institute"}>Institute</option>
                      <option value={"hostel"}>Hostel</option>
                      <option value={"mess"}>Mess</option>
                    </select>
                  </div>
                  <div className="add-other-data-element">
                    <h3>Reason for fine:</h3>
                    <input
                      type="text"
                      id="search"
                      placeholder="Enter Remarks"
                      required
                      value={fine?.remarks}
                      onChange={(e) => setFine((prev) => {
                        return { ...prev, remarks: e.target.value }
                      })}
                    />
                  </div>
                </div>
                <div className="add-other-data">
                  <div className="add-other-data-element">
                    <h3>Adjust fee:</h3>
                    <input
                      type="text"
                      id="search"
                      placeholder="Enter Amount"
                      required
                      value={feeWaiver?.amount}
                      onChange={(e) => setFeeWaiver((prev) => {
                        return { ...prev, amount: e.target.value }
                      })}
                    />
                  </div>
                  <div className="add-other-data-element">
                    <h3>Type:</h3>
                    <select id="type" value={feeWaiver?.type} onChange={(e) => setFeeWaiver((prev) => {
                      return { ...prev, type: e.target.value }
                    })}>
                      <option selected disabled value={""}>Select Type of Fine</option>
                      <option value={"institute"}>Institute</option>
                      <option value={"hostel"}>Hostel</option>
                      <option value={"mess"}>Mess</option>
                    </select>
                  </div>
                  <div className="add-other-data-element">
                    <h3>Reason for Adjustments:</h3>
                    <input
                      type="text"
                      id="search"
                      placeholder="Enter Remarks"
                      required
                    />
                  </div>
                </div>
                <button className="primary" disabled={tempLoading} style={{cursor: tempLoading ? 'not-allowed' : 'pointer', opacity: tempLoading ? '0.5' : "1"}} onClick={() => {
                  if (fine.amount > 0) {
                    addFine();
                  }
                  if (feeWaiver.amount > 0) {
                    addFeeWaiver()
                  }
                }}>{tempLoading ? "Submitting Please wait..." : "Submit details"}</button>
              </div>
            </div>
          </div>
          <div className="mid-div">
            <div className="data-upload-div">
              <div className="data-upload-contents">
                <div className="data-upload-details">
                  <h2>Upload adjusted fee records in Bulk</h2>
                  <p>
                    Use this to add adjusted fee records, amounts will be adjust from
                    current fee of students
                    <br /> (
                    <Link
                      to="https://docs.google.com/spreadsheets/d/1yYajmSGul9SwjCNuHepldKTK0ALs8lm7lceIoJ1U07k/edit?usp=drive_link"
                      target="_blank"
                    >
                      {" "}
                      Download format{" "}
                    </Link>
                    ) only
                  </p>
                </div>
                <div className="data-upload-buttons">
                  <form style={{ width: "100%" }} onSubmit={(e) => uploadAdjustmentFile(e)}>
                    <input className="required"
                      type="file"
                      id="csv-file"
                      placeholder="Choose .csv file"
                      accept=".csv"
                      value={file?.filename}
                      onChange={(e) => setFile(e.target.files[0])}
                      required
                    />
                    <button className="primary" type="submit">
                      Upload .csv file
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>}
      <div className="mid-div">
        <div className="payHistory-section">
          <div className="payHistory-div">
            <div className="title-div cv">
              <div className="title-left-div">
                <h2>Fee Adjustment History:</h2>
              </div>
            </div>
            <div className="content-div">
              <div className="result-items history">
                <div className="result-heading">
                  <div className="result-heading-item index-column">
                    <h4>S.No</h4>
                  </div>
                  <div className="result-heading-item">
                    <h4>Roll</h4>
                  </div>
                  <div className="result-heading-item">
                    <h4>Amount</h4>
                  </div>
                  <div className="result-heading-item">
                    <h4>Type</h4>
                  </div>
                  <div className="result-heading-item">
                    <h4>Date</h4>
                  </div>
                  <div className="result-heading-item">
                    <h4>Adjusted in</h4>
                  </div>
                  <div className="result-heading-item">
                    <h4>Added By</h4>
                  </div>
                  <div className="result-heading-item TID">
                    <h4>Remarks</h4>
                  </div>
                </div>
                {loadingStatus ? (
                  <Loader />
                ) : (
                  adjustment?.filter((e, i) =>
                    i >= page * 30 && i < page * 30 + 30 ? true : false
                  ).map((item, index) => {
                    return (
                      <div className="result-contents">
                        <div className="result-content">
                          <div className="result-content-item index-column">
                            <h4>{index + page * 30 + 1}</h4>
                          </div>
                          <div className="result-content-item">
                            <h4>{item?.roll}</h4>
                          </div>
                          <div className="result-content-item">
                            <h4>{item?.amount}</h4>
                          </div>
                          <div className="result-content-item">
                            <h4>{item?.fineType}</h4>
                          </div>
                          <div className="result-content-item">
                            <h4>{new Date(item?.date).getDate()}/{new Date(item?.date).getMonth() + 1}/{new Date(item?.date).getFullYear()}</h4>
                          </div>
                          <div className="result-content-item">
                            <h4>{item?.type}</h4>
                          </div>
                          <div className="result-content-item">
                            <h4>{item?.adjustedBy}</h4>
                          </div>
                          <div className="result-content-item TID">
                            <h4>{item?.reason}</h4>
                          </div>
                        </div>
                      </div>)
                  }))}
                <div className="pagination">
                  <button
                    className="new_btn"
                    onClick={() => setPage(page > 0 ? page - 1 : page)}
                  >
                    <i className="fa-solid fa-arrow-left"></i>
                  </button>
                  <div className="page-number">
                    <p>
                      {page + 1} / {Math.ceil(adjustment?.length / 30)}
                    </p>
                  </div>
                  <button
                    className="new_btn"
                    onClick={() =>
                      setPage(
                        page + 1 < adjustment.length / 30 ? page + 1 : page
                      )
                    }
                  >
                    <i className="fa-solid fa-arrow-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {adminData?.role?.find((e) => e === "DOAA" || e === "Super Admin") &&
        <>
          <div className="mid-div">
            <div className="data-upload-divs">
              <div className="data-div-left">
                <div className="data-upload-contents data-div-left-contents">
                  <div className="data-upload-details data-div-left-details">
                    <h2>Fee Structure Database:</h2>
                    <p>
                      Upload data of all students fee in .csv format, please use the
                      given format <br /> (<Link to="https://docs.google.com/spreadsheets/d/1BaGviII5R_-Cpp4Iu0jvUV-C5sFJ1-vmx-InSNVhdKs/edit?usp=drive_link" target="_blank"> Download format </Link>
                      ) only
                    </p>
                  </div>
                  <div className="data-upload-buttons data-div-left-buttons">
                    <form style={{ width: "100%" }}>
                      <input className="required"
                        type="file"
                        id="csv-file"
                        placeholder="Choose .csv file"
                        accept=".csv"
                        required
                        value={file?.filename}
                        onChange={(e) => setFile(e.target.files[0])}
                      />
                      <button
                        className="primary"
                        onClick={() => uploadFeeStructure()}
                      >
                        Upload .csv file
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="data-div-right">
                <div className="data-upload-contents data-div-right-contents">
                  <div className="data-upload-details data-div-right-details">
                    <h2>Course Structure Database:</h2>
                    <p>
                      Upload data of all courses in .csv format, please use the
                      given format <br /> (<Link to="https://docs.google.com/spreadsheets/d/1zDBhyM8_BN0m6t9DgGPwSexGPqBsR1mj7Wep8E33HuU/edit?usp=drive_link" target="_blank"> Download format </Link>
                      ) only
                    </p>
                  </div>
                  <div className="data-upload-buttons data-div-right-buttons">
                    <form style={{ width: "100%" }}>
                      <input className="required"
                        type="file"
                        id="csv-file"
                        placeholder="Choose .csv file"
                        accept=".csv"
                        required
                        value={file?.filename}
                        onChange={(e) => setFile(e.target.files[0])}
                      />
                      <button
                        className="primary"
                        onClick={() => uploadCourseStructure()}
                      >
                        Upload .csv file
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mid-div">
            <DebardSection />
          </div>
          <div className="mid-div">
            <div className="data-upload-div">
              <div className="data-upload-contents">
                <div className="data-upload-details2">
                  <h2>Promote Students:</h2>
                  <div className="inner-element" style={{ display: "flex" }}>
                    <span>
                      Promote B.Tech students to their respective next semester
                    </span>
                    <div className="data-upload-buttons2">
                      <button className="primary" disabled onClick={() => openPopUp("B.TECH.")}>
                        Promote B.Tech
                      </button>
                    </div>
                  </div>
                  <div className="inner-element" style={{ display: "flex" }}>
                    <span>
                      Promote M.Tech students to their respective next semester
                    </span>
                    <div className="data-upload-buttons2">
                      <button className="primary" disabled onClick={() => openPopUp("M.TECH.")}>
                        Promote M.Tech
                      </button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="mid-div">
            <div className="data-upload-div">
              <div className="data-upload-contents">
                <div className="data-upload-details">
                  <h2>New Admission Database:</h2>
                  <p>
                    Upload data of newly alloted students in .csv format, <br />
                    please use the given format (
                    <Link to="https://docs.google.com/spreadsheets/d/1G_dTUyirL4TG2Lf4gWzN6ptRZrtgLvkh-YTba_V36yY/edit?usp=drive_link" target="_blank"> Download format </Link>) only
                  </p>
                </div>
                <div className="data-upload-buttons">
                  <form style={{ width: "100%" }} onSubmit={(e) => e.preventDefault()}>
                    <input className="required"
                      type="file"
                      id="csv-file"
                      placeholder="Choose .csv file"
                      accept=".csv"
                      required
                      value={file?.filename}
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                    <button className="primary" onClick={() => uploadNewAllotmentDatabase()}>
                      Upload .csv file
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="mid-div">
            <div className="data-upload-div">
              <div className="data-upload-contents">
                <div className="data-upload-details">
                  <h2>Assign Institute Roll No. and Institute Mail-Id to New Students:</h2>
                  <p>
                    Upload roll and email data of newly alloted students in .csv format, <br />
                    please use the given format (
                    <Link to="https://docs.google.com/spreadsheets/d/1vO6NcSZjwOZQWASmttCEJkpyphO6k26dBup5n_0v8XQ/edit?usp=drive_link" target="_blank"> Download format </Link>) only
                  </p>
                </div>
                <div className="data-upload-buttons">
                  <form style={{ width: "100%" }}>
                    <input className="required"
                      type="file"
                      id="csv-file"
                      placeholder="Choose .csv file"
                      accept=".csv"
                      required
                      value={file2?.filename}
                      onChange={(e) => setFile2(e.target.files[0])}
                    />
                    <button className="primary" onClick={() => uploadRollandEmailData()}>
                      Upload .csv file
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-div">
            <div className="data-upload-div">
              <div className="data-upload-contents">
                <div className="data-upload-details">
                  <h2>Add Data in Main Database:</h2>
                  <p>
                    Upload data of all the students in .csv format, <br />
                    please use the given format (
                    <Link to="https://docs.google.com/spreadsheets/d/1KpU-GEkLPJ8AxkC0192iWRRP4NM0rvQyWTzuegfMUTs/edit?usp=drive_link" target="blank"> Download format </Link>) only
                  </p>
                </div>
                <div className="data-upload-buttons">
                  <form style={{ width: "100%" }}>
                    <input className="required"
                      type="file"
                      id="csv-file"
                      placeholder="Choose .csv file"
                      accept=".csv"
                      required
                      value={file?.filename}
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                    <button className="primary" onClick={() => uploadMainDatabase()}>
                      Upload .csv file
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>}
    </>
  );
};

export default DataUpload;
