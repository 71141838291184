import * as React from "react";
import "../css/Page/HostelManagement.css";
import AdminHeader from "../components/AdminHeader";
import HostelRequestElement from "../components/HostelRequestElement";
import apis from "../apis";
// import HostelReqHistoryElement from "../components/HostelReqHistoryElement";
import HostelProvider, { useHostelContext } from "../contexts/HostelProvider";
import { AuthProvider } from "../auth/AuthContext";
import Loader from "../components/Loader";
import Table from "../common/tables/Table";
import GenerateTimer from "../components/GenerateTimer";
import UserPage from "../components/UserPage";
import MessManagement from "../components/MessManagement";

const HostelManagementTable = ({ hostelData, sessionList }) => {
  const { adminData } = AuthProvider();
  const { selectAll, setSelectAll, setSelectedData, selectedData } = useHostelContext();
  const [hostel, setHostel] = React.useState();
  const [mess, setMess] = React.useState();
  const [option, setOption] = React.useState("");
  const [page, setPage] = React.useState(-1);
  const [loadingStatus, setLoadingStatus] = React.useState(true);
  const [search, setSearch] = React.useState({
    gender: "",
    session: sessionList[0],
    batch: "",
    hostel: "",
    status: "",
    program: "",
    semester: "",
    previousHostel: "",
  });
  const [batchList, setBatchList] = React.useState();
  const { newHostelData, setNewHostelData } = useHostelContext();
  const [paymentData, setPaymentData] = React.useState();

  const [loading, setLoading] = React.useState(false);


  React.useEffect(() => {
    setNewHostelData(hostelData);
  }, [hostelData]);

  React.useEffect(() => {
    const fetchBatchList = async () => {
      await apis.getBatchFilterList().then((data) => {
        setBatchList(data);
      }).catch((err) => {
        console.log(err.message);
      })
    }
    fetchBatchList();

    if(sessionList.length > 0) {
      setSearch((e) => {
        return { ...e, session: sessionList[0] }
      })
    }

  }, [sessionList.length]);

  React.useEffect(() => {
    const fetchPaymentData = async () => {
      setLoadingStatus(true);
      await apis.getStudentFeesByHostel(search?.hostel !== "" ? search?.hostel : "NA", search?.semester !== "" ? search.semester : "NA").then((data) => {
        setPaymentData(data);
        setLoadingStatus(false);
      }).catch((err) => {
        alert(err.message);
      })
    }
    fetchPaymentData();
  }, [search])

  React.useEffect(() => {
    if (paymentData?.length > 0) {
      setPage(0);
    }
  }, [paymentData, search?.hostel]);

  React.useEffect(() => {
    if (hostelData) {
      setNewHostelData(adminData?.role?.find((e) => e === "Warden") ? hostelData.filter((e) => { return e.hostel === adminData?.wardenHostel }) : hostelData);
    }
    setSearch((e) => {
      return { ...e, hostel: adminData?.role?.find((e) => e === "Warden") ? adminData?.wardenHostel : "" }
    })
  }, [hostelData, adminData]);

  React.useEffect(() => {
    if (selectedData?.length === hostelData?.length) {
      setSelectAll(true);
    }
  }, [selectedData, hostelData, setSelectAll]);

  // React.useEffect(() => {
  //   setNewHostelData(newHostelData)
  // }, [newHostelData])
  const filterStudents = async() => {
    let filteredData = hostelData;

    if (search.session !== "") {
      filteredData = await apis.getAllHostelApplications(search.session);
    }

    if (search.gender !== "") {
      filteredData = filteredData.filter(e => e.gender.toLowerCase() === search.gender.toLowerCase());
    }

    if (search.program !== "") {
      filteredData = filteredData.filter(e => e.program === search.program);
    }

    if (search.batch !== "") {
      filteredData = filteredData.filter(e => e.batch === search.batch);
    }

    if (search.status !== "") {
      filteredData = filteredData.filter(e => e.status === search.status);
    }

    if (search.hostel !== "") {
      filteredData = filteredData.filter(e => e.hostel === search.hostel);
    }

    if (search.semester !== "") {
      filteredData = filteredData.filter(e => e.semester === search.semester);
    }

    if (search.previousHostel !== "") {
      filteredData = filteredData.filter(e => e.previousHostel === search.previousHostel);
    }


    // console.log(filteredData);

    setNewHostelData(filteredData);
  }

  const resetFilter = () => {
    setSearch({
      gender: "",
      session: sessionList[0],
      batch: "",
      hostel: "",
      status: "",
      program: "",
      semester: ""
    })
    setNewHostelData(hostelData);
  }

  const filterData = (newHostelData, option) => {
    console.log("filtering3");
    if (option === "Batch") {
      return newHostelData?.sort((a, b) => {
        return a.batch.localeCompare(b.batch);
      });
    }
    else if (option === "Session") {
      return newHostelData?.sort((a, b) => {
        return a.session.localeCompare(b.session);
      });
    }
    else if (option === "Program") {
      return newHostelData?.sort((a, b) => {
        return a.program.localeCompare(b.program);
      });
    } else if (option === "Preference") {
      return newHostelData?.sort((a, b) => {
        return a.preference.localeCompare(b.preference);
      });
    } else if (option === "Status") {
      return newHostelData?.sort((a, b) => {
        return a.status.localeCompare(b.status);
      });
    } else if (option === "Roll") {
      return newHostelData?.sort((a, b) => {
        return a.roll.localeCompare(b.roll);
      });
    } else if (option === "Gender") {
      return newHostelData?.sort((a, b) => {
        return a.gender.localeCompare(b.gender);
      });
    } else if (option === "Semester") {
      return newHostelData?.sort((a, b) => {
        return a.semester.localeCompare(b.semester);
      })
    } else {
      return newHostelData?.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    }

  };
  // 

  const approveSelected = async () => {
    if (selectAll) {
      const selectedApplications = newHostelData
        ?.filter((data) => {
          return data.status === "Pending";
        })
        ?.map((data) => {
          return data.id;
        });
      await apis
        .approveHostelApplication({ selected: selectedApplications })
        .then((res) => {
          window.location.reload();
          alert("Applications Approved");
        })
        .catch((err) => {
          alert(err.message);
        });
    } else {
      await apis
        .approveHostelApplication({ selected: selectedData })
        .then((res) => {
          window.location.reload();
          alert("Applications Approved");
        })
        .catch((err) => {
          alert(err.message);
        });
    }
  };

  const rejectSelected = async () => {
    if (selectAll) {
      const selectedApplications = newHostelData
        ?.filter((data) => {
          return data.status === "Pending";
        })
        ?.map((data) => {
          return data.id;
        });
      await apis
        .rejectHostelApplication({ selected: selectedApplications })
        .then((res) => {
          window.location.reload();
          alert("Applications Rejected");
        })
        .catch((err) => {
          alert(err.message);
        });
    } else {
      await apis
        .rejectHostelApplication({ selected: selectedData })
        .then((res) => {
          window.location.reload();
          alert("Applications Rejected");
        })
        .catch((err) => {
          alert(err.message);
        });
    }
  };

  const allotHostel = async () => {
    if (selectAll) {
      const selectedApplications = newHostelData
        ?.filter((data) => {
          return data.status === "Approved";
        })
        ?.map((data) => {
          return data.id;
        });
      await apis
        .allotHostel({ selected: selectedApplications, hostel })
        .then((res) => {
          window.location.reload();
          alert("Hostel Alloted");
        })
        .catch((err) => {
          alert(err.message);
        });
    } else {
      await apis
        .allotHostel({ selected: selectedData, hostel })
        .then((res) => {
          window.location.reload();
          alert("Hostel Alloted");
        })
        .catch((err) => {
          alert(err.message);
        });
    }
  };

  const allotMess = async () => {
    if (selectAll) {
      const selectedApplications = newHostelData
        ?.filter((data) => {
          return data.status === "Approved";
        })
        ?.map((data) => {
          return data.id;
        });
      await apis
        .allotMess({ selected: selectedApplications, mess })
        .then((res) => {
          window.location.reload();
          alert("Mess Alloted");
        })
        .catch((err) => {
          alert(err.message);
        });
    } else {
      await apis
        .allotMess({ selected: selectedData, mess })
        .then((res) => {
          window.location.reload();
          alert("Mess Alloted");
        })
        .catch((err) => {
          alert(err.message);
        });
    }
  };

  const columns = [
    {
      Header: (adminData?.role?.includes('HAB') || adminData.role?.includes('Super Admin')) ? (
        <input
          className="checkbox"
          type="checkbox"
          checked={selectAll}
          onChange={(e) => setSelectAll(e.target.checked)}
        />
      ) : null,
      accessor: 'checkbox',
      style: { width: '50px' },
      showContextMenu: false,
    },
    {
      Header: 'S.N.',
      accessor: 'sno',
      style: { width: '48px', minWidth: '48px', cursor: 'pointer' },
      showContextMenu: true,
    },
    {
      Header: 'Name',
      accessor: 'name',
      style: { width: '150px', cursor: 'pointer' },
      showContextMenu: true,
    },
    {
      Header: 'Roll/App. ID',
      accessor: 'roll',
      style: { width: '110px', minWidth: '110px', cursor: 'pointer' },
      showContextMenu: true,
    },
    {
      Header: 'Gender',
      accessor: 'gender',
      style: { width: '70px', minWidth: '70px', cursor: 'pointer' },
      showContextMenu: true,
    },
    {
      Header: 'Batch',
      accessor: 'batch',
      style: { width: '60px', minWidth: '60px', cursor: 'pointer' },
      showContextMenu: true,
    },
    {
      Header: 'Semester',
      accessor: 'semester',
      style: { width: '88px', minWidth: '88px', cursor: 'pointer' },
      showContextMenu: true,
    },
    {
      Header: 'Program',
      accessor: 'program',
      style: { width: '85px', minWidth: '85px', cursor: 'pointer' },
      showContextMenu: true,
    },
    {
      Header: 'Hostel',
      accessor: 'hostel',
      style: { width: '80px', minWidth: '80px', cursor: 'pointer' },
      showContextMenu: true,
    },
    {
      Header: 'Previous Hostel',
      accessor: 'previousHostel',
      style: { width: '110px', minWidth: '110px', cursor: 'pointer' },
      showContextMenu: true,
    },
    {
      Header: 'Mess',
      accessor: 'mess',
      style: { width: '80px', minWidth: '80px', cursor: 'pointer' },
      showContextMenu: true,
    },
    {
      Header: 'Status',
      accessor: 'status',
      style: { width: '70px', minWidth: '70px', cursor: 'pointer' },
      showContextMenu: true,
    },
    {
      Header: 'Allot Room',
      accessor: 'room',
      style: { width: '100%' },
      showContextMenu: false,
    },
  ];

  // const filteredData = filterData(newHostelData, option);

  const hosteldata = newHostelData?.map((data, index) => ({
    checkbox: (
      (adminData?.role?.includes("HAB") || adminData?.role?.includes("Super Admin")) &&
      <input
        className="checkbox"
        type="checkbox"
        checked={selectedData.includes(data.id)}
        onChange={(e) => {
          const newSelectedData = selectedData.includes(data.id)
            ? selectedData.filter(id => id !== data.id)
            : [...selectedData, data.id];
          setSelectedData(newSelectedData);
          setSelectAll(false);
        }}
      />
    ),
    sno: index + 1,
    name: data.name,
    roll: data.roll ?? data.applicationid,
    gender: data.gender,
    batch: data.batch,
    semester: data.semester,
    program: data.program,
    hostel: data.hostel,
    previousHostel: data.previousHostel,
    mess: data.mess,
    status: data.status,
    room: (
      <div className="roomCont">
        {/* <input
          type="text"
          name="room"
          id="room"
          value={data.room}
          className="roomInput"
          disabled={!["Warden", "Super Admin", "HAB"].some(role => adminData?.role?.includes(role))}
          onChange={(e) => {
            const newRoom = e.target.value;
            const newData = [...newHostelData];
            newData[index].room = newRoom;
            setNewHostelData(newData);
          }}
        />
        {["Warden", "Super Admin"].some(role => adminData?.role?.includes(role)) &&
          <input
            type="submit"
            value="set"
            className="roomBtn"
            onClick={() => {
              apis.allotRoom({ id: data.id, room: data.room })
                .then(() => alert("Room Allotted"))
                .catch((err) => alert(err.message));
            }}
          />
        } */}
        <HostelRequestElement
          key={data.id}
          id={data.id}
          room={data.room}
        />
      </div>
    ),
  }));

  return (
    <>
      <div className="hostel-management-body">
        <div className="search-div">
          <div className="sub-title">
            <h2>Filter Students using following:</h2>
            <div className="search-item btn-div">
              <button
                id="search1"
                className="reset-btn"
                onClick={() => resetFilter()}
              >
                <span>Reset </span><i className="fa-solid fa-refresh"></i>
              </button>
            </div>
          </div>
          <div className="search-items">
            <div className="search-item">
              <h4 htmlFor="course">Gender:</h4>

              <select
                name="gender"
                id="gender"
                onChange={(e) =>
                  setSearch((e1) => {
                    return { ...e1, gender: e.target.value };
                  })
                }
                value={search.gender}
              >
                {/* <option value="null">All</option> */}
                <option disabled value="">
                  Gender
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Others">Others</option>
              </select>
            </div>
            <div className="search-item">
              <h4 htmlFor="course">Session:</h4>

              <select
                name="session"
                id="session"
                value={search.session}
                onChange={(e) =>
                  setSearch((e1) => {
                    return { ...e1, session: e.target.value };
                  })
                }
              >
                <option value="">Session</option>
                {sessionList?.map((e) => {
                  return <option value={e}>{e}</option>;
                })}
              </select>
            </div>
            <div className="search-item">
              <h4 htmlFor="course">Program:</h4>

              <select
                name="course"
                id="course"
                onChange={(e) =>
                  setSearch((e1) => {
                    return { ...e1, program: e.target.value };
                  })
                }
                value={search.program}
              >
                <option disabled value="">
                  Program
                </option>
                <option value="B.TECH.">B.Tech</option>
                <option value="M.TECH.">M.Tech</option>
                <option value="Ph.D.">PhD</option>
              </select>
            </div>
            <div className="search-item">
              <h4 htmlFor="semester">Batch:</h4>

              <select
                name="batch"
                id="batch"
                onChange={(e) =>
                  setSearch((e1) => {
                    return { ...e1, batch: e.target.value };
                  })
                }
                value={search?.batch}
              >
                <option disabled value="">
                  Batch
                </option>
                {search?.program ? batchList[search?.program]?.map((batch) => (
                  <option key={batch} value={batch}>
                    {batch}
                  </option>
                )) :
                  <option value="null">Select Program</option>
                }
              </select>
            </div>
            <div className="search-item">
              <h4 htmlFor="semester">Semester:</h4>

              <select
                name="semester"
                id="semester"
                onChange={(e) =>
                  setSearch((e1) => {
                    return { ...e1, semester: e.target.value };
                  })
                }
                value={search.semester}
              >
                <option disabled value="">
                  Semester
                </option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
              </select>
            </div>
            <div className="search-item">
              <h4 htmlFor="hostel">Hostel:</h4>

              <select
                name="hostel"
                id="hostel"
                disabled={adminData?.role?.find((e) => e === "Warden") ? true : false}
                onChange={(e) =>
                  setSearch((e1) => {
                    return { ...e1, hostel: e.target.value };
                  })
                }
                value={search.hostel}
              >
                <option disabled value="">
                  Hostel
                </option>
                <option value="H1">H1</option>
                <option value="H9">H9</option>
                <option value="H7">H7</option>
                <option value="NBH">NBH</option>
                <option value="GH">GH</option>
                <option value="KBH">KBH</option>
                <option value="KGH">KGH</option>
              </select>
            </div>
            <div className="search-item">
              <h4 htmlFor="hostel">Previous Hostel:</h4>

              <select
                name="hostel"
                id="hostel"
                disabled={adminData?.role?.find((e) => e === "Warden") ? true : false}
                onChange={(e) =>
                  setSearch((e1) => {
                    return { ...e1, previousHostel: e.target.value };
                  })
                }
                value={search.previousHostel}
              >
                <option disabled value="">
                  Prev Hostel
                </option>
                <option value="Not Alloted">Not Alloted</option>
                <option value="H1">H1</option>
                <option value="H9">H9</option>
                <option value="H7">H7</option>
                <option value="NBH">NBH</option>
                <option value="GH">GH</option>
                <option value="KBH">KBH</option>
                <option value="KGH">KGH</option>
              </select>
            </div>
            <div className="search-item">
              <h4 htmlFor="branch">Status:</h4>

              <select
                name="status"
                id="status"
                onChange={(e) =>
                  setSearch((e1) => {
                    return { ...e1, status: e.target.value };
                  })
                }
                value={search.status}
              >
                <option disabled value="">
                  Status
                </option>
                <option value="Not Applied">Not Applied</option>
                <option value="Pending">Pending</option>
                <option value="Approved">Approved</option>
                <option value="Alloted">Alloted</option>
                <option value="Rejected">Rejected</option>
              </select>
            </div>
            <div className="search-item btn-div">
              <button
                id="search"
                className="new_btn primary"
                onClick={() => filterStudents()}
              >
                <span>Filter</span>
                <img src="/assets/search-icon.svg" alt="search-icon" />
              </button>
            </div>
          </div>
        </div>
        <div className="seat-allotment">
          <div className="allotment-requests" style={{ width: (adminData?.role?.find((e) => { return e === "Warden" }) && adminData?.role?.find((e) => { return "Super Admin" })) && "100%" }}>
            {/* <div className="request-list" >
              <div className="request-list-header">
                <div className="request-list-header-item checkbox-container">
                  {(adminData?.role?.find((e) => { return e === "HAB" }) || adminData.role?.find((e) => { return e === "Super Admin" })) && <input
                    className="checkbox"
                    type="checkbox"
                    checked={selectAll}
                    onChange={(e) => setSelectAll(e.target.checked)}
                  />
                  }
                </div>
                <div
                  className="request-list-header-item"
                >
                  <h4>S.N.</h4>
                </div>
                <div
                  className="request-list-header-item"
                >
                  <h4>Name</h4>
                </div>
                <div
                  className="request-list-header-item"
                  onClick={() => setOption("Roll")}
                  style={{ cursor: "pointer" }}
                >
                  <h4>Roll/App. ID</h4>
                </div>
                <div
                  className="request-list-header-item"
                  onClick={() => setOption("Gender")}
                  style={{ cursor: "pointer" }}
                >
                  <h4>Gender</h4>
                </div>
                <div
                  className="request-list-header-item"
                  onClick={() => setOption("Batch")}
                  style={{ cursor: "pointer" }}
                >
                  <h4>Batch</h4>
                </div>
                <div
                  className="request-list-header-item"
                  onClick={() => setOption("Semester")}
                  style={{ cursor: "pointer" }}
                >
                  <h4>Semester</h4>
                </div>
                <div
                  className="request-list-header-item"
                  onClick={() => setOption("Program")}
                  style={{ cursor: "pointer" }}
                >
                  <h4>Program</h4>
                </div>
                <div
                  className="request-list-header-item"
                  onClick={() => setOption("Preference")}
                  style={{ cursor: "pointer" }}
                >
                  <h4>Hostel</h4>
                </div>
                <div
                  className="request-list-header-item"
                  onClick={() => setOption("Preference")}
                  style={{ cursor: "pointer" }}
                >
                  <h4>Previous Hostel</h4>
                </div>
                <div
                  className="request-list-header-item"
                  onClick={() => setOption("Status")}
                  style={{ cursor: "pointer" }}
                >
                  <h4>Status</h4>
                </div>
                <div
                  className="request-list-header-item"
                >
                  <h4>Allot Room</h4>
                </div>
              </div>
              <div className="request-list-body">
                {filterData(newHostelData, option)?.map((data, index) => {
                  return (
                    <HostelRequestElement
                      key={data.id}
                      sno={index + 1}
                      id={data.id}
                      name={data.name}
                      roll={data.roll ?? data.applicationid}
                      gender={data.gender}
                      batch={data.batch}
                      semester={data.semester}
                      program={data.program}
                      hostel={data.hostel}
                      previousHostel={data.previousHostel}
                      status={data.status}
                      room={data.room}
                    />
                  );
                })}
              </div>
            </div> */}
            <div className="request-list">
              <Table
                columns={columns}
                data={hosteldata}
                loading={false}
                className="ratingDetailTable"
                bodyClassName="request-list-body ratingDetailBody"
              />
            </div>

          </div>
          {(adminData?.role?.find((e) => { return e === "HAB" }) || adminData.role?.find((e) => { return e === "Super Admin" })) && <div className="allotment-actions">
          <div className="hostelTimerSection">
          <GenerateTimer schedularType={"HOSTEL"} />
          </div>
            <div className="approval-btns">
              <h3>Approve or Reject Selected</h3>
              <div className="remarks">
                <label htmlFor="remarks">Remarks:</label>
                <textarea id="remarks" rows="4" cols="50"></textarea>
              </div>
              <div className="approve-reject-btns">
                <button className="approve-btn primary" onClick={approveSelected}>
                  Approve
                </button>
                <button className="reject-btn secondary" onClick={rejectSelected}>
                  Reject
                </button>
              </div>
            </div>
            <div className="allotment-btns">
              <h3>Allot Hostel to Selected</h3>
              <form>
                <div className="formgroup">
                  <label htmlFor="hostel" className="required">
                    Select Hostel:
                  </label>
                  <select
                    id="hostel"
                    required
                    value={hostel}
                    onChange={(e) => setHostel(e.target.value)}
                    style={{maxWidth: "180px"}}                    
                  >
                    <option value="">Select Hostel</option>
                    <option value="H1">Hostel 1</option>
                    <option value="H9">Hostel 9</option>
                    <option value="H7">Hostel 7</option>
                    <option value="GH">Girls Hostel (BCE)</option>
                    <option value="NBH">New Boys Hostel</option>
                    <option value="KBH">Kautilya Boys Hostel</option>
                    <option value="KGH">Kadambini Girls Hostel</option>
                  </select>
                </div>
                <button className="allot-btn primary" onClick={allotHostel} style={{marginBottom: "10px"}}>
                  Allot to Selected
                </button>
              </form>
            </div>
            <div className="allotment-btns">
              <h3>Allot Mess to Selected</h3>
              <form>
                <div className="formgroup">
                  <label htmlFor="hostel" className="required">
                    Select Mess Service:
                  </label>
                  <select
                    id="mess"
                    required
                    value={mess}
                    onChange={(e) => setMess(e.target.value)}
                    style={{maxWidth: "180px"}}
                  >
                    <option value="">Select Mess Service</option>
                    <option value="KBH_MESS">Sai Hospitality And Services(NBH/H1)</option>
                    <option value="VSL_MESS">Sugandhim – Food & Catering(VCL)</option>
                  </select>
                </div>
                <button className="allot-btn primary" onClick={allotHostel} style={{marginBottom: "10px"}}>
                  Allot to Selected
                </button>
              </form>
            </div>
          </div>}
        </div>
      </div>
      {!adminData?.role?.find((e) => e === "Warden" || e === "Super Admin") &&
        <div className="bottom-div">
          <div className="payHistory-section">
            <div className="payHistory-div">
              <div className="title-div cv">
                <div className="title-left-div">
                  <h2>Payment History:</h2>
                </div>

                {/* <div className="filter-item title-right-div">
                  <h4>From: </h4>
                  <input class="date-input" type="date" value={dateRange[0]} onChange={
                    (e) => {
                      setDateRange([e.target.value, dateRange[1]])
                    }
                  } />
                  <h4 style={{ marginLeft: "5%" }}>To: </h4>
                  <input class="date-input" type="date"
                    value={dateRange[1]} onChange={
                      (e) => {
                        setDateRange([dateRange[0], e.target.value])
                      }
                    }
                  />
                </div> */}
              </div>
              <div className="content-div">
                <div className="result-items history">
                  <div className="result-heading">
                    <div className="result-heading-item index-column">
                      <h4>S.No</h4>
                    </div>
                    <div className="result-heading-item">
                      <h4>Roll No.</h4>
                    </div>
                    <div className="result-heading-item">
                      <h4>Hostel Fee Status</h4>
                    </div>
                    <div className="result-heading-item">
                      <h4>Mess Fee Status</h4>
                    </div>
                    <div className="result-heading-item">
                      <h4>Batch</h4>
                    </div>
                    <div className="result-heading-item">
                      <h4>Program</h4>
                    </div>
                  </div>
                  {loadingStatus ? (
                    <Loader />
                  ) : (
                    <div className="result-contents">
                      {paymentData?.filter((e, i) =>
                        i >= page * 50 && i < page * 50 + 50 ? true : false
                      )
                        .map((payment, index) => {
                          return (
                            <PaymentDetail
                              index={index + page * 50 + 1}
                              payment={payment}
                              key={payment.id}
                              admin={true}
                            />
                          );
                        })}
                    </div>
                  )}
                  <div className="pagination">
                    <button
                      className="new_btn"
                      onClick={() => setPage(page > 0 ? page - 1 : page)}
                    >
                      <i className="fa-solid fa-arrow-left"></i>
                    </button>
                    <div className="page-number">
                      <p>
                        {page + 1} / {Math.ceil(paymentData?.length / 50)}
                      </p>
                    </div>
                    <button
                      className="new_btn"
                      onClick={() =>
                        setPage(
                          page + 1 < paymentData?.length / 50 ? page + 1 : page
                        )
                      }
                    >
                      <i className="fa-solid fa-arrow-right"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
    </>
  );
};

const DownloadCsv = () => {
  // const { searchResult } = useSearch();
  // 
  const { newHostelData } = useHostelContext();
  const { adminData } = AuthProvider();
  async function downloadCsv() {
    const headers = [
      {
        label: "Roll",
        value: "roll",
      },
      {
        label: "Name",
        value: "name",
      },
      {
        label: "Gender",
        value: "gender",
      },
      {
        label: "Program",
        value: "program",
      },
      {
        label: "Batch",
        value: "batch",
      },
      {
        label: "Status",
        value: "status",
      },
      {
        label: "Alloted Hostel",
        value: "hostel",
      },
      {
        label: "Alloted room",
        value: "room",
      },
    ];
    await apis
      .downloadCsv(adminData?.role?.find((e) => e === "Warden") ? newHostelData?.filter((e) => { return e.hostel === adminData?.wardenHostel }) : newHostelData, headers, "hostelData.csv")
      .then((data) => {
        let url = window.URL.createObjectURL(data);
        window.open(url, +"_blank");
        alert("Downloaded");
      })
      .catch((err) => {
        alert(err.message);
      });
  }
  return (
    <div className="result-footer">
      <div className="result-footer-title">
        <h2>Export Hostel Allotment Records</h2>
        <p>(Get an offline .csv file of above data)</p>
      </div>
      <div className="result-footer-button">
        <button onClick={() => downloadCsv()}>
          Start exporting now as .csv file{" "}
          <i className="fa-solid fa-circle-arrow-right"></i>
        </button>
      </div>
    </div>
  );
};

const PaymentDetail = ({ payment, index }) => {
  return (
    <div className="result-content">
      <div className="result-content-item index-column">
        <h4>{index}. </h4>
      </div>
      <div className="result-content-item">
        <h4>{payment.roll ?? payment?.applicationid}</h4>
      </div>
      <div className="result-content-item">
        <h4>₹ {payment.paymentStatusSplit.hostel}</h4>
      </div>
      <div className="result-content-item">
        <h4>₹ {payment.paymentStatusSplit.mess}</h4>
      </div>
      <div className="result-content-item">
        <h4>{payment.batch}</h4>
      </div>
      <div className="result-content-item TID">
        <h4>{payment.course}</h4>
      </div>
    </div>
  )
}

function CountDisplay() {
  const { newHostelData } = useHostelContext();

  return <div className="allotment-highlights">
    <div className="allotment-highlight-group">
      <div className="allotment-highlight">
        <h1>{newHostelData?.length}</h1>
        <h4>Total</h4>
      </div>
      <div className="allotment-highlight">
        <h1>
          {
            newHostelData?.filter((data) => {
              return data.status === "Pending";
            }).length
          }
        </h1>
        <h4>Pending</h4>
      </div>
      <div className="allotment-highlight">
        <h1>
          {
            newHostelData?.filter((data) => {
              return data.status === "Approved";
            }).length
          }
        </h1>
        <h4>Approved</h4>
      </div>
      <div className="allotment-highlight">
        <h1>
          {
            newHostelData?.filter((data) => {
              return data.status === "Rejected";
            }).length
          }
        </h1>
        <h4>Rejected</h4>
      </div>
      <div className="allotment-highlight">
        <h1>
          {
            newHostelData?.filter((data) => {
              return data.status === "Alloted";
            }).length
          }
        </h1>
        <h4>Alloted</h4>
      </div>
    </div>
    <div className="allotment-highlight-group">
      <div className="allotment-highlight allotted-element">
        <h1>
          {
            newHostelData?.filter((data) => {
              return data.hostel === "H1";
            }).length
          }
        </h1>
        <h4>H1</h4>
      </div>
      <div className="allotment-highlight allotted-element">
        <h1>
          {
            newHostelData?.filter((data) => {
              return data.hostel === "H9";
            }).length
          }
        </h1>
        <h4>H9</h4>
      </div>
      <div className="allotment-highlight allotted-element">
        <h1>
          {
            newHostelData?.filter((data) => {
              return data.hostel === "H7";
            }).length
          }
        </h1>
        <h4>H7</h4>
      </div>
      <div className="allotment-highlight allotted-element">
        <h1>
          {
            newHostelData?.filter((data) => {
              return data.hostel === "GH";
            }).length
          }
        </h1>
        <h4>GH</h4>
      </div>
      {/* <div className="allotment-highlight allotted-element">
        <h1>
          {
            newHostelData?.filter((data) => {
              return data.hostel === "NBH";
            }).length
          }
        </h1>
        <h4>NBH</h4>
      </div> */}
      <div className="allotment-highlight allotted-element">
        <h1>
          {
            newHostelData?.filter((data) => {
              let yearFromSession = data.session.split("-")[0];
              if(Number(yearFromSession) >= 2024) {
                return data.hostel === "KBH";
              }
              else{
                return data.hostel === "NBH";
              }
            }).length
          }
        </h1>
        <h4>KBH</h4>
      </div>
      <div className="allotment-highlight allotted-element">
        <h1>
          {
            newHostelData?.filter((data) => {
              return data.hostel === "KGH";
            }).length
          }
        </h1>
        <h4>KGH</h4>
      </div>
    </div>
  </div>
}

const HostelManagement = () => {
  const [hostelData, setHostelData] = React.useState();
  const [page, setPage] = React.useState(-1);
  const [loadingStatus, setLoadingStatus] = React.useState(true);
  const [sessionList, setSessionList] = React.useState([]);
  const [feeDetails, setFeeDetails] = React.useState();
  const { adminData } = AuthProvider();
  const fetchAllApplied = async () => {
    await apis.getSessionFilterList().then(async(sessionList) => {
      setSessionList(sessionList);
      const currSession = sessionList[0];
      await apis
        .getAllHostelApplications(currSession)
        .then((res) => {
          setHostelData(res);
        })
        .catch((err) => {
          alert(err.message);
        });
    }).catch((err) => {
      alert(err.message);
    });
  };

  const fetchStudentFeesByHostel = React.useCallback(async () => {
    setLoadingStatus(true);
    adminData?.wardenHostel && await apis.getStudentFeesByHostel(adminData?.wardenHostel).then((data) => {

      setFeeDetails(data);
      setLoadingStatus(false);
    }).catch((err) => {
      alert(err.message);
    })
  }, [adminData])

  React.useEffect(() => {
    fetchStudentFeesByHostel();
    fetchAllApplied();
  }, [fetchStudentFeesByHostel]);

  React.useEffect(() => {
    if (feeDetails?.length > 0) {
      setPage(0);
    }
  }, [feeDetails, adminData?.wardenHostel]);

  return (
    <>
      <AdminHeader />
      <HostelProvider>
        <div className="top-div">
          <CountDisplay />
        </div>
        <div className="mid-div">
          <div className="hostel-management">
            <div className="title cv">
              <div className="title-left-div">
                <h2>Hostel management:</h2>
              </div>

              <div className="title-right-div">
                <div className="btn-options">
                </div>
              </div>
            </div>

            <HostelManagementTable hostelData={hostelData} sessionList={sessionList} />
          </div>
        </div>
        <div className="mid-div">
          <div className="hostel-management">
            <div className="title cv">
              <div className="title-left-div">
                <h2>Mess management:</h2>
              </div>

              <div className="title-right-div">
                <div className="btn-options">
                </div>
              </div>
            </div>

            <MessManagement />
            {/* <HostelManagementTable hostelData={hostelData} sessionList={sessionList} /> */}
          </div>
        </div>
        <div className="mid-div" style={{ marginBottom: "0px" }}>
          <DownloadCsv />
        </div>

        {adminData?.role?.find((e) => e === "Warden") &&
          <div className="bottom-div">
            <div className="payHistory-section">
              <div className="payHistory-div">
                <div className="title-div cv">
                  <div className="title-left-div">
                    <h2>Payment History:</h2>
                  </div>

                  {/* <div className="filter-item title-right-div">
                  <h4>From: </h4>
                  <input class="date-input" type="date" value={dateRange[0]} onChange={
                    (e) => {
                      setDateRange([e.target.value, dateRange[1]])
                    }
                  } />
                  <h4 style={{ marginLeft: "5%" }}>To: </h4>
                  <input class="date-input" type="date"
                    value={dateRange[1]} onChange={
                      (e) => {
                        setDateRange([dateRange[0], e.target.value])
                      }
                    }
                  />
                </div> */}
                </div>
                <div className="content-div">
                  <div className="result-items history">
                    <div className="result-heading">
                      <div className="result-heading-item index-column">
                        <h4>S.No</h4>
                      </div>
                      <div className="result-heading-item">
                        <h4>Roll No.</h4>
                      </div>
                      <div className="result-heading-item">
                        <h4>Hostel Fee</h4>
                      </div>
                      <div className="result-heading-item">
                        <h4>Mess Fee</h4>
                      </div>
                      <div className="result-heading-item">
                        <h4>Batch</h4>
                      </div>
                      <div className="result-heading-item">
                        <h4>Program</h4>
                      </div>
                    </div>
                    {loadingStatus ? (
                      <Loader />
                    ) : (
                      <div className="result-contents">
                        {feeDetails?.filter((e, i) =>
                          i >= page * 50 && i < page * 50 + 50 ? true : false
                        )
                          .map((payment, index) => {
                            return (
                              <PaymentDetail
                                index={index + page * 50 + 1}
                                payment={payment}
                                key={payment.id}
                                admin={true}
                              />
                            );
                          })}
                      </div>
                    )}
                    <div className="pagination">
                      <button
                        className="new_btn"
                        onClick={() => setPage(page > 0 ? page - 1 : page)}
                      >
                        <i className="fa-solid fa-arrow-left"></i>
                      </button>
                      <div className="page-number">
                        <p>
                          {page + 1} / {Math.ceil(feeDetails?.length / 50)}
                        </p>
                      </div>
                      <button
                        className="new_btn"
                        onClick={() =>
                          setPage(
                            page + 1 < feeDetails?.length / 50 ? page + 1 : page
                          )
                        }
                      >
                        <i className="fa-solid fa-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>}
      </HostelProvider>
    </>
  );
};

export default HostelManagement;
