import React, { useEffect } from "react";
import "../css/Component/PersonalDetailsPopUp.css";
import { Link } from "react-router-dom";
import apis from "../apis";
import { AuthProvider } from "../auth/AuthContext";
import { CircularProgress } from "@mui/material";

const arrayBufferToURL = (buffer, type) => {
  var data = new Uint8Array(buffer);
  var blob = new Blob([data], { type: type });
  var url = URL.createObjectURL(blob);
  return url;
};

const OtherDocumentsPopUp = ({
  open,
  onClose,
  applicationid,
  documentVerificationStatus,
}) => {
  const { adminData } = AuthProvider();
  const [reject, setReject] = React.useState(null);
  const [showButtons, setShowButtons] = React.useState({
    pwdCertificate: false,
    medicalCertificate: false,
    provisionalDocVerificationLetter: false,
    application: false,
  });
  const [rejectReason, setRejectReason] = React.useState({
    pwdCertificate: "",
    medicalCertificate: "",
    provisionalDocVerificationLetter: "",
    application: "",
  });

  const [loading, setLoading] = React.useState(true);
  const [userDataFiles, setUserDataFiles] = React.useState();
  useEffect(() => {
    if(applicationid && open){
      const fetchData = async () => {
        setLoading(true);
        await apis
          .fetchOtherFiles(applicationid)
          .then((res) => {
            setUserDataFiles(res);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            alert(err.message);
          });
      };
      fetchData();
    }
  }, [open, applicationid]);

  useEffect(() => {
    if (userDataFiles) {
      setShowButtons({
        pwdCertificate:
          userDataFiles.documentVerificationStatus.pwdCertificate.status ===
          "Verified"
            ? false
            : true,
        medicalCertificate:
          userDataFiles.documentVerificationStatus.medicalCertificate.status ===
          "Verified"
            ? false
            : true,
        provisionalDocVerificationLetter:
          userDataFiles.documentVerificationStatus
            .provisionalDocVerificationLetter.status === "Verified"
            ? false
            : true,
        application:
          userDataFiles.documentVerificationStatus.application.status ===
          "Verified"
            ? false
            : true,
      });

      setRejectReason({
        pwdCertificate:
          userDataFiles.documentVerificationStatus.pwdCertificate.comment,
        medicalCertificate:
          userDataFiles.documentVerificationStatus.medicalCertificate.comment,
        provisionalDocVerificationLetter:
          userDataFiles.documentVerificationStatus
            .provisionalDocVerificationLetter.comment,
        application:
          userDataFiles.documentVerificationStatus.application.comment,
      });
    }
  }, [
    userDataFiles,
    showButtons.pwdCertificate,
    showButtons.medicalCertificate,
    showButtons.provisionalDocVerificationLetter,
    showButtons.application,
  ]);

  const handleReject = (type) => {
    setReject(type);
  };

  const verifyDocument = async (docType) => {
    const data = {
      verified: "Verified",
      doc: docType,
      comment: "Document verified",
    };
    await apis
      .approveRejectDocuments(data, applicationid)
      .then((res) => {
        setShowButtons({ ...showButtons, [docType]: false });
        userDataFiles.documentVerificationStatus[docType].status = "Verified";
        documentVerificationStatus[docType].status = "Verified";
        alert(`${docType} verified successfully`);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const rejectDocument = async (docType) => {
    const data = {
      verified: "Rejected",
      doc: docType,
      comment: rejectReason[docType] === "" ? null : rejectReason[docType],
    };

    console.log(userDataFiles.documentVerificationStatus[docType].status);

    if (
      (userDataFiles.documentVerificationStatus[docType].status = "Verified")
    ) {
      if (
        window.confirm(
          `The document ${docType} is already verified. Are you sure you want to reject it?`
        )
      ) {
        await apis
          .approveRejectDocuments(data, applicationid)
          .then((res) => {
            setShowButtons({ ...showButtons, [docType]: true });
            userDataFiles.documentVerificationStatus[docType].status =
              "Rejected";
            documentVerificationStatus[docType].status = "Rejected";
            userDataFiles.documentVerificationStatus[docType].comment =
              rejectReason[docType];
            setReject(null);
            alert(
              `${docType} rejected successfully with Reason: ${rejectReason[docType]}`
            );
          })
          .catch((err) => {
            setReject(false);
            console.log(err.message);
          });
      } else {
        return;
      }
    }
  };

  if (!open) return null;
  return (
    <>
      <div className="PersonalDetailsPopUp">
        {reject && (
          <div className="reject-form">
            <div className="reject-form-content" style={{position: 'relative'}}>
              <h3>Reject Reason</h3>
              <button onClick={() => setReject(null)} className="closeReject">
                  <i class="fa-solid fa-xmark"></i>
                </button>
              <textarea
                placeholder="Enter reject reason"
                value={rejectReason[reject] === "Document verified" ? "" : rejectReason[reject]}
                onChange={(e) =>
                  setRejectReason({ ...rejectReason, [reject]: e.target.value })
                }
              ></textarea>
              <button
                className="primary"
                onClick={() => rejectDocument(reject)}
              >
                Submit
              </button>
            </div>
          </div>
        )}
        <div className="documents-popup-title">
          <h2>
            Other Documents: <span>{applicationid}</span>
          </h2>
          <button onClick={onClose}>
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
        {loading ? <div className="documents-popup-content" style={{minHeight: "100px", height: "200px", display: "flex", alignItems: 'center', justifyContent: 'center', width: '100%'}}> <CircularProgress /> </div> : (
        <div className="documents-popup-content">
          <div className="view-mode">
            <div className="form-container">
              <div className="form-groups-row">
                <div className="form-group left-group docs-uploading">
                  <label htmlFor="pwdDoc">PWD Certificate (pdf)</label>
                  {userDataFiles?.pwdCertificate && (
                    <Link
                      to={arrayBufferToURL(
                        userDataFiles?.pwdCertificate?.data,
                        "application/pdf"
                      )}
                      style={{ marginTop: "5px" }}
                      target="_blank"
                    >{`${applicationid}_pwdCertificate`}</Link>
                  )}
                  {adminData &&
                    (adminData.role.includes("Super Admin") ||
                      adminData.role.includes("Document Verifier")) &&
                    userDataFiles?.pwdCertificate && (
                      <div className="approve-reject">
                        <button
                          className="approve primary"
                          onClick={() => verifyDocument("pwdCertificate")}
                        >
                          {documentVerificationStatus.pwdCertificate.status === "Rejected" || documentVerificationStatus.pwdCertificate.status === "Verified" ? "Re Approve" : "Approve"}
                        </button>
                        <button
                          className="reject secondary"
                          onClick={() => handleReject("pwdCertificate")}
                        >
                          {documentVerificationStatus.pwdCertificate.status === "Rejected" ? "Rejected" : "Reject"}
                        </button>
                      </div>
                    )}
                </div>
                <div className="form-group right-group docs-uploading">
                  <label htmlFor="mediDoc" className="required">
                    Medical Certificate (pdf)
                  </label>
                  {userDataFiles?.medicalCertificate && (
                    <Link
                      to={arrayBufferToURL(
                        userDataFiles?.medicalCertificate.data,
                        "application/pdf"
                      )}
                      style={{ marginTop: "5px" }}
                      target="_blank"
                    >{`${applicationid}_medicalCertificate`}</Link>
                  )}
                  {adminData &&
                    (adminData.role.includes("Super Admin") ||
                      adminData.role.includes("Document Verifier")) && (
                      <div className="approve-reject">
                        <button
                          className="approve primary"
                          onClick={() => verifyDocument("medicalCertificate")}
                        >
                          {documentVerificationStatus.medicalCertificate.status === "Rejected" || documentVerificationStatus.medicalCertificate.status === "Verified" ? "Re Approve" : "Approve"}
                        </button>
                        <button
                          className="reject secondary"
                          onClick={() => handleReject("medicalCertificate")}
                        >
                          {documentVerificationStatus.medicalCertificate.status === "Rejected" ? "Rejected" : "Reject"}
                        </button>
                      </div>
                    )}
                </div>
              </div>
              <div className="form-groups-row">
                <div className="form-group left-group docs-uploading">
                  <label htmlFor="provisionalDoc" className="required">
                    Provisional document verifiaction letter (pdf)
                  </label>
                  {userDataFiles?.provisionalDocVerificationLetter && (
                    <Link
                      to={arrayBufferToURL(
                        userDataFiles?.provisionalDocVerificationLetter.data,
                        "application/pdf"
                      )}
                      style={{ marginTop: "5px" }}
                      target="_blank"
                    >{`${applicationid}_provisionalDocverificationLetter`}</Link>
                  )}
                  {adminData &&
                    (adminData.role.includes("Super Admin") ||
                      adminData.role.includes("Document Verifier")) && (
                      <div className="approve-reject">
                        <button
                          className="approve primary"
                          onClick={() =>
                            verifyDocument("provisionalDocVerificationLetter")
                          }
                        >
                          {documentVerificationStatus.provisionalDocVerificationLetter.status === "Rejected" || documentVerificationStatus.provisionalDocVerificationLetter.status === "Verified" ? "Re Approve" : "Approve"}
                        </button>
                        <button
                          className="reject secondary"
                          onClick={() =>
                            handleReject("provisionalDocVerificationLetter")
                          }
                        >
                          {documentVerificationStatus.provisionalDocVerificationLetter.status === "Rejected" ? "Rejected" : "Reject"}
                        </button>
                      </div>
                    )}
                </div>
                <div className="form-group right-group docs-uploading">
                  <label htmlFor="appInLew">
                    Category Certificate (EWS/OBC/SC/ST) (pdf)
                  </label>
                  {userDataFiles?.application && (
                    <Link
                      to={arrayBufferToURL(
                        userDataFiles?.application.data,
                        "application/pdf"
                      )}
                      style={{ marginTop: "5px" }}
                      target="_blank"
                    >{`${applicationid}_casteCertificate`}</Link>
                  )}
                  {adminData &&
                    (adminData.role.includes("Super Admin") ||
                      adminData.role.includes("Document Verifier")) && (
                      <div className="approve-reject">
                        <button
                          className="approve primary"
                          onClick={() => verifyDocument("application")}
                        >
                          {documentVerificationStatus.application.status === "Rejected" || documentVerificationStatus.application.status === "Verified" ? "Re Approve" : "Approve"}
                        </button>
                        <button
                          className="reject secondary"
                          onClick={() => handleReject("application")}
                        >
                          {documentVerificationStatus.application.status === "Rejected" ? "Rejected" : "Reject"}
                        </button>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
        )}
      </div>
    </>
  );
};

export default OtherDocumentsPopUp;
