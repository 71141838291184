import React, { useEffect, useState } from "react";
import "../css/Component/FormsDocs.css";
import apis from "../apis";
import DocAddPopup from "../components/DocAddPopup";

const bufferToURL = (buffer) => {
  let byteArray = new Uint8Array(buffer);
  var blob = new Blob([byteArray], { type: "application/pdf" });
  var url = URL.createObjectURL(blob);
  return url;
};

const Form = (props) => {
  return (
    <div className="form">
      <a
        href={props.link ? props.link : bufferToURL(props.files.data)}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.fileName}
      </a>
    </div>
  );
};

const Doc = (props) => {
  return (
    <div className="doc">
      <a
        href={props.link ? props.link : bufferToURL(props.files.data)}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.fileName}
      </a>
    </div>
  );
};

const FormsDocs = ({ admin }) => {
  const [forms, setForms] = useState([]);
  const [docs, setDocs] = useState([]);
  const [add, setAdd] = useState(false);

  useEffect(() => {
    async function fetchData() {
      await apis
        .allForms()
        .then((data) => {
          if (data) {
            setForms(data);
          }
        })
        .catch((err) => {
          alert(err);
        });

      await apis
        .allDocuments()
        .then((data) => {
          if (data) {
            setDocs(data);
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
    fetchData();
  }, []);

  const [openDocAddPopup, setOpenDocAddPopup] = useState({
    open: false,
    type: "",
  });

  return (
    <>
      <DocAddPopup
        open={openDocAddPopup.open}
        onClose={() => setOpenDocAddPopup(false)}
      />
      <div className="FormsDocs">
        <div className="FormsDocs-box">
          <div className="form-title">
            <h2>Forms:</h2>
            {admin === true && (
              <button
                className="primary"
                onClick={() =>
                  setOpenDocAddPopup({
                    open: true,
                  })
                }
              >
                Add
              </button>
            )}
          </div>
          <div className="contents">
            {forms.map((form) => {
              return (
                <Form
                  id={form.id}
                  link={form.link}
                  fileName={form.fileName}
                  files={form.files}
                />
              );
            })}
          </div>
        </div>

        <div className="FormsDocs-box">
          <div className="doc-title">
            <h2>Documents:</h2>
            {admin === true && (
              <button
                className="primary"
                onClick={() =>
                  setOpenDocAddPopup({
                    open: true,
                  })
                }
              >
                Add
              </button>
            )}
          </div>
          <div className="contents">
            {docs.map((doc) => {
              return (
                <Doc
                  id={doc.id}
                  link={doc.link}
                  fileName={doc.fileName}
                  files={doc.files}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default FormsDocs;
