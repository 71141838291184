import axios from "axios";

class NewRegistrationApi {
    constructor() {
        this.client = axios.create({
            baseURL: "/api/new",
            headers: {
                Authorization: `${localStorage.getItem("newUserToken")}`,
            }
        })
    }

    login = (applicationId, password) => {
        return new Promise(async (resolve, reject) => {
            await this.client.post("/login", { applicationId, password })
                .then((response) => {
                    localStorage.setItem("newUserToken", `Bearer ${response.data.token}`);
                    resolve(response.data.userData);
                })
                .catch((err) => {
                    reject(err.response.data);
                })
        })
    }

    getProfile = () => {
        return new Promise(async (resolve, reject) => {
            await this.client({
                url: "/newregistration",
                method: "GET",
                headers: {
                    'Content-Type': "application/json",
                    Authorization: `${localStorage.getItem("newUserToken")}`
                }
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((err) => {

                    reject(err.response.data);
                })
        })
    }

    updateProfile = (data) => {
        return new Promise(async (resolve, reject) => {
            await this.client({
                url: "/update",
                method: "PUT",
                headers: {
                    'Content-Type': "application/json",
                    Authorization: `${localStorage.getItem("newUserToken")}`
                },
                data
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                })
        })
    }

    uploadFiles1 = (data, applicationid) => {
        return new Promise(async (resolve, reject) => {
            await this.client({
                url: `/uploadfiles1/${applicationid}`,
                method: "POST",
                headers: {
                    'Content-Type': "multipart/form-data",
                    Authorization: `${localStorage.getItem("newUserToken")}`
                }, data
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                })
        })
    }

    uploadFiles2 = (data, applicationid) => {
        return new Promise(async (resolve, reject) => {
            await this.client({
                url: `/uploadfiles2/${applicationid}`,
                method: "PUT",
                headers: {
                    'Content-Type': "multipart/form-data",
                    Authorization: `${localStorage.getItem("newUserToken")}`
                },
                data
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                })
        })
    }

    checkReupload = (applicationid) => {
        return new Promise(async (resolve, reject) => {
            await this.client({
                url: `/checkReupload/${applicationid}`,
                method: "GET",
                headers: {
                    'Content-Type': "application/json",
                    Authorization: `${localStorage.getItem("newUserToken")}`
                }
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                })
        })
    }

    getProfileAdmin = (applicationid) => {
        return new Promise(async (resolve, reject) => {
            await this.client({
                url: `/profile/admin/${applicationid}`,
                method: "GET",
                headers: {
                    'Content-Type': "application/json",
                    Authorization: `${localStorage.getItem("admin_access_token")}`
                },
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                })
        })
    }

    applyNewStudHostel = (applicationid, batch, program, semester, hostel) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.client({
                    url: `/new-stud-hostel`,
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `${localStorage.getItem("newUserToken")}`
                    },
                    data: {
                        applicationid,
                        batch,
                        program,
                        semester,
                        hostel
                    }
                })
                resolve(response.data);
            } catch (err) {
                reject(err.response.data);
            }
        })
    }

    getNewStudHostel = (applicationid, batch, program, semester) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.client({
                    url: `/new-stud-hostel/?applicationid=${applicationid}&batch=${batch}&program=${program}&semester=${semester}`,
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `${localStorage.getItem("newUserToken")}`
                    }
                })
                resolve(response.data);
            } catch (err) {
                reject(err.response.data);
            }
        })
    }

    downloadPhotoAndSign = (session) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await this.client({
                    url: `/admin/allstud-image-sign/?session=${session}`,
                    method: "GET",
                    responseType: "blob",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `${localStorage.getItem("admin_access_token")}`,
                    },
                });
                resolve(res.data);
            } catch (err) {
                reject(err.response.data);
            }
        });
    }

    // downloadPhotoAndSign2 = (session) => {
    //     return new Promise(async (resolve, reject) => {
    //       try {
    //         // Create an EventSource to listen to SSE from the server
    //         const eventSource = new EventSource(`/api/new/admin/allstud-image-sign2/?session=${session}`);
      
    //         eventSource.onmessage = function (event) {
    //           const data = JSON.parse(event.data);
    //           if (data.counter !== undefined) {
    //             // Update your UI with the counter value
    //             console.log(`Processed: ${data.counter}`);
    //           }
    //         };
      
    //         eventSource.addEventListener("close", () => {
    //           eventSource.close();
    //         });
      
    //         eventSource.onerror = function (err) {
    //           eventSource.close();
    //           reject(err);
    //         };
      
    //       } catch (err) {
    //         reject(err);
    //       }
    //     });
    //   };
};

const instance = new NewRegistrationApi();

export default instance;