import React, { useEffect, useRef } from 'react'
import Header from '../components/Header'
import "../css/Page/BankDetailsPage.css"
import { AuthProvider } from "../auth/AuthContext";
import apis from '../apis';

const BankDetailsPage = () => {
  const { userData } = AuthProvider();
  console.log(userData);
  const selectFile = useRef(null);
  const [file, setFile] = React.useState(null);
  const [filePreview, setFilePreview] = React.useState(null);
  const [showInput, setShowInput] = React.useState(false);
  const [showUpload, setShowUpload] = React.useState(true);
  const [bankDetails, setBankDetails] = React.useState({
    accountNumber: '',
    bankName: '',
    ifscCode: '',
  });

  const arrayBufferToBase64 = (buffer) => {
    var binary = "";
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    return window.btoa(binary);
  }

  useEffect(() => {
    if (userData) {
      setBankDetails({
        accountNumber: userData.accountNumber === null || userData.accountNumber === "" ? null : userData.accountNumber,
        bankName: userData.bankName === null || userData.bankName === "" ? null : userData.bankName,
        ifscCode: userData.ifscCode === null || userData.ifscCode === "" ? null : userData.ifscCode,
      });

      if (userData?.passbook) {
        setShowUpload(false);
        setFilePreview(`data:application/pdf;base64,${arrayBufferToBase64(userData.passbook.data)}`);
      }
    }

    if (userData && userData?.accountNumber && userData?.bankName && userData?.ifscCode && userData?.passbook) {
      setShowInput(false);
    } else {
      setShowInput(true);
    }
  }, [userData]);

  const handleClick = () => {
    selectFile.current?.click();
  }

  const handleChange = async (e) => {
    console.log(e.target.files[0]);
    setFile(e.target.files[0]);
    setFilePreview(URL.createObjectURL(e.target.files[0]));
    setShowUpload(false);
  }

  const handleBankDetails = async () => {
    if (!showInput) {
      setShowUpload(true);
      setShowInput(true);
      return;
    }

    if (file) {
      const formData = new FormData();
      formData.append('passbook', file);
      formData.append('roll', userData.roll);
      formData.append('accountNumber', bankDetails.accountNumber);
      formData.append('bankName', bankDetails.bankName);
      formData.append('ifscCode', bankDetails.ifscCode);

      await apis.addBankDetails(formData).then((data) => {
        alert('Bank Details added successfully');
      }).catch((err) => {
        alert(err.message);
      });
    }
    else if (filePreview) {
      const formData = new FormData();
      formData.append('roll', userData.roll);
      formData.append('accountNumber', bankDetails.accountNumber);
      formData.append('bankName', bankDetails.bankName);
      formData.append('ifscCode', bankDetails.ifscCode);

      await apis.addBankDetails(formData).then((data) => {
        alert('Bank Details updated successfully');
        window.location.reload();
      }).catch((err) => {
        alert(err.message);
      });
    }
    else {
      alert('Please upload the passbook');
    }
  }

  return (
    <div>
      <Header />
      <div className="pageContainer">
        <h2 className="passbook_heading">Bank Details</h2>
        <div className="detailContainer_bank">
          <div className="leftPart_bank">
            <p><span className='detailHeading'>Name</span><span>: {userData.name}</span></p>
            <p><span className='detailHeading'>S/D/o</span><span>: {userData.fathersname}</span></p>
            <p><span className='detailHeading'>Bank Name</span><span>: </span>
              {
                showInput ? (
                  <input type='text' className='bank-input' value={bankDetails.bankName} onChange={
                    (e) => setBankDetails({ ...bankDetails, bankName: e.target.value })
                  } placeholder='Enter Bank Name' />
                ) : (
                  <span>{userData.bankName}</span>
                )
              }
            </p>
            <p><span className='detailHeading'>Account Number</span><span>: </span>
              {
                showInput ? (
                  <input type='text' className='bank-input' value={bankDetails.accountNumber} onChange={
                    (e) => setBankDetails({ ...bankDetails, accountNumber: e.target.value })
                  } placeholder='Enter Account Number' />
                ) : (
                  <span>{userData.accountNumber}</span>
                )
              }
            </p>
            <p><span className='detailHeading'>IFSC Code</span><span>: </span>
              {
                showInput ? (
                  <input type='text' className='bank-input' value={bankDetails.ifscCode} onChange={
                    (e) => setBankDetails({ ...bankDetails, ifscCode: e.target.value })
                  } placeholder='Enter IFSC Code' />
                ) : (
                  <span>{userData.ifscCode}</span>
                )
              }
            </p>
            <p><span className='detailHeading'>Email</span><span>: {userData.email}</span></p>
            <p><span className='detailHeading'>Phone</span><span>: {userData.contact}</span></p>
          </div>
          <div className="rightPart_bank">
            {
              !showUpload ? (
                <iframe
                  src={filePreview}
                  width="100%"
                  height="200px"
                  style={{
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    boxShadow: 'inset 0 4px 8px rgba(0, 0, 0, 0.1)',
                    overflow: 'auto',
                    scrollbarWidth: 'thin',
                  }}
                />
              ) : (
                <div
                  className='uploadSection_bank'
                  onClick={handleClick}
                >
                  <img src={'/assets/upload.svg'} width={40} height={40} alt='upload' className='uploadImg' />
                  <div>Upload your passbook here</div>
                  <div>(Only pdf format with max 1MB)</div>
                  <input type='file' ref={selectFile} onChange={handleChange} accept="application/pdf" hidden />

                </div>
              )
            }
          </div>
        </div>
      </div>
      <div className="btnCont">
        <button className='primary bankBtn' onClick={handleBankDetails}>
          {
            showInput ? 'Save' : 'Update'
          }
        </button>
        {
          showInput ? (
            <button className='secondary bankBtn' onClick={() => window.location.reload()}>Cancel</button>
          ) : (
            <></>
          )
        }
      </div>
    </div>
  )
}

export default BankDetailsPage