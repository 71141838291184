import * as React from "react";
import "../css/Page/CourseVerification.css";
import CourseDetailsPopUp from "./CourseDetailsPopUp";
import apis from "../apis";


const CourseRequest = ({ data }) => {
  const [openGateway, setOpenGateway] = React.useState(false);
  const [userData, setUserData] = React.useState();
  const [userProfile, setUserProfile] = React.useState();
  const [userPHD, setUserPHD] = React.useState();
  const [reason, setReason] = React.useState("");

  React.useEffect(() => {
    const fetchData = async () => {
      await apis.specificStudent({ roll: data?.studentId }).then((res) => {
        setUserData(res[0]);
      }).catch((err) => {
        alert(err.message);
      });
    }
    const fetchProfile = async () => {
      await apis.profile(data?.studentId).then((res) => {
        setUserProfile(res);
      }).catch((err) => {
        alert(err.message);
      });
    }
    const phdData = async () => {
      await apis.getPHDData(data?.studentId).then((res) => {

        setUserPHD(res);
      }).catch((err) => {
        alert(err.message);
      });
    }
    fetchData();
    fetchProfile();
    if (data?.program === "Ph.D.") {
      phdData();
    }
  }, [data]);

  const approve = async () => {
    const query = {
      id: data?.studentId,
      reason: reason,
      semester: userData?.semester,
    }
    await apis.approveCourse(query).then((res) => {
      window.location.reload();
      alert("Course Approved");
    }).catch((err) => {
      alert(err.message);
    })
  }

  const reject = async () => {
    const query = {
      id: data?.studentId,
      reason: reason,
      semester: userData?.semester,
    }
    await apis.rejectCourse(query).then((res) => {
      window.location.reload();
      alert("Course Rejected");
    }).catch((err) => {
      alert(err.message);
    })
  }
  // 
  const showExactCourseName = (course) => {
    if (course === "comprehensive") return "Comprehensive Examination";
    else if (course === "artSeminar") return "State of Art Seminar";
    else if (course === "reviewSeminar") return "Progress Review Seminar";
    else if (course === "synopsisSeminar") return "Synopsis Seminar";
  }

  return (
    <>
      <div className="verification-request">
        <div className="request-header">
          <div className="sender-info">
            <h3>
              Name: <span>{userData?.name}</span>
            </h3>
            <h3>
              Roll/Application Id: <span>{userData?.roll}</span>
            </h3>
          </div>
          <div className="attachment">
            {data?.program === "Ph.D." ? (
              <h3>
                Registering For: <span>{showExactCourseName(userPHD?.eligibleForRegistration?.option)}</span>
              </h3>
            ) : (
              <button onClick={() => setOpenGateway(true)}>
                View Course details
              </button>
            )}
          </div>
        </div>
        <div className="request-details">
          <h4>
            Semester: <span>{userData?.semester}</span>
          </h4>
          <h4>
            Payment Status: <span>{userData?.paymentStatus}</span>
          </h4>
          <h4>
            Minor: <span>N/A</span>
          </h4>
          <h4>
            Cgpa: <span>{userProfile?.cgpa}</span>
          </h4>
          <h4>
            Active Backlog: <span>{userProfile?.activeBacklog}</span>
          </h4>
        </div>
        <div className="request-action">
          <div className="action-reason">
            <input
              className="text-input"
              type="text"
              value={reason}
              placeholder="Add Remarks/Reasons"
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
          <div className="action-buttons">
            <button className="primary" onClick={approve}>
              <i className="fa-solid fa-check"></i>Approve
            </button>
            <button className="secondary" onClick={reject}>
              <i className="fa-solid fa-xmark"></i>Reject
            </button>
          </div>
        </div>
      </div>
      <CourseDetailsPopUp
        open={openGateway}
        user={userData}
        onClose={() => setOpenGateway(false)}
      />
    </>
  );
};

export default CourseRequest;
