import * as React from "react";
import { AuthProvider } from "../auth/AuthContext";
import apis from "../apis";

const semesterPattern = (sem) => {
  if (sem === "1") {
    return `${sem}st (${sem & 1 ? "Autumn" : "Spring"})`;
  } else if (sem === "2") {
    return `${sem}nd (${sem & 1 ? "Autumn" : "Spring"})`;
  } else if (sem === "3") {
    return `${sem}rd (${sem & 1 ? "Autumn" : "Spring"})`;
  } else {
    return `${sem}th (${sem & 1 ? "Autumn" : "Spring"})`;
  }
};

const RegistrationSummary = () => {
  const { userData } = AuthProvider();
  const [paymentData, setPaymentData] = React.useState();
  React.useEffect(() => {
    const fetchPaymentData = async () => {
      await apis.specificStudent({ roll: userData?.roll, semester: userData?.semester }).then((data) => {
        setPaymentData(data[0]);
      });
    };
    fetchPaymentData();
  }, [userData]);

  return (
    <>
      <div className="summary">
        <div className="summary-div">
          <div className="summary-left-div">
            <div className="summary-left-item">
              <h3>
                Name: <span>{userData.name}</span>
              </h3>
            </div>
            <div className="summary-left-item">
              <h3>
                Roll: <span>{userData.roll}</span>
              </h3>
            </div>
            <div className="summary-left-item">
              <h3>
                Course: <span>{userData.course}</span>
              </h3>
            </div>
            <div className="summary-left-item">
              <h3>
                Batch: <span>{userData.batch}</span>
              </h3>
            </div>
            <div className="summary-left-item">
              <h3>
                Branch: <span>{userData.branch}</span>
              </h3>
            </div>
          </div>
          <div className="summary-right-div">
            <div className="summary-right-item">
              <h3>
                Current Semester:{" "}
                <span>{semesterPattern(userData.semester)}</span>
              </h3>
            </div>
            <div className="summary-right-item">
              <h3>
                Payment Status: <span>{paymentData?.paymentStatus}</span>
              </h3>
            </div>
            <div className="summary-right-item">
              <h3>
                CGPA: <span>{userData.cgpa}</span>
              </h3>
            </div>
            <div className="summary-right-item">
              <h3>
                Active Backlog: <span>{userData.activeBacklog}</span>
              </h3>
            </div>
            <div className="summary-right-item">
              <h3>
                Current Registration Status:{" "}
                <span>{paymentData?.registrationStatus === "Approved" ? "Eligible for Physical Registration" : paymentData?.registrationStatus}</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegistrationSummary;
