import React, { useEffect, useState } from "react";
import AdminHeader from "../components/AdminHeader";
import "../css/Page/BonafideAsign.css";
import apis from "../apis";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { set, useForm } from "react-hook-form";
import BonafideHistoryContents from "../components/BonafideHistoryContents";
import BonafideRequestsPopUp from "../components/BonafideRequestsPopUp";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import BonafiedGeneration from "../components/BonafiedGeneration";
import FeeReceiptGeneration from "../components/FeeReceiptGeneration";
import DemandLetterGeneration from "../components/DemandLetterGeneration";

const date = new Date();

const arrayBufferToBase64 = (buffer) => {
  var binary = "";
  var bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => (binary += String.fromCharCode(b)));
  return window.btoa(binary);
};

const BonafideAsign = () => {
  const { id, again } = useParams();
  const [bonafide, setBonafide] = useState(null);
  const [tempType, setTempType] = useState(null);
  const [type, setType] = useState(undefined);
  const [specific, setSpecific] = React.useState(null);
  const [search, setSearch] = React.useState(null);
  const [student, setStudent] = React.useState(null);
  const [bonafideHistory, setBonafideHistory] = React.useState(null);
  const [applications, setApplications] = React.useState(null);
  const [feeStructure, setFeeStructure] = React.useState(null);
  const [totalFee, setTotalFee] = React.useState(null);
  const [purpose, setPurpose] = React.useState("");
  const [state, setState] = React.useState("");
  const [applySemester, setApplySemester] = React.useState(null);
  const [update, setUpdate] = React.useState({
    refNo: null,
    purpose: "Scholarship/Education Loan",
  });
  const [selectOther, setSelectOther] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBonafideApplications = async () => {
      await apis
        .getBonafideApplications()
        .then((res) => {
          setApplications(res);
        })
        .catch((err) => {
          alert(err.message);
        });
    };
    fetchBonafideApplications();
  }, []);

  const fetchSpecific = React.useCallback(async () => {
    if (!specific) return;
    await apis
      .getStudentData(specific)
      .then((res) => {
        setStudent(res.data);
      })
      .catch((err) => {
        alert(err.message);
      });
  }, [specific]);

  const applyChanges = (data) => {
    setUpdate((e) => {
      return { ...e, purpose: data.purpose };
    });
  };
  const applySearch = async () => {
    await setType(tempType);
    await axios({
      url: "/api/bonafide/apply",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("admin_access_token")}`,
      },
      data: {
        roll: search?.roll,
        applicationType: tempType,
        purpose: id !== null ? purpose : "Scholarship/Education Loan",
      },
    })
      .then(async (res) => {
        navigate(`/student/admin/documents-bonafide/${res.data.id}/n`);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };
  useEffect(() => {
    const fetchBonafide = async () => {
      if (id !== "null") {
        await apis
          .getBonafide(id)
          .then((res) => {
            console.log(res)
            setBonafide(res);
            setSpecific({ roll: res?.roll });
            setSearch({ roll: res?.roll });
            setPurpose(res?.purpose);
            setApplySemester(res?.applySemester);
            setState(res?.state);
            if (res?.purpose !== "National Scholorship via NSP") {
              setSelectOther(true);
            }
            if (tempType) {
              setType(tempType);
            } else {
              setType(
                res?.applicationType === null ? "Bonafide" : res.applicationType
              );
              setTempType(
                res?.applicationType === null ? "Bonafide" : res.applicationType
              );
            }
            // console.log(res?.applicationType === null ? "Bonafide" : res.applicationType)
          })
          .catch((err) => {
            alert(err.message);
          });
      }
    };
    const fetchBonafideHistory = async () => {
      await apis
        .getBonafideHistory()
        .then((res) => {
          setBonafideHistory(res);
        })
        .catch((err) => {
          alert(err.message);
        });
    };
    fetchBonafideHistory();
    fetchBonafide();
  }, [id]);
  useEffect(() => {
    fetchSpecific();
    setUpdate({
      refNo: bonafide?.refNo,
      purpose: bonafide?.purpose,
    });
  }, [bonafide, fetchSpecific]);

  useEffect(() => {
    if (type === "Receipt" || type === "Demand") {
      let sem = student?.semester;
      if (type === "Demand" && sem) {
        sem = String.fromCharCode(sem.charCodeAt(0) + 1);
      }
      // console.log(sem);
      const getFeeReceipt = async () => {
        await apis
          .specificStudent({ roll: student?.roll })
          .then(async (data) => {
            await apis
              .feeStructure(
                student?.year,
                student?.course,
                sem,
                student?.batch,
                data[0]?.type
              )
              .then((result1) => {
                // console.log(result1);
                setFeeStructure(result1);
                let total =
                  Number(result1?.tuitionfee) +
                  Number(result1?.otherfee) +
                  Number(result1?.admissionfee) +
                  Number(result1?.cautionmoney) +
                  Number(result1?.convocationfee);
                setTotalFee(total);
                // let otherTotal =
                //   Number(allotHostelFee());
                // setOtherTotalFee(otherTotal);
              })
              .catch((err) => {
                alert(err);
              });
          })
          .catch((err) => {
            alert(err);
          });
      };

      getFeeReceipt();
    }
  }, [type, setType, student]);

  const printRef = React.useRef();
  const { register, handleSubmit } = useForm();

  const print = async () => {
    const canvas = await html2canvas(printRef.current);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");

    pdf.addImage(imgData, "PNG", 4, 4, 202, 190);
    pdf.save(
      `${student?.roll}_${
        type === (null || "Bonafide")
          ? "bonafide"
          : type === "Receipt"
          ? "fee_receipt"
          : type === "Demand"
          ? "demand_letter"
          : ""
      }.pdf`
    );
    if (again !== "a")
      await apis
        .generateBonafide(bonafide?.id, update?.purpose)
        .then((res) => {
          window.location.reload();
          alert(`${type} Generated`);
        })
        .catch((err) => {
          alert(err.message);
        });
  };

  const [openBonaRequestsPopup, setBonaRequestsPopup] = useState({
    open: false,
  });

  const printPage = async() => {
    const canvas = await html2canvas(printRef.current);
    const imgData = canvas.toDataURL("image/png");
    
    const imgDataIframe = `<img src="${imgData}" width="100%"></img>`;
    const printWindow = window.open('', '', 'width=900,height=848');
    printWindow.document.write(`
      <html>
        <head>
          <style>
            @media print {
              body {
                margin: 0;
                padding: 0;
                width: 100%;
                height: 100%;
              }
              header, footer {
                display: none;
              }
            }
          </style>
        </head>
        <body>
          ${imgDataIframe}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
    printWindow.onafterprint = function() {
      printWindow.close();
    };
  };

  return (
    <>
      <AdminHeader />
      <BonafideRequestsPopUp
        open={openBonaRequestsPopup.open}
        applications={applications}
        setBonaRequestsPopup={setBonaRequestsPopup}
        onClose={() => setBonaRequestsPopup(false)}
      />
      <div className="top-div">
        <div className="bonafide-assign">
          <div className="search-student">
            <div className="title">
              <h2>Search Students list: </h2>
            </div>
            <div className="content-application">
              <div className="search-div search-div-application">
                <div className="sub-title inner-title">
                  <div>
                    Specific Student Search: <span>For instant result</span>
                  </div>
                </div>
                <div className="search-items">
                  <div className="search-item">
                    <h4 htmlFor="roll">Roll Number:</h4>
                    <input
                      type="text"
                      name="student-id"
                      id="student-id"
                      onChange={(e) =>
                        setSearch((e1) => {
                          if (e.target.value === "") {
                            return null;
                          }
                          return { ...e1, roll: e.target.value };
                        })
                      }
                      value={search?.roll}
                    />
                  </div>
                  {/* <div className="search-item">
                  <h4 htmlFor="name">Full Name:</h4>
                  <input
                    type="text"
                    name="student-id"
                    id="student-id"
                    onChange={(e) =>
                      setSearch((e1) => {
                        if (e.target.value === "") {
                          return null;
                        }
                        return { ...e1, name: e.target.value };
                      })
                    }
                    value={search?.name}
                  />
                </div> */}
                </div>
              </div>
              <div className="second-part">
                <div className="application-title">Application Type</div>
                <div className="option-list">
                  <div className="">
                    <input
                      type="radio"
                      name="check"
                      id="bonafide"
                      checked={tempType === "Bonafide"}
                      onClick={() => setTempType("Bonafide")}
                    />
                    <label htmlFor="bonafide">Bonafide</label>
                  </div>
                  <div className="">
                    <input
                      type="radio"
                      name="check"
                      id="fee-receipt"
                      checked={tempType === "Receipt"}
                      onClick={() => setTempType("Receipt")}
                    />
                    <label htmlFor="fee-receipt">Fee Receipt</label>
                  </div>
                  <div className="">
                    <input
                      type="radio"
                      name="check"
                      id="demand"
                      checked={tempType === "Demand"}
                      onClick={() => setTempType("Demand")}
                    />
                    <label htmlFor="demand">Demand Letter</label>
                  </div>
                </div>
                <div className="search-item btn-div">
                  <button
                    id="search"
                    className="new_btn primary"
                    onClick={() => applySearch()}
                  >
                    <span>Generate</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="bonafide-requests">
            <div className="title">
              <h2>Requests: </h2>
            </div>
            <div
              className="requests-view"
              onClick={() =>
                setBonaRequestsPopup({
                  open: true,
                })
              }
            >
              <h3>
                <span>{applications?.length}</span>
              </h3>
              <h3>(Total active requests)</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="mid-div">
        <div className="student-bonafide-result">
          <div className="bonafide-generated" ref={printRef}>
            {(type === null || type === "Bonafide") && (
              <BonafiedGeneration
                update={update}
                applySemester={applySemester}
                state={state}
                student={student}
                date={date}
                arrayBufferToBase64={arrayBufferToBase64}
              />
            )}
            {type !== null && type === "Receipt" && (
              <FeeReceiptGeneration
                update={update}
                student={student}
                date={date}
                feeData={feeStructure}
                totalFees={totalFee}
              />
            )}
            {type !== null && type === "Demand" && (
              <DemandLetterGeneration
                update={update}
                student={student}
                date={date}
                feeData={feeStructure}
                totalFees={totalFee}
              />
            )}
          </div>
          <div className="bonafide-actions">
            <div className="action">
              <div className="fill-bonafide-details">
                <form onSubmit={handleSubmit(applyChanges)}>
                  <div className="form-group">
                    <label className="required" htmlFor="ref">
                      Ref. Number: IIITBH/REG/2023/
                    </label>
                    <input
                      type="text"
                      name="ref-id"
                      id="ref-id"
                      value={update?.refNo}
                      onChange={(e) =>
                        setUpdate((e1) => {
                          return { ...e1, refNo: e.target.value };
                        })
                      }
                      // disabled
                    />
                  </div>
                  {(type === null || type === "Bonafide") && (
                    <div className="form-group" style={{ width: "100%" }}>
                      <label className="required" htmlFor="reason">
                        Purpose for bonafide:
                      </label>
                      <select
                        id="type"
                        value={purpose}
                        onChange={(e) => {
                          if (e.target.value === "Other") {
                            setSelectOther(true);
                            setPurpose(e.target.value);
                          } else {
                            setSelectOther(false);
                            setPurpose(e.target.value);
                            setUpdate((e1) => {
                              return { ...e1, purpose: e.target.value };
                            });
                          }
                        }}
                        required
                      >
                        <option value="">Select Purpose for Application</option>
                        <option value="National Scholorship via NSP">
                          National Scholorship via NSP
                        </option>
                        <option value="Other">Other</option>
                      </select>
                      {selectOther && (
                        <input
                          type="text"
                          name="purpose-id"
                          id="purpose-id"
                          // value={update?.purpose}
                          placeholder={update?.purpose}
                          style={{ width: "80%" }}
                          onChange={(e) =>
                            setUpdate((e1) => {
                              return { ...e1, purpose: e.target.value };
                            })
                          }
                          {...register("purpose")}
                        />
                      )}
                    </div>
                  )}
                  {type === "Bonafide" ? (
                    selectOther ? (
                      <button className="primary" type="submit">
                        <span>Apply Changes</span>
                      </button>
                    ) : null
                  ) : (
                    <button className="primary" type="submit">
                      <span>Apply Changes</span>
                    </button>
                  )}
                </form>
              </div>
            </div>
            <div className="action">
              <div className="bonafide-action-btn">
                <button className="new-btn" onClick={print}>
                  <span>Download</span>
                </button>
              </div>
              <div className="bonafide-action-btn">
                <button className="new-btn" onClick={()=>printPage()}>
                  <span>Print</span>
                </button>
              </div>
              {/* <div className="bonafide-action-btn">
                <button className="new-btn">
                  Mail to<span> Student</span>
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-div">
        <div className="bonafide-assign-history">
          <div className="title">
            <h2>History: </h2>
          </div>
          <div className="result-items history bonafide-history-contents">
            <BonafideHistoryContents history={bonafideHistory} />
            {/* <div className="result-heading">
              <div className="result-heading-item">
                <h4>Date</h4>
              </div>
              <div className="result-heading-item">
                <h4>Roll Number</h4>
              </div>
              <div className="result-heading-item">
                <h4>Purpose</h4>
              </div>
              <div className="result-heading-item">
                <h4>Ref-Id</h4>
              </div>
              <div className="result-heading-item">
                <h4>Bonafide</h4>
              </div>
            </div>
            <div className="result-contents">
              {bonafideHistory?.map((history) => (
                <BonafideHistoryContents key={history.id} history={history} />
              ))}
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default BonafideAsign;
