import React from "react";
import "../css/Component/Header.css";
import jwtVerify from "../auth/service";
import { useNavigate } from "react-router-dom";
// import { AuthProvider } from "../auth/AuthContext";

function Header() {
  const navigate = useNavigate();
  const logout = () => {
    jwtVerify.logoutUser("student");
    navigate("/");
  };
  // const { userData, newUserData } = AuthProvider();
  return (
    <>
      <nav className="navbar">
        <div className="container">
          <div
            className="nav-logo"
            onClick={() => window.location.pathname === '/admissionpage' ? navigate('/admissionpage') : navigate("/student")}
          >
            <img className="logo-icon" src="/assets/inst-logo.svg" alt="logo" />
            <img className="logo-text" src="/assets/logo-name.svg" alt="name" />
          </div>
          <div className="nav-right">
            <div className="nav-help">
              <a href="/">Help and Support</a>
            </div>
            <div className="nav-logout">
              <button id="logout" className="primary" onClick={() => logout()}>
                <span>Logout</span>
                <img src="/assets/logout.svg" alt="logout" />
              </button>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
