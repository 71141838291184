import React from "react";
import DetailedBarChart from "./DetailedBarChart";
import "../css/Component/GraphComponents.css";
import PieChartComp from "./PieChartComp";
import LineChartComp from "./LineChartComp";

const GraphComponents = ({
  R_A,
  R_B,
  R_C,
  R_A_Percent,
  R_B_Percent,
  R_C_Percent,
  R_A_AverageCnt,
  R_B_AverageCnt,
  R_C_AverageCnt,
  eachRatingCount,
}) => {
  return (
    <div style={{ width: "100%" }} className="GraphsContiner">
      <div className="pieAndLineContainer">
        <PieChartComp eachRatingCount={eachRatingCount} />
        <LineChartComp R_A_AverageCnt={R_A_AverageCnt} R_B_AverageCnt={R_B_AverageCnt} R_C_AverageCnt={R_C_AverageCnt} />
      </div>
      <div className="barContainer">
        <DetailedBarChart
          R_A_Percent={R_A}
          R_B_Percent={R_B}
          R_C_Percent={R_C}
        />
      </div>
    </div>
  );
};

export default GraphComponents;
