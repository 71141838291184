import React, { useEffect } from 'react'
import "../css/Component/PaymentHistoryPopUp.css";
import { Link } from "react-router-dom";
import { PaymentDetail } from './PaymentHistoryNew';
import apis from '../apis';
import { CircularProgress } from '@mui/material';

const arrayBufferToURL = (buffer, type) => {
  var data = new Uint8Array(buffer)
  var blob = new Blob([data], { type: type });
  var url = URL.createObjectURL(blob);
  return url;
};

const PaymentHistoryPopUp = ({ open, onClose, applicationid }) => {
  
  const [paymentHistory, setPaymentHistory] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    if(applicationid && open){
      const fetchPaymentHistory = async () => {
        setLoading(true);
        await apis
          .paymentHistory({ applicationid: applicationid })
          .then((data) => {
            setLoading(false);
            setPaymentHistory(data);
          })
          .catch((err) => {
            setLoading(false);
            alert(err);
          });
      };
      fetchPaymentHistory();
    }
  }, [open, applicationid]);

  if (!open) return null;
  return (
    <>
      <div className="PersonalDetailsPopUp">
        <div className="documents-popup-title">
          <h2>
            Payment Record: <span>{applicationid ?? "Loading..."}</span>
          </h2>
          <button onClick={onClose}>
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
        {loading ? <div className="documents-popup-content" style={{minHeight: "100px", height: "200px", display: "flex", alignItems: 'center', justifyContent: 'center', width: '100%'}}> <CircularProgress /> </div> : (
        <div className="content-div popUpPaymentDetail">
          <div className="result-items history">
            <div className="result-heading">
              <div className="result-heading-item">
                <h4>Date</h4>
              </div>
              <div className="result-heading-item">
                <h4>Amount</h4>
              </div>
              <div className="result-heading-item">
                <h4>Mode</h4>
              </div>
              <div className="result-heading-item TID">
                <h4>Transaction ID</h4>
              </div>
              <div className="result-heading-item">
                <h4>Status / Verified by</h4>
              </div>
              <div className="result-heading-item">
                <h4>Reciept</h4>
              </div>
            </div>
            <div className="result-contents">
              {paymentHistory.map((payment) => {
                return <PaymentDetail payment={payment} key={payment.id} />;
              })}
            </div>
          </div>
        </div>
        )}
      </div>
    </>
  )
}

export default PaymentHistoryPopUp