import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import "../css/Component/PersonalDetails.css";
import apis from "../apis";
import { useNavigate } from "react-router-dom";

const PersonalDetails = ({ studentData, adminData }) => {
  const navigate = useNavigate();

  const [update, setUpdate] = useState(false);
  const [update2, setUpdate2] = useState(false);
  const [updateAddress, setUpdateAddress] = useState(false);
  const [personalData, setPersonalData] = useState({
    name: studentData?.name,
    roll: studentData?.roll,
    email: studentData?.email,
    course: studentData?.course,
    branch: studentData?.branch,
    batch: studentData?.batch,
    contact: studentData?.contact,
    applicationid: studentData?.applicationid,
    instituteEmailId: studentData?.instituteEmailId,
    currentStatus: studentData?.currentStatus,
    type: studentData?.type,
    allotedBranch: studentData?.allotedBranch,
    address: studentData?.address,
  });

  const [checkUpdate, setCheckUpdate] = useState({
    name: false,
    roll: false,
    email: false,
    branch: false,
    batch: false,
    contact: false,
    instituteEmailId: false,
    currentStatus: false,
    type: false,
    allotedBranch: false,
    address: false,
  });

  useEffect(() => {
    setPersonalData({
      name: studentData?.name,
      roll: studentData?.roll,
      email: studentData?.email,
      course: studentData?.course,
      branch: studentData?.branch,
      batch: studentData?.batch,
      contact: studentData?.contact,
      applicationid: studentData?.applicationid,
      instituteEmailId: studentData?.instituteEmailId,
      currentStatus: studentData?.currentStatus,
      type: studentData?.type,
      allotedBranch: studentData?.allotedBranch,
      address: studentData?.address,
    });

    setCheckUpdate({
      name: false,
      roll: false,
      email: false,
      branch: false,
      batch: false,
      contact: false,
      instituteEmailId: false,
      currentStatus: false,
      type: false,
      allotedBranch: false,
      address: false,
    });
  }, [studentData, adminData]);

  const saveUpdate = async () => {
    const updateData = {};
    if (checkUpdate.name) updateData.name = personalData.name;
    if (checkUpdate.branch) updateData.branch = personalData.branch;
    if (checkUpdate.batch) updateData.batch = personalData.batch;
    
    if(checkUpdate.roll && checkUpdate.instituteEmailId){
      updateData.roll = personalData.roll;
      updateData.email = personalData.email;
    }
    else if (checkUpdate.roll){
      updateData.roll = personalData.roll;
      let currEmail = personalData.instituteEmailId;
      let currEmailArr = currEmail.split(".");
      let newEmail = currEmailArr[0] + "." + personalData.roll + "@iiitbh.ac.in";
      updateData.email = newEmail;
    } else if (checkUpdate.instituteEmailId){
      updateData.email = personalData.instituteEmailId;
      let currEmail = personalData.instituteEmailId;
      let currEmailArr = currEmail.split(".");
      let rollPart = currEmailArr[1].split("@");
      updateData.roll = rollPart[0];
    }


    const givenData = {
      roll: studentData.roll,
    };

    const data = {
      updateData,
      givenData,
    };

    try {
      await apis.updateNewProfile(data).then((response) => {
        console.log(response);
        alert("Data Updated Successfully");
        if(checkUpdate.roll || checkUpdate.instituteEmailId){
          navigate(`/student/admin/newprofile/${updateData.roll}/${personalData.applicationid}?semester=${studentData.semester}`, { replace: true });
        }
        setUpdate(false);
        studentData.name = updateData?.name || studentData?.name;
        studentData.roll = updateData?.roll || studentData?.roll;
        studentData.branch = updateData?.branch || studentData?.branch;
        studentData.batch = updateData?.batch || studentData?.batch;
        studentData.instituteEmailId = updateData?.email || studentData?.instituteEmailId;

        setCheckUpdate({
          ...checkUpdate,
          name: false,
          roll: false,
          branch: false,
          batch: false,
          instituteEmailId: false,
        });
      });
    } catch (error) {
      console.log(error);
      setUpdate(false);
      setCheckUpdate({
        ...checkUpdate,
        name: false,
        roll: false,
        branch: false,
        batch: false,
        instituteEmailId: false,
      });
    }
  };

  const saveUpdate2 = async() => {
    let updateData = {};
    if (checkUpdate.contact) updateData.contact = personalData.contact;
    if (checkUpdate.email) updateData.semail = personalData.email;
    if (checkUpdate.currentStatus)
      updateData.currentStatus = personalData.currentStatus;
    if (checkUpdate.type) updateData.type = personalData.type;
    if (checkUpdate.allotedBranch)
      updateData.allotedBranch = personalData.allotedBranch;

    const givenData = {
      roll: studentData.roll,
      applicationid: studentData.applicationid,
      contact: studentData.contact,
      semail: studentData.email,
    }

    const data = {
      updateData,
      givenData,
    };

    try {
      await apis.updateNewProfile(data).then((response) => {
        console.log(response);
        alert("Data Updated Successfully");
        navigate(`/student/admin/newprofile/${personalData.roll}/${personalData.applicationid}?semester=${studentData.semester}`, { replace: true });
        console.log(studentData.allotedBranch);
        setUpdate2(false);
        studentData.allotedBranch = updateData?.allotedBranch || studentData?.allotedBranch;
        studentData.contact = updateData?.contact || studentData?.contact;
        studentData.email = updateData?.semail || studentData?.email;
        studentData.currentStatus = updateData?.currentStatus || studentData?.currentStatus;
        studentData.type = updateData?.type || studentData?.type;

        setCheckUpdate({
          ...checkUpdate,
          contact: false,
          email: false,
          currentStatus: false,
          type: false,
          allotedBranch: false,
        });
      });
    } catch (error) {
      console.log(error);
      setUpdate2(false);
      setCheckUpdate({
        ...checkUpdate,
        contact: false,
        email: false,
        currentStatus: false,
        type: false,
        allotedBranch: false,
      });
      
    }
  };

  const saveUpdateAddress = async() => {
    let updateData = {};
    if (checkUpdate.address) updateData.address = personalData.address;

    const givenData = {
      roll: studentData.roll,
      applicationid: studentData.applicationid,
    };

    const data = {
      updateData,
      givenData,
    };

    try {
      await apis.updateNewProfile(data).then((response) => {
        console.log(response);
        alert("Data Updated Successfully");
        navigate(`/student/admin/newprofile/${personalData.roll}/${personalData.applicationid}?semester=${studentData.semester}`, { replace: true });
        setUpdateAddress(false);
        studentData.address = updateData?.address || studentData?.address;
        setCheckUpdate({
          ...checkUpdate,
          address: false,
        });
      });
    } catch (error) {
      console.log(error);
      setUpdateAddress(false);
      setCheckUpdate({
        ...checkUpdate,
        address: false,
      });
    }
    
  };

  const cancelUpdate = () => {
    setUpdate(false);
    setPersonalData({
      ...personalData,
      name: studentData?.name,
      roll: studentData?.roll,
      branch: studentData?.branch,
      batch: studentData?.batch,
      instituteEmailId: studentData?.instituteEmailId,
    });

    setCheckUpdate({
      ...checkUpdate,
      name: false,
      roll: false,
      branch: false,
      batch: false,
      instituteEmailId: false,
    });
  };

  const cancelUpdate2 = () => {
    setUpdate2(false);
    setPersonalData({
      ...personalData,
      contact: studentData?.contact,
      email: studentData?.email,
      currentStatus: studentData?.currentStatus,
      type: studentData?.type,
      allotedBranch: studentData?.allotedBranch,
    });

    setCheckUpdate({
      ...checkUpdate,
      contact: false,
      email: false,
      currentStatus: false,
      type: false,
      allotedBranch: false,
    });
  };

  const cancelUpdateAddress = () => {
    setUpdateAddress(false);
    setPersonalData({
      ...personalData,
      address: studentData?.address,
    });

    setCheckUpdate({
      ...checkUpdate,
      address: false,
    });
  };

  return (
    <div className="personalPageCont">
      <div className="p_top">
        <div className="p_left">
          <div className="profile_edits">
            {(adminData?.role?.includes("DOAA") || (adminData?.role?.includes("Super Admin"))) && (!update ? (
              <EditIcon onClick={() => setUpdate(true)} />
            ) : (
              <div style={{ display: "flex", gap: "10px" }}>
                <CheckCircleOutlineRoundedIcon onClick={() => saveUpdate()} />
                <HighlightOffRoundedIcon onClick={() => cancelUpdate()} />
              </div>
            ))}
          </div>
          <div className={`p_content ${update && "p_update"}`}>
            <span>Name: </span>
            {update ? (
              <input
                className="profile_input"
                type="text"
                value={personalData.name}
                onChange={(e) => {
                  setPersonalData({ ...personalData, name: e.target.value });
                  setCheckUpdate({ ...checkUpdate, name: true });
                }}
              />
            ) : (
              personalData.name
            )}
          </div>
          <div className={`p_content ${update && "p_update"}`}>
            <span>Roll: </span>
            {update ? (
              <input
                className="profile_input"
                type="text"
                value={personalData.roll}
                onChange={(e) => {
                  setCheckUpdate({ ...checkUpdate, roll: true });
                  setPersonalData({ ...personalData, roll: e.target.value });
                }}
              />
            ) : (
              personalData.roll
            )}
          </div>
          <div className={`p_content ${update && "p_update"}`}>
            <span>Institute Email: </span>
            {update ? (
              <input
                className="profile_input"
                type="text"
                value={personalData.instituteEmailId || personalData.email}
                onChange={(e) => {
                  setCheckUpdate({ ...checkUpdate, instituteEmailId: true });
                  setPersonalData({
                    ...personalData,
                    instituteEmailId: e.target.value,
                  });
                }}
              />
            ) : (
              personalData.instituteEmailId
            )}
          </div>
          <div className={`p_content ${update && "p_update"}`}>
            <span>Course: </span> {personalData.course}
          </div>
          <div className={`p_content ${update && "p_update"}`}>
            <span>Branch: </span>
            {update ? (
              <input
                className="profile_input"
                type="text"
                value={personalData.branch}
                onChange={(e) => {
                  setCheckUpdate({ ...checkUpdate, branch: true });
                  setPersonalData({ ...personalData, branch: e.target.value });
                }}
              />
            ) : (
              personalData.branch
            )}
          </div>
          <div className={`p_content ${update && "p_update"}`}>
            <span>Batch: </span>
            {update ? (
              <input
                className="profile_input"
                type="text"
                value={personalData.batch}
                onChange={(e) => {
                  setCheckUpdate({ ...checkUpdate, batch: true });
                  setPersonalData({ ...personalData, batch: e.target.value });
                }}
              />
            ) : (
              personalData.batch
            )}
          </div>
        </div>
        <div className="p_right">
          <div className="profile_edits">
            {(adminData?.role?.includes("DOAA") || (adminData?.role?.includes("Super Admin"))) && (!update2 ? (
              <EditIcon onClick={() => setUpdate2(true)} />
            ) : (
              <div style={{ display: "flex", gap: "10px" }}>
                <CheckCircleOutlineRoundedIcon onClick={() => saveUpdate2()} />
                <HighlightOffRoundedIcon onClick={() => cancelUpdate2()} />
              </div>
            ))}
          </div>
          <div className={`p_content ${update2 && "p_update"}`}>
            <span>Contact: </span>
            {update2 ? (
              <input
                className="profile_input"
                type="text"
                value={personalData.contact}
                onChange={(e) => {
                  setCheckUpdate({ ...checkUpdate, contact: true });
                  setPersonalData({ ...personalData, contact: e.target.value });
                }}
              />
            ) : (
              personalData.contact
            )}
          </div>
          <div className={`p_content ${update2 && "p_update"}`}>
            <span>Application ID: </span> {personalData.applicationid}
          </div>
          <div className={`p_content ${update2 && "p_update"}`}>
            <span>Personal Email: </span>
            {update2 ? (
              <input
                className="profile_input"
                type="text"
                value={personalData.email}
                onChange={(e) => {
                  setCheckUpdate({ ...checkUpdate, email: true });
                  setPersonalData({ ...personalData, email: e.target.value });
                }}
              />
            ) : (
              personalData.email
            )}
          </div>
          <div className={`p_content ${update2 && "p_update"}`}>
            <span>Current Status: </span>
            {update2 ? (
              <input
                className="profile_input"
                type="text"
                value={personalData.currentStatus}
                onChange={(e) => {
                  setCheckUpdate({ ...checkUpdate, currentStatus: true });
                  setPersonalData({
                    ...personalData,
                    currentStatus: e.target.value,
                  });
                }}
              />
            ) : (
              personalData.currentStatus
            )}
          </div>
          <div className={`p_content ${update2 && "p_update"}`}>
            <span>Type: </span>
            {update2 ? (
              <input
                className="profile_input"
                type="text"
                value={personalData.type}
                onChange={(e) => {
                  setCheckUpdate({ ...checkUpdate, type: true });
                  setPersonalData({ ...personalData, type: e.target.value });
                }}
              />
            ) : (
              personalData.type
            )}
          </div>
          <div className={`p_content ${update2 && "p_update"}`}>
            <span>Alloted Branch: </span>
            {update2 ? (
              <input
                className="profile_input"
                type="text"
                value={personalData.allotedBranch}
                onChange={(e) => {
                  setCheckUpdate({ ...checkUpdate, allotedBranch: true });
                  setPersonalData({
                    ...personalData,
                    allotedBranch: e.target.value,
                  });
                }}
              />
            ) : (
              personalData.allotedBranch
            )}
          </div>
        </div>
      </div>
      <div className="p_bottom">
        <div className="profile_edits">
          {(adminData?.role?.includes("DOAA") || (adminData?.role?.includes("Super Admin"))) && (!updateAddress ? (
            <EditIcon onClick={() => setUpdateAddress(true)} />
          ) : (
            <div style={{ display: "flex", gap: "10px" }}>
              <CheckCircleOutlineRoundedIcon
                onClick={() => saveUpdateAddress()}
              />
              <HighlightOffRoundedIcon onClick={() => cancelUpdateAddress()} />
            </div>
          ))}
        </div>
        <div
          className={`p_content ${updateAddress && "p_update"}`}
          style={{ width: "100%" }}
        >
          <span>Address: </span>
          {updateAddress ? (
            <input
              className="profile_input"
              type="text"
              style={{ width: "70%" }}
              value={personalData.address}
              onChange={(e) => {
                setCheckUpdate({ ...checkUpdate, address: true });
                setPersonalData({ ...personalData, address: e.target.value });
              }}
            />
          ) : (
            personalData.address
          )}
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;
