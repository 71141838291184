import React from "react";
import "../css/Component/PersonalDetailsPopUp.css";

const PersonalDetailsPopUp = ({ open, onClose, userData }) => {
  if (!open) return null;
  return (
    <>
      <div className="PersonalDetailsPopUp">
        <div className="documents-popup-title">
          <h2>
            Personal Details: <span>{userData?.applicationid}</span>
          </h2>
          <button onClick={onClose}>
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div className="documents-popup-content">
          <div className="form-fields-element view-mode">
            <div className="form-container">
              <div className="form-groups-row">
                <div className="form-group left-group">
                  <label htmlFor="aadhaarNum" className="required">
                    Aadhaar Number
                  </label>
                  <h4>{userData?.aadhar}</h4>
                </div>
                <div className="form-group right-group">
                  <label htmlFor="bloodGroup">Blood Group</label>
                  <h4>{userData?.bloodgroup}</h4>
                </div>
              </div>
              <div className="form-groups-row">
                <div className="form-group left-group">
                  <label htmlFor="permanentAdd" className="required">
                    Permanent Address
                  </label>
                  <h4>{userData?.paddress}</h4>
                </div>
                <div className="form-group right-group">
                  <label htmlFor="contactAdd" className="required">
                    Contact Address
                  </label>
                  <h4>{userData?.caddress}</h4>
                </div>
              </div>
              <div className="form-groups-row">
                <div className="form-group left-group">
                  <label htmlFor="guardianName" className="required">
                    Guardian Name
                  </label>
                  <h4>{userData?.gname}</h4>
                </div>
                <div className="form-group right-group">
                  <label htmlFor="guardianRel">Guardian Relation</label>
                  <h4>{userData?.grelation}</h4>
                </div>
              </div>
              <div className="form-groups-row">
                <div className="form-group left-group">
                  <label htmlFor="guardianPhone" className="required">
                    Guardian's Contact
                  </label>
                  <h4>{userData?.gcontact}</h4>
                </div>
                <div className="form-group right-group">
                  <label htmlFor="guardianEmail">Guardian's Email</label>
                  <h4>{userData?.gemail}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalDetailsPopUp;
