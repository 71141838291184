import React, { useEffect } from "react";
import "../css/Component/HodData.css";
import { AuthProvider } from "../auth/AuthContext";
import { useNavigate } from "react-router-dom";

const HodData = () => {
  const { adminData } = AuthProvider();
  const navigate = useNavigate();
  const [sessionVal, setSessionVal] = React.useState("");
  const [seasonVal, setSeasonVal] = React.useState("");
  const [filterAttribute, setFilterAttribute] = React.useState({
    session: "",
    program: "All",
    season: "",
  });

  const generateSessionList = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const sessionList = [];
    for (let i = currentYear; i >= 2017; i--) {
      if (currentMonth < 6 && i === currentYear) continue;
      let nextYear = (i + 1).toString().slice(-2);
      sessionList.push(`${i}-${nextYear}`);
    }
    return sessionList;
  };

  const generateSeasonList = () => {
    const currentMonth = new Date().getMonth();
    if (currentMonth >= 7 && currentMonth <= 12) {
      return "Autumn";
    } else if (currentMonth >= 1 && currentMonth <= 5) {
      return "Spring";
    }
  };

  useEffect(() => {
    generateSessionList();
    setSessionVal(generateSessionList()[0]);
  }, []);

  useEffect(() => {
    setSeasonVal(generateSeasonList());
  }, []);

  return (
    <div className="top-div">
      <div className="hodContainer">
        <div className="title cv">
          <div className="">
            <h2>Review Student Data:</h2>
          </div>
          <div className="filter-item2">
            <h4>Filter by:</h4>
            <div className="filterAttribute">
              <p className="">Session</p>
              <select
                defaultValue={sessionVal}
                onChange={(e) => {
                  setSessionVal(e.target.value);
                  setFilterAttribute((e1) => {
                    return { ...e1, session: e.target.value };
                  });
                }}
              >
                {generateSessionList().map((e, i) => {
                  return (
                    <option key={i} value={e}>
                      {e}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="filterAttribute">
              <p>Program</p>
              <select
                onChange={(e) => {
                  setFilterAttribute((e1) => {
                    return { ...e1, program: e.target.value };
                  });
                }}
              >
                <option value="All">All</option>
                <option value="B.TECH.">B.Tech</option>
                <option value="M.TECH.">M.Tech</option>
                <option value="Ph.D.">PhD</option>
              </select>
            </div>
            <div className="filterAttribute">
              <p>Season</p>
              <select
                onChange={(e) => {
                  setSeasonVal(e.target.value);
                  setFilterAttribute((e1) => {
                    return { ...e1, season: e.target.value };
                  });
                }}
                defaultValue={seasonVal}
              >
                <option value="Spring">Spring</option>
                <option value="Autumn">Autumn</option>
              </select>
            </div>
          </div>
        </div>
        <div className="status-div">
          {filterAttribute.program === "All" ||
          filterAttribute.program === "B.TECH." ? (
            <div className="below">
              <p className="">B.Tech.</p>
              {seasonVal === "Spring" ? (
                <div className="SemCont">
                  <div
                    onClick={() => {
                      navigate(
                        `/student/admin/my-courses/hodview/${sessionVal}__B.TECH.__${adminData.branch[0]}__2`
                      );
                    }}
                    className="semEle"
                  >
                    2nd <br /> Semester
                  </div>
                  <div
                    onClick={() => {
                      navigate(
                        `/student/admin/my-courses/hodview/${sessionVal}__B.TECH.__${adminData.branch[0]}__4`
                      );
                    }}
                    className="semEle"
                  >
                    4th <br /> Semester
                  </div>
                  <div
                    onClick={() => {
                      navigate(
                        `/student/admin/my-courses/hodview/${sessionVal}__B.TECH.__${adminData.branch[0]}__6`
                      );
                    }}
                    className="semEle"
                  >
                    6th <br /> Semester
                  </div>
                  <div
                    onClick={() => {
                      navigate(
                        `/student/admin/my-courses/hodview/${sessionVal}__B.TECH.__${adminData.branch[0]}__8`
                      );
                    }}
                    className="semEle"
                  >
                    8th <br /> Semester
                  </div>
                </div>
              ) : (
                <div className="SemCont">
                  <div
                    onClick={() => {
                      navigate(
                        `/student/admin/my-courses/hodview/${sessionVal}__B.TECH.__${adminData.branch[0]}__1`
                      );
                    }}
                    className="semEle"
                  >
                    1st <br /> Semester
                  </div>
                  <div
                    onClick={() => {
                      navigate(
                        `/student/admin/my-courses/hodview/${sessionVal}__B.TECH.__${adminData.branch[0]}__3`
                      );
                    }}
                    className="semEle"
                  >
                    3rd <br /> Semester
                  </div>
                  <div
                    onClick={() => {
                      navigate(
                        `/student/admin/my-courses/hodview/${sessionVal}__B.TECH.__${adminData.branch[0]}__5`
                      );
                    }}
                    className="semEle"
                  >
                    5th <br /> Semester
                  </div>
                  <div
                    onClick={() => {
                      navigate(
                        `/student/admin/my-courses/hodview/${sessionVal}__B.TECH.__${adminData.branch[0]}__7`
                      );
                    }}
                    className="semEle"
                  >
                    7th <br /> Semester
                  </div>
                </div>
              )}
            </div>
          ) : filterAttribute.program === "All" ||
            filterAttribute.program === "M.TECH." ? (
            <div className=""></div>
          ) : filterAttribute.program === "All" ||
            filterAttribute.program === "Ph.D." ? (
            <div className=""></div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default HodData;
