import * as React from "react";
import "../css/Component/StudentLogin.css";
import { AuthProvider } from "../auth/AuthContext";
import api from "../newRegistrationApis";
import { useNavigate } from "react-router-dom";

const NewStudentLogin = () => {
  const { setNewUserData } = AuthProvider();
  const [applicationId, setApplicationId] = React.useState("");
  const [password, setPassword] = React.useState("");
  const navigate = useNavigate()
  async function loginStudent(e) {
    e.preventDefault();
    await api
      .login(applicationId, password)
      .then(async (res) => {
        setNewUserData(res);
        navigate("/admissionpage")
      })
      .catch((err) => {
        alert(err.message);
      });

  }

  return (
    <>
      <div className="login-div">
        <div className="login-header">
          <h2>Login to proceed</h2>
        </div>
        <div className="login-message">
          <p>Welcome onboard with us!</p>
        </div>
        <div className="login-form">
          <form onSubmit={(e) => loginStudent(e)}>
            <div className="login-input">
              <h3>Registration ID</h3>
              <input
                type="text"
                placeholder="Username (as per onbaording mail)"
                onChange={(e) => setApplicationId(e.target.value)}
                value={applicationId}
                required
              />
            </div>
            <div className="login-input">
              <h3>Password</h3>
              <input
                type="password"
                placeholder="Password (as per onbaording mail)"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required
              />
            </div>
            <div className="login-button">
              <button type="submit">Login</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default NewStudentLogin;
